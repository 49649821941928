import { IconType } from '@components/Icon/types';
import { PasswordVisibility } from '@typings/auth';

export const mapPasswordVisibilityToIconType: Record<
  PasswordVisibility,
  IconType
> = {
  [PasswordVisibility.HIDDEN]: IconType.EYE,
  [PasswordVisibility.VISIBLE]: IconType.EYE_OFF,
};

export const mapPasswordVisibilityToInputType: Record<
  PasswordVisibility,
  'password' | 'text'
> = {
  [PasswordVisibility.HIDDEN]: 'password',
  [PasswordVisibility.VISIBLE]: 'text',
};
