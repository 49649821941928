import { UIModel } from '@store/user/types';

export const mapModelToReadableName: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]: 'models.relationExtraction.name',
  [UIModel.NAMED_ENTITY_RECOGNITION]: 'models.namedEntityRecognition.name',
  [UIModel.TEXT_CLASSIFICATION]: 'models.textClassification.name',
  [UIModel.TEXT_TO_TABLE]: 'models.textToTable.name',
};

export const mapModelToMetaDescription: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]: 'models.relationExtraction.metaDescription',
  [UIModel.NAMED_ENTITY_RECOGNITION]:
    'models.namedEntityRecognition.metaDescription',
  [UIModel.TEXT_CLASSIFICATION]: 'models.textClassification.metaDescription',
  [UIModel.TEXT_TO_TABLE]: 'models.textToTable.metaDescription',
};
