import { Routes as RRDRoutes, Route as RRDRoute } from 'react-router-dom';

import Home from '@pages/Home';
import ResetPassword from '@pages/ResetPassword';
import NotFound from '@pages/NotFound';
import Model from '@containers/Model';
import { UIModel } from '@store/user/types';

import {
  HOME_ROUTE,
  NAMED_ENTITY_RECOGNITION_HOME_ROUTE,
  RESET_PASSWORD_ROUTE,
  RELATION_EXTRACTION_HOME_ROUTE,
  TEXT_CLASSIFICATION_HOME_ROUTE,
  TEXT_TO_TABLE_HOME_ROUTE,
} from './list';
import NonAuthorizedRoute from './NonAuthorizedRoute';

const Routes = () => (
  <RRDRoutes>
    <RRDRoute path={HOME_ROUTE} element={<Home />}>
      <RRDRoute index element={<Model model={UIModel.TEXT_TO_TABLE} />} />
      <RRDRoute
        path={TEXT_TO_TABLE_HOME_ROUTE}
        element={<Model model={UIModel.TEXT_TO_TABLE} />}
      />
      <RRDRoute
        path={TEXT_CLASSIFICATION_HOME_ROUTE}
        element={<Model model={UIModel.TEXT_CLASSIFICATION} />}
      />
      <RRDRoute
        path={NAMED_ENTITY_RECOGNITION_HOME_ROUTE}
        element={<Model model={UIModel.NAMED_ENTITY_RECOGNITION} />}
      />
      <RRDRoute
        path={RELATION_EXTRACTION_HOME_ROUTE}
        element={<Model model={UIModel.RELATION_EXTRACTION} />}
      />
    </RRDRoute>
    <RRDRoute
      path={RESET_PASSWORD_ROUTE}
      element={
        <NonAuthorizedRoute>
          <ResetPassword />
        </NonAuthorizedRoute>
      }
    />
    <RRDRoute path="*" element={<NotFound />} />
  </RRDRoutes>
);

export default Routes;
