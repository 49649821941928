import { GroupBase, OptionsOrGroups, SingleValue } from 'react-select';

export type SelectOption<T = string> = {
  value: T;
  label: string;
};

export type SelectOptionsOrGroups<T = string> = OptionsOrGroups<
  SelectOption<T>,
  GroupBase<SelectOption<T>>
>;

export type SingleSelectValue<T = string> = SingleValue<SelectOption<T>>;

export enum SelectLabelPosition {
  LEFT = 'left',
  TOP = 'top',
}
