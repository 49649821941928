import {
  FC,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import useForceUpdate from '@hooks/useForceUpdate';
import useToggle from '@hooks/useToggle';

import {
  StyledItem,
  Details,
  LabelWrapper,
  Number,
  LabelParent,
  Label,
  Score,
  Actions,
  StyledLink,
} from './styled';

interface Props {
  number: number;
  label: string;
  score: string;
  handleRemove: VoidFunction;
  handleUpdate: (newLabel: string) => void;
}

const Item: FC<Props> = ({
  number,
  label,
  score,
  handleRemove,
  handleUpdate,
}) => {
  const text = useRef(label);

  const [clicked, toggleClicked, handleUnclick] = useToggle(false);

  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (text.current !== label) {
      text.current = label;
      forceUpdate();
    }
  }, [label]);

  const handleEditClick: MouseEventHandler<HTMLParagraphElement> = (event) => {
    event.stopPropagation();
  };

  const handleInput: FormEventHandler<HTMLParagraphElement> = (event) => {
    text.current = event.currentTarget.textContent || '';
  };

  const handleBlur = () => {
    handleUpdate(text.current);
  };

  const handleDeleteClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
    handleRemove();
    handleUnclick();
  };

  return (
    <StyledItem onClick={toggleClicked}>
      <Details>
        <LabelWrapper>
          <Number>{number}.</Number>
          <LabelParent>
            <Label
              contentEditable
              suppressContentEditableWarning
              onClick={handleEditClick}
              onInput={handleInput}
              onBlur={handleBlur}
            >
              {text.current}
            </Label>
          </LabelParent>
        </LabelWrapper>
        <Score>{score}</Score>
      </Details>
      {clicked && (
        <Actions>
          <StyledLink to="#" underline onClick={handleDeleteClick}>
            {t('components.classes.content.actions.delete')}
          </StyledLink>
        </Actions>
      )}
    </StyledItem>
  );
};

export default Item;
