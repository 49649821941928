import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import HomeContainer from '@containers/Home';
import {
  mapModelToMetaDescription,
  mapModelToReadableName,
} from '@components/Header/utils';
import Layout from '@components/Layout';

import { StyledHome } from './styled';

const Home = () => {
  const { t } = useTranslation();

  return (
    <StyledHome>
      <Layout>
        {(model) => {
          const title = `Knowledgator AI Playground - ${t(
            mapModelToReadableName[model]
          )}`;
          const description = t(mapModelToMetaDescription[model]);
          return (
            <>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
              </Helmet>
              <HomeContainer model={model} />
            </>
          );
        }}
      </Layout>
    </StyledHome>
  );
};

export default Home;
