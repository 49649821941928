import styled from 'styled-components';

import Link from '@components/Link';

export const StyledEntities = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  margin-right: 2rem;
  font-size: 2rem;
`;

export const CreatableSpace = styled.div<{ $focused: boolean }>`
  position: relative;
  width: ${(props) =>
    props.$focused
      ? props.theme.creatable.minWidth * 1.5
      : props.theme.creatable.minWidth}rem;
`;

export const CreatableWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: -${(props) => props.theme.creatable.minHeight / 2}rem;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  margin-left: 1.75rem;
  color: ${(props) => props.theme.processor.entitiesLinkColor};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.processor.entitiesLinkColor};
  }
`;
