import { RefObject, useEffect } from 'react';

const useAccordionAnimation = (
  containerRef: RefObject<HTMLDivElement>,
  opened: boolean
) => {
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const { current: container } = containerRef;
    container.style.maxHeight = opened ? `${container.scrollHeight}px` : '0';
  }, [opened, containerRef]);
};

export default useAccordionAnimation;
