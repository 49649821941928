import styled from 'styled-components';

import IconButton from '@components/IconButton';
import Input from '@components/Input';

export const FormFields = styled.div`
  padding: 0 4rem;
`;

export const StyledInput = styled(Input)``;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledRightIcon = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const Hint = styled.div`
  margin-top: 6rem;
  text-align: center;
`;
