import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';
import { IconButtonType } from '@components/IconButton/types';

export const iconButtonTheme: DefaultTheme['iconButton'] = {
  [IconButtonType.PRIMARY]: {
    backgroundColor: Color.CORNFLOWER_BLUE,
    borderColor: Color.TRANSPARENT,
    borderStyle: 'solid',
    hover: {
      backgroundColor: Color.INDIGO,
      borderColor: Color.TRANSPARENT,
    },
    active: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.SILVER_CHALICE,
    },
  },
  [IconButtonType.SECONDARY]: {
    backgroundColor: Color.TRANSPARENT,
    borderColor: Color.CORNFLOWER_BLUE,
    borderStyle: 'solid',
    hover: {
      backgroundColor: Color.CORNFLOWER_BLUE,
      borderColor: Color.CORNFLOWER_BLUE,
    },
    active: {
      backgroundColor: Color.INDIGO,
      borderColor: Color.INDIGO,
    },
  },
  [IconButtonType.DOTTED]: {
    backgroundColor: Color.TRANSPARENT,
    borderColor: Color.SILVER_CHALICE,
    borderStyle: 'dashed',
    hover: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.SILVER_CHALICE,
    },
    active: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.SILVER_CHALICE,
    },
  },
  [IconButtonType.PLAIN]: {
    backgroundColor: Color.TRANSPARENT,
    borderColor: Color.TRANSPARENT,
    borderStyle: 'solid',
    hover: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.TRANSPARENT,
    },
    active: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.TRANSPARENT,
    },
  },
};
