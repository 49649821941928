import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import IconButton from '@components/IconButton';
import { IconButtonType } from '@components/IconButton/types';
import {
  NotificationReason,
  NotificationType,
} from '@store/notification/types';

import { StyledNotification, Text, ActionIconButton } from './styled';
import {
  mapNotificationReasonToMessageId,
  mapNotificationTypeToIconType,
} from './utils';

interface Props {
  type: NotificationType;
  reason: NotificationReason;
  handleRemove: VoidFunction;
}

const NotificationComponent: FC<Props> = ({ type, reason, handleRemove }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledNotification $type={type}>
      <IconButton type={IconButtonType.PLAIN}>
        <Icon
          type={mapNotificationTypeToIconType[type]}
          stroke={theme.notification[type].iconColor}
          width={2.4}
          height={2.4}
          large
        />
      </IconButton>
      <Text>{t(mapNotificationReasonToMessageId[reason])}</Text>
      <ActionIconButton type={IconButtonType.PLAIN} onClick={handleRemove}>
        <Icon
          type={IconType.CROSS_CIRCLE}
          stroke={theme.notification[type].iconColor}
          large
        />
      </ActionIconButton>
    </StyledNotification>
  );
};

export default NotificationComponent;
