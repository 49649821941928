import styled from 'styled-components';

export const StyledBarChart = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.barChart.backgroundColor};
  border: 1px solid ${(props) => props.theme.barChart.borderColor};
  border-radius: 1.5rem;
`;

export const Chart = styled.div`
  height: ${(props) => props.theme.barChart.chartHeight}rem;
  font-size: 1.4rem;
  position: relative;
`;
