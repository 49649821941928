import { FC, PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import { useTheme } from 'styled-components';

import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';

import { DEFAULT_MODAL_ROOT_ID, MODAL_BODY_OPEN_CLASS_NAME } from './defaults';
import { Actions, StyledIconButton, Content, getModalStyles } from './styled';

ReactModal.setAppElement(`#${DEFAULT_MODAL_ROOT_ID}`);

export interface Props {
  isOpen: boolean;
  handleClose?: VoidFunction;
  info?: boolean;
  animate?: boolean;
}

const Modal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  handleClose,
  info,
  animate,
  children,
}) => {
  const theme = useTheme();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      bodyOpenClassName={MODAL_BODY_OPEN_CLASS_NAME}
      closeTimeoutMS={animate ? theme.modal.transitionTime * 1000 : undefined}
      style={getModalStyles(theme)}
    >
      <Actions>
        {info && (
          <StyledIconButton type={IconButtonType.PLAIN} $left>
            <Icon type={IconType.INFO_CIRCLE} large />
          </StyledIconButton>
        )}
        {handleClose && (
          <StyledIconButton
            type={IconButtonType.PLAIN}
            onClick={handleClose}
            $right={!!info}
          >
            <Icon type={IconType.CROSS_CIRCLE} large />
          </StyledIconButton>
        )}
      </Actions>
      <Content>{children}</Content>
    </ReactModal>
  );
};

export default Modal;
