import {
  OPEN_TUTORIAL,
  CLOSE_TUTORIAL,
  ADD_UI_ERROR,
  REMOVE_UI_ERROR,
  CLEAR_UI_ERRORS,
  SET_TUTORIAL_STEP_INDEX,
} from './actionTypes';
import { UIActions, UIState } from './types';

const initialState: UIState = {
  tutorial: {
    opened: false,
    stepIndex: 0,
  },
  errors: [],
};

const reducer = (state = initialState, action: UIActions): UIState => {
  switch (action.type) {
    case OPEN_TUTORIAL: {
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          opened: true,
          stepIndex: action.payload,
        },
      };
    }
    case SET_TUTORIAL_STEP_INDEX:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          stepIndex: action.payload,
        },
      };
    case CLOSE_TUTORIAL:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          opened: false,
        },
      };
    case ADD_UI_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    }
    case REMOVE_UI_ERROR: {
      return {
        ...state,
        errors: state.errors.filter((error) => error !== action.payload),
      };
    }
    case CLEAR_UI_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
