import { ContentLimitType } from '@containers/Processor/types';
import { countChars, countWords } from '@containers/Processor/utils';

export const mapContentLimitTypeToCounter: Record<
  ContentLimitType,
  (value: string) => number
> = {
  [ContentLimitType.WORDS]: countWords,
  [ContentLimitType.CHARS]: countChars,
};

export const mapContentLimitTypeToMaxTextMessageId: Record<
  ContentLimitType,
  string
> = {
  [ContentLimitType.WORDS]: 'components.processor.footer.limits.words.max',
  [ContentLimitType.CHARS]: 'components.processor.footer.limits.chars.max',
};
