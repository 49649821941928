/* Auth */
export const SIGN_IN = '/users/signin';
export const SIGN_UP = '/users/signup';
export const SIGN_OUT = '/users/signout';
/* */

/* Change/Validate Password */
export const RESET_PASSWORD_REQUEST = '/users/password_reset_request';
export const RESET_PASSWORD = '/users/password_reset';
export const CHANGE_PASSWORD = '/users/password';
export const VALIDATE_PASSWORD = '/users/validate_password';
/* */

/* User */
export const CURRENT_USER = '/users/currentuser';
/* */

/* File retreival */
export const FILE_DETAILS = '/user_account/file/:id';
export const getFileDetailsEndpoint = (id: number) =>
  FILE_DETAILS.replace(':id', id.toString());
/* */

/* ---------- Relation Extraction endpoints ---------- */

/* Text processing */
export const RELATION_EXTRACTION_PROCESS = '/rex';
/* */

/* ---------- Named Entity Recognition endpoints ---------- */

/* Text processing */
export const NAMED_ENTITY_RECOGNITION_PROCESS = '/ner/deterministic';
/* */

/* ---------- --- ---------- */

/* ---------- Text Classification endpoints ---------- */

/* Text processing */
export const TEXT_CLASSIFICATION_PROCESS = '/predictions/ml-zero-nli-model';
/* */

/* ---------- --- ---------- */

/* ---------- Text to Table endpoints ---------- */

/* Text pre-processing */
export const TEXT_TO_TABLE_PRE_PROCESS = '/t2t/text_preprocessing';
/* */

/* Link pre-processing */
export const TEXT_TO_TABLE_LINK_PRE_PROCESS = '/t2t/web2text';
/* */

/* File pre-processing */
export const TEXT_TO_TABLE_FILE_PRE_PROCESS = '/t2t/pdf2text';
/* */

/* Text processing */
export const TEXT_TO_TABLE_PROCESS = '/t2t/text2table';
/* */

/* ---------- --- ---------- */
