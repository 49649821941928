import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const cookieTheme: DefaultTheme['cookie'] = {
  backgroundColor: Color.MINE_SHAFT,
  borderColor: Color.TUNDORA,
  color: Color.WHITE,
  lineColor: Color.WHITE,
  moreBorderColor: Color.HIPPIE_GREEN,
};
