import styled from 'styled-components';

export const StyledFile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${(props) => props.theme.fileInput.file.width}rem;
`;

export const PreviewWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  margin-top: 1rem;
  border-radius: 1.4rem;
`;
