import {
  FC,
  FocusEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import { InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';
import { DefaultTFuncReturn } from 'i18next';

import IconButton from '@components/IconButton/IconButton';
import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon/Icon';
import { IconType } from '@components/Icon/types';

import { DEFAULT_CREATABLE_ROOT_ID } from './defaults';
import { StyledIconButton, StyledIcon, getCreatableStyles } from './styled';
import { MultiCreatableValue } from './types';

interface Props {
  autoFocus?: boolean;
  inputValue?: string;
  handleInputChange: (value: string, actionMeta: InputActionMeta) => void;
  value?: MultiCreatableValue;
  handleChange: (value: MultiCreatableValue) => void;
  handleKeyDown: KeyboardEventHandler<HTMLDivElement>;
  placeholder?: DefaultTFuncReturn;
  focused?: boolean;
  uiError?: boolean;
  handleFocus?: FocusEventHandler<HTMLInputElement>;
  handleBlur?: FocusEventHandler<HTMLInputElement>;
}

const Creatable: FC<PropsWithChildren<Props>> = ({
  autoFocus = false,
  inputValue,
  handleInputChange,
  value,
  handleChange,
  handleKeyDown,
  placeholder,
  focused = false,
  uiError = false,
  handleFocus,
  handleBlur,
}) => {
  const theme = useTheme();

  const handleDropdownIndicatorClick = (event: MouseEvent<HTMLDivElement>) => {
    handleKeyDown({
      preventDefault: event.preventDefault,
      key: 'Enter',
    } as KeyboardEvent<HTMLDivElement>);
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      autoFocus={autoFocus}
      menuIsOpen={false}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      components={{
        DropdownIndicator: () => (
          <IconButton
            type={IconButtonType.PLAIN}
            onClick={handleDropdownIndicatorClick}
          >
            <Icon
              type={IconType.PLUS}
              stroke={
                inputValue?.length === 0
                  ? theme.creatable.dropdownIndicatorDisabledColor
                  : theme.creatable.dropdownIndicatorColor
              }
            />
          </IconButton>
        ),
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        MultiValueRemove: (props) => (
          <StyledIconButton
            type={IconButtonType.PLAIN}
            onClick={props.innerProps.onClick}
          >
            <StyledIcon
              type={IconType.CROSS}
              stroke={theme.creatable.multiValueRemoveIconColor}
            />
          </StyledIconButton>
        ),
      }}
      menuPortalTarget={
        document.querySelector(`#${DEFAULT_CREATABLE_ROOT_ID}`) as HTMLElement
      }
      onFocus={handleFocus}
      onBlur={handleBlur}
      styles={getCreatableStyles(
        theme,
        focused,
        !value || value.length === 0,
        uiError
      )}
    />
  );
};

export default Creatable;
