import { FC, PropsWithChildren } from 'react';

import { StyledPlayground } from './styled';

interface Props extends PropsWithChildren {
  className?: string;
}

const Playground: FC<Props> = ({ className, children }) => (
  <StyledPlayground className={className}>{children}</StyledPlayground>
);

export default Playground;
