import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import Loader from '@components/Loader';
import {
  getCheckAuthLoadingSelector,
  getCurrentUserSelector,
} from '@store/auth/selectors';
import { useAppSelector } from '@store/hooks';

import { HOME_ROUTE } from './list';

const NonAuthorizedRoute: FC<PropsWithChildren<any>> = ({ children }) => {
  const loading = useAppSelector(getCheckAuthLoadingSelector);
  const currentUser = useAppSelector(getCurrentUserSelector);

  if (loading) {
    return <Loader />;
  }

  if (currentUser) {
    return <Navigate to={HOME_ROUTE} />;
  }

  return children;
};

export default NonAuthorizedRoute;
