import { down } from 'styled-breakpoints';
import { createGlobalStyle, css } from 'styled-components';

import { MODAL_BODY_OPEN_CLASS_NAME } from '@components/Modal/defaults';

import { CORE_FONT_SIZE_PX, FONT_FAMILY } from './defaults';

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: ${(props) => props.theme.scrollbar.width}px;
  }

  ::-webkit-scrollbar:horizontal {
    height: ${(props) => props.theme.scrollbar.height}px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.scrollbar.track.backgroundColor};
    border-radius: 0.2rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.scrollbar.thumb.backgroundColor};
    border-radius: 0.2rem;
  }
`;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    font-size: ${CORE_FONT_SIZE_PX}px;

    ${down('xxl')} {
      font-size: ${CORE_FONT_SIZE_PX - 2}px;
    }

    ${down('xl')} {
      font-size: ${CORE_FONT_SIZE_PX - 3}px;
    }
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    font-family: ${FONT_FAMILY};
    color: ${(props) => props.theme.generic.color};
    background-color: ${(props) => props.theme.generic.backgroundColor};

    &.${MODAL_BODY_OPEN_CLASS_NAME} {
      overflow: hidden;
    }
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-weight: 400;
  }

  ${scrollbarStyle};
`;

export default GlobalStyle;
