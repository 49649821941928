import { FC, useEffect, useRef } from 'react';
import parse from 'html-react-parser';

import { TextToTableHighlightedData } from '@containers/Processor/types';
import {
  NEW_LINE_SYMBOL,
  EMPTY_SENTENCE_VALUE,
} from '@components/Highlighter/defaults';
import {
  StyledHighlighter,
  StyledModelSentence,
} from '@components/Highlighter/styled';

import { prepareTextToTableText } from './algorithm';
import { getTextToTableOptions, isElementInWindowViewportByTop } from './utils';

interface Props {
  sentences: string[];
  highlightedData?: TextToTableHighlightedData[];
  handleEdit?: VoidFunction;
}

const TextToTable: FC<Props> = ({
  sentences,
  highlightedData = [],
  handleEdit,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || highlightedData.length === 0) {
      return;
    }
    if (!isElementInWindowViewportByTop(ref.current)) {
      ref.current.scrollTo({
        top: 0,
      });
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [highlightedData]);

  const preparedText = prepareTextToTableText(
    sentences.join(''),
    highlightedData
  );
  const splittedText = preparedText.split(NEW_LINE_SYMBOL);
  return (
    <StyledHighlighter ref={ref} onClick={handleEdit}>
      {splittedText.map((sentence, index) => {
        if (!sentence) {
          return (
            <StyledModelSentence key={index} $hidden>
              {EMPTY_SENTENCE_VALUE}
            </StyledModelSentence>
          );
        }
        const value = parse(sentence, getTextToTableOptions());
        return <StyledModelSentence key={index}>{value}</StyledModelSentence>;
      })}
    </StyledHighlighter>
  );
};

export default TextToTable;
