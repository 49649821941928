import styled from 'styled-components';

export const StyledTextCutter = styled.div``;

export const Action = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: ${(props) => props.theme.textCutter.color};
`;
