import { Element, HTMLReactParserOptions, Text } from 'html-react-parser';

import Entity from './Entity';

export const getNamedEntityRecognitionOptions = (): HTMLReactParserOptions => ({
  replace: (domNode) => {
    const elementDomNode = domNode as Element;
    if (elementDomNode.attribs && elementDomNode.attribs['data-score']) {
      const entity = (elementDomNode.children[0] as Text).data;
      const type = ((elementDomNode.children[1] as Element).children[0] as Text)
        .data;
      const active = elementDomNode.attribs.class.includes('active');
      const score = elementDomNode.attribs['data-score'];
      return (
        <Entity
          active={active}
          entity={entity}
          type={type}
          score={`${Math.round(parseFloat(score) * 100)}%`}
        />
      );
    }
    return domNode;
  },
});
