import { FC, useEffect, useRef } from 'react';
import Lottie, { LottieRef } from 'lottie-react';

import { JSON } from '@components/Stepper/Step/types';

import { StyledAnimation } from './styled';

interface Props {
  json: JSON;
  speedFactor: number;
}

const Animation: FC<Props> = ({ json, speedFactor }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef(null) as LottieRef;

  useEffect(() => {
    lottieRef.current?.setSpeed(speedFactor);
  }, [speedFactor]);

  return (
    <StyledAnimation ref={wrapperRef}>
      <Lottie animationData={json} loop={false} lottieRef={lottieRef} />
    </StyledAnimation>
  );
};

export default Animation;
