import styled from 'styled-components';

export const StyledLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin: 4rem;
  flex: auto;
  overflow-y: auto;
`;
