import { KeyboardEvent } from 'react';

export enum MacrosKey {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export type MacrosKeyWithListener = Record<string, VoidFunction>;

export interface MacrosProps {
  keysWithListeners: MacrosKeyWithListener;
}

export interface MacrosHandler {
  handleKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
}
