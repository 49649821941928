import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const tableTheme: DefaultTheme['table'] = {
  titleColor: Color.SILVER_CHALICE,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  hoverBackgroundColor: Color.MINE_SHAFT,
  updateBackgroundColor: Color.MINE_SHAFT_LIGHT,
  borderColor: Color.TUNDORA,
  borderStyle: 'solid',
  hoverBorderColor: Color.CORNFLOWER_BLUE,
  hoverBorderStyle: 'dashed',
  errorBorderColor: Color.FLUSH_MAHOGANY,
  errorBorderStyle: 'dashed',
  labelEditableBackgroundColor: Color.CORNFLOWER_BLUE_50,
  headingLabelColor: Color.SILVER_CHALICE,
  header: {
    action: {
      iconColor: Color.SILVER_CHALICE,
      hovered: {
        iconColor: Color.CORNFLOWER_BLUE,
      },
    },
  },
};
