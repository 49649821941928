import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';
import { EntityPosition } from '@components/Relations/Content/Item/types';

export const entityTheme: DefaultTheme['entity'] = {
  [EntityPosition.SOURCE]: {
    backgroundColor: Color.GRAY,
    borderColor: Color.GRAY,
    typeBackgroundColor: Color.GRAY,
    typeColor: Color.MINE_SHAFT,
    selected: {
      backgroundColor: Color.PIZZA,
      borderColor: Color.PIZZA,
      typeBackgroundColor: Color.FUEL_YELLOW,
      typeColor: Color.MINE_SHAFT,
    },
    hovered: {
      backgroundColor: Color.ALTO,
      borderColor: Color.ALTO,
      typeBackgroundColor: Color.GRAY,
      typeColor: Color.MINE_SHAFT,
    },
  },
  [EntityPosition.TARGET]: {
    backgroundColor: Color.GRAY,
    borderColor: Color.GRAY,
    typeBackgroundColor: Color.GRAY,
    typeColor: Color.MINE_SHAFT,
    selected: {
      backgroundColor: Color.HIPPIE_GREEN,
      borderColor: Color.HIPPIE_GREEN,
      typeBackgroundColor: Color.ENVY,
      typeColor: Color.MINE_SHAFT,
    },
    hovered: {
      backgroundColor: Color.ALTO,
      borderColor: Color.ALTO,
      typeBackgroundColor: Color.GRAY,
      typeColor: Color.MINE_SHAFT,
    },
  },
};
