import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const chipTheme: DefaultTheme['chip'] = {
  iconHoverColor: Color.MINE_SHAFT_MEDIUM,
  backgroundColor: Color.TRANSPARENT,
  color: Color.SILVER_CHALICE,
  borderColor: Color.TRANSPARENT,
  borderStyle: 'solid',
  selected: {
    backgroundColor: Color.CORNFLOWER_BLUE,
    color: Color.WHITE,
    borderColor: Color.CORNFLOWER_BLUE,
    borderStyle: 'solid',
  },
  active: {
    backgroundColor: Color.INDIGO,
    color: Color.WHITE,
    borderColor: Color.INDIGO,
    borderStyle: 'solid',
  },
  hover: {
    backgroundColor: Color.TRANSPARENT,
    color: Color.SILVER_CHALICE,
    borderColor: Color.SILVER_CHALICE,
    borderStyle: 'dashed',
  },
};
