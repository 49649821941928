import { createSelector } from 'reselect';

import { AppState } from '@store/rootReducer';

export const getTutorialState = (state: AppState) => state.ui.tutorial;

export const getErrorsState = (state: AppState) => state.ui.errors;

export const getTutorialSelector = createSelector(
  getTutorialState,
  (tutorial) => tutorial
);

export const getErrorsSelector = createSelector(
  getErrorsState,
  (errors) => errors
);
