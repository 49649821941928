import styled from 'styled-components';

import Button from '@components/Button';

export const StyledStepper = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

export const Title = styled.p`
  text-transform: uppercase;
  margin-right: 3rem;
  color: ${(props) => props.theme.stepper.header.textColor};
  font-weight: 600;
  font-size: 2.4rem;
`;

export const Action = styled.p`
  cursor: pointer;
  color: ${(props) => props.theme.stepper.header.actionColor};
  font-size: 2.1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressWrapper = styled.div`
  margin-right: 3rem;
`;

export const ActionWrapper = styled.div`
  margin-right: 3rem;
`;

export const StyledButton = styled(Button)`
  padding: 1rem 1.5rem;
  border-radius: 0.4rem;
`;
