import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { countWords, removeExcessiveWords } from '@containers/Processor/utils';
import IconButton from '@components/IconButton/IconButton';
import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';

import { StyledTextCutter, Action } from './styled';

interface Props {
  value: string;
  maxWords: number;
}

const TextCutter: FC<Props> = ({ value, maxWords }) => {
  const wordsCount = countWords(value);

  const [displayedValue, setDisplayedValue] = useState('');

  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setDisplayedValue(value);
  }, [value]);

  useEffect(() => {
    if (wordsCount > maxWords) {
      setDisplayedValue(removeExcessiveWords(value, maxWords));
    }
  }, [wordsCount, maxWords, value]);

  const handleShowMore = () => {
    setDisplayedValue(value);
  };

  const handleShowLess = () => {
    setDisplayedValue(removeExcessiveWords(value, maxWords));
  };

  const showMore =
    wordsCount > maxWords && value.length > displayedValue.length;
  const showLess =
    wordsCount > maxWords && value.length === displayedValue.length;
  return (
    <StyledTextCutter>
      {displayedValue}
      {(showMore || showLess) && (
        <>
          {showMore && '...'}
          <Action>
            {showMore
              ? t('components.textCutter.more')
              : t('components.textCutter.less')}
            <IconButton type={IconButtonType.PLAIN}>
              <Icon
                type={IconType.CHEVRON_DOWN}
                onClick={showMore ? handleShowMore : handleShowLess}
                stroke={theme.textCutter.color}
                large
                style={
                  showMore
                    ? undefined
                    : {
                        transform: 'rotate(180deg)',
                      }
                }
              />
            </IconButton>
          </Action>
        </>
      )}
    </StyledTextCutter>
  );
};

export default TextCutter;
