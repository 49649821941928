import { Styles } from 'react-modal';
import styled, { css, DefaultTheme } from 'styled-components';

import IconButton from '@components/IconButton';

export const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
`;

export const StyledIconButton = styled(IconButton)<{
  $left?: boolean;
  $right?: boolean;
}>`
  ${(props) =>
    props.$left &&
    css`
      padding-right: 0.5rem;
    `};

  ${(props) =>
    props.$right &&
    css`
      padding-left: 0.5rem;
    `};
`;

export const Content = styled.div`
  padding: 3rem;
`;

export const getModalStyles = (theme: DefaultTheme): Styles => ({
  overlay: {
    zIndex: theme.modal.zIndex,
    backdropFilter: 'blur(2.5px)',
    WebkitBackdropFilter: 'blur(2.5px)',
    backgroundColor: theme.modal.overlay.backgroundColor,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 0,
    borderRadius: '1.5rem',
    boxShadow: `0 4px 4px -4px ${theme.modal.content.boxShadowColor}`,
    padding: 0,
    backgroundColor: theme.modal.content.backgroundColor,
    overflow: 'visible',
    maxWidth: '100%',
  },
});
