import {
  CreatableOption,
  MultiCreatableValue,
} from '@components/Creatable/types';
import {
  NamedEntityRecognitionData,
  RelationExtractionData,
  TextClassificationData,
  TextToTableData,
} from '@store/user/types';
import { Color } from '@theme/colors';

export enum RelationExtractionTextTopic {
  SCIENTIFIC = 'scientific',
  TECHNOLOGY = 'technology',
  FINANCE = 'finance',
  BUSINESS = 'business',
  HEALTHCARE = 'healthcare',
  SOCIAL = 'social',
}

export enum NamedEntityRecognitionTextTopic {
  LIFE_SCIENCES = 'lifeSciences',
  NEWS = 'news',
  USER_REVIEW = 'userReview',
}

export enum TextClassificationTextTopic {
  SENTIMENT_ANALYSIS = 'sentimentAnalysis',
  INTENT_RECOGNITION = 'intentRecognition',
  INFLUENCER_QUEALIFICATION = 'influencerQualification',
  CONTENT_TAGGING = 'contentTagging',
  LIFE_SCIENCES = 'lifeSciences',
}

export enum TextToTableTextTopic {
  LiFE_SCIENCES = 'lifeSciences',
  NEWS = 'news',
  USER_REVIEW = 'userReview',
}

export enum TextType {
  SAMPLE = 'sample',
  CUSTOM = 'custom',
}

export enum TextStatus {
  EMPTY = 'empty',
  PENDING_ERROR = 'pendingError',
  PENDING_VALID = 'pendingValid',
  READY_FOR_PROCESSING = 'readyForProcessing',
  PROCESSING_PENDING = 'processingPending',
  PROCESSING_SUCCESS = 'processingSuccess',
  PROCESSING_ERROR = 'processingError',
  HIGHLIGHTED = 'highlighted',
}

export interface NamedEntityRecognitionSampleText {
  initialLabels: string[];
  labels: string[];
  data: NamedEntityRecognitionData[];
}

export interface TextClassificationSampleText {
  initialLabels: MultiCreatableValue;
  labels: MultiCreatableValue;
  pendingLabels: MultiCreatableValue;
  data: TextClassificationData;
}

export interface TextToTableColumn extends CreatableOption {
  updated: boolean;
  new: boolean;
}

export interface TextToTableHighlightedData {
  column: keyof TextToTableData;
  label: string;
  color: Color;
}

export type TextToTableHighlightedElement =
  | {
      type: 'row' | 'column' | 'cell';
      index: number;
    }
  | undefined;

export interface TextToTableSampleText {
  initialColumns: TextToTableColumn[];
  columns: TextToTableColumn[];
  data: TextToTableData;
  highlightedData: TextToTableHighlightedData[];
  highlightedElement: TextToTableHighlightedElement;
}

export interface RelationExtractionSampleText {
  relations: RelationExtractionData[];
  activeRelations: RelationExtractionData[];
  selectedRelations: RelationExtractionData[];
  hoveredRelations: RelationExtractionData[];
}

export interface SampleText {
  title: string;
  content: string[];
  files: File[];
  type: TextType;
  status: TextStatus;
  changed: boolean;
  link: boolean;
  namedEntityRecognition?: NamedEntityRecognitionSampleText;
  textClassification?: TextClassificationSampleText;
  textToTable?: TextToTableSampleText;
  relationExtraction?: RelationExtractionSampleText;
}

export enum ContentLimitType {
  CHARS = 'chars',
  WORDS = 'words',
}

export interface ContentLimit {
  type: ContentLimitType;
  min: number;
  max: number;
  truncate: boolean;
}

export interface ValidateTextResult {
  text: string;
  isValid: boolean;
}
