import { FC, MouseEventHandler } from 'react';

import { StyledRadio } from './styled';
import { RelationState } from './types';

interface Props {
  state: RelationState;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Radio: FC<Props> = ({ state, className, onClick }) => (
  <StyledRadio className={className} $state={state} onClick={onClick} />
);

export default Radio;
