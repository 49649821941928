export enum BarChartType {
  PRECISION = 'PRECISION',
  COST = 'COST',
}

export interface DataPoint {
  label: string;
  value: number;
  highlight?: boolean;
}
