import { FC, FormEvent, PropsWithChildren } from 'react';

import useMacros from '@hooks/useMacros';
import { MacrosKey } from '@hooks/useMacros/types';

import { StyledForm } from './styled';

interface Props {
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}

const Form: FC<PropsWithChildren<Props>> = ({ handleSubmit, children }) => {
  const { handleKeyDown } = useMacros({
    keysWithListeners: {
      [MacrosKey.ENTER]: handleSubmit,
    },
  });

  return (
    <StyledForm onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      {children}
    </StyledForm>
  );
};

export default Form;
