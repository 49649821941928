import styled, { css } from 'styled-components';

export const StyledCell = styled.div<{
  $highlight?: boolean;
}>`
  ${(props) =>
    props.$highlight &&
    css`
      background-color: ${(props) => props.theme.table.hoverBackgroundColor};
    `};
`;

export const Content = styled.div<{
  $uiError: boolean;
  $last: boolean;
  $highlight: boolean;
  $height?: number;
}>`
  padding: 2rem;
  min-height: 7.8rem;
  max-height: calc(7.8rem * 3);

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `};

  ${(props) =>
    !props.$last &&
    css`
      border-bottom-width: 1px;
      border-bottom-style: ${props.$uiError
        ? props.theme.table.errorBorderStyle
        : props.theme.table.borderStyle};
      border-bottom-color: ${props.$uiError
        ? props.theme.table.errorBorderColor
        : props.theme.table.borderColor};
    `};

  ${(props) =>
    props.$highlight &&
    css`
      background-color: ${props.theme.table.hoverBackgroundColor};
    `};
`;

export const Label = styled.p`
  overflow-y: auto;
  height: 100%;
`;
