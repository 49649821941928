import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

type TWindowSize = [number, number];

type THook = TWindowSize;

export default (): THook => {
  const initSize: TWindowSize = [window.innerWidth, window.innerHeight];

  const [windowSize, setWindowSize] = useState<TWindowSize>(initSize);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
