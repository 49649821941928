import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const selectTheme: DefaultTheme['select'] = {
  minHeight: 5,
  width: 18,
  zIndex: 9998,
  backgroundColor: Color.MINE_SHAFT,
  iconColor: Color.WHITE,
  openedIconColor: Color.CORNFLOWER_BLUE,
  optionColor: Color.SILVER_CHALICE,
  selectedOptionColor: Color.CORNFLOWER_BLUE,
  borderColor: Color.CORNFLOWER_BLUE,
  groupBorderColor: Color.TUNDORA,
  hover: {
    optionColor: Color.WHITE,
    optionBackgroundColor: Color.TUNDORA,
  },
};
