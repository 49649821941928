import styled from 'styled-components';

import Link from '@components/Link';

export const StyledUserMenu = styled.div`
  position: relative;
`;

export const MenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  right: 0;
  min-width: ${(props) => props.theme.userMenu.minWidth}rem;
  background-color: ${(props) => props.theme.userMenu.backgroundColor};
  z-index: ${(props) => props.theme.userMenu.zIndex};
  border-radius: 1.5rem;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.userMenu.borderColor};
`;

export const UserCredentials = styled.div`
  margin-left: 1.5rem;
`;

export const UserName = styled.h3`
  margin-bottom: 0.5rem;
`;

export const UserEmail = styled.p``;

export const UserActions = styled.div``;

export const UserAction = styled(Link)`
  padding: 1.5rem;
`;
