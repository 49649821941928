import styled from 'styled-components';

export const StyledContent = styled.div`
  height: ${(props) => props.theme.relations.maxContentHeight}rem;
  overflow-y: auto;
  flex: auto;
  padding-right: 2.5rem;
  margin: 2rem 2.5rem 2rem 0;
`;

export const NoData = styled.p`
  padding: 12rem 3rem 0 8rem;
  font-size: 2.1rem;
  line-height: 3.8rem;
  color: ${(props) => props.theme.relations.noDataColor};
  text-align: center;
`;
