import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { ButtonType } from '@components/Button/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import Link from '@components/Link';
import useAccordionAnimation from '@hooks/useAccordionAnimation/useAccordionAnimation';
import useToggle from '@hooks/useToggle/useToggle';

import { COOKIES_ALLOWED_KEY } from './defaults';
import {
  StyledCookies,
  Title,
  Separator,
  Details,
  Teaser,
  More,
  MoreTitle,
  MoreContent,
  MoreContentText,
  Actions,
} from './styled';
import { CookieState } from './types';
import { enableAnalytics, sendDeclineCookiesEvent } from './utils';

const Cookies = () => {
  const moreContentRef = useRef<HTMLDivElement>(null);

  const [cookies, setCookie] = useCookies([COOKIES_ALLOWED_KEY]);

  const { t } = useTranslation();

  const [cookieState, setCookieState] = useState<CookieState>(
    CookieState.PENDING
  );
  const [opened, toggleOpened] = useToggle(false);

  useAccordionAnimation(moreContentRef, opened);

  useEffect(() => {
    const cookiesAllowed = cookies[COOKIES_ALLOWED_KEY];
    setCookieState(
      cookiesAllowed === undefined
        ? CookieState.NOT_SET
        : cookiesAllowed === 1
        ? CookieState.ALLOWED
        : CookieState.DECLINED
    );
  }, [cookies]);

  useEffect(() => {
    if (cookieState !== CookieState.ALLOWED) {
      return;
    }
    setTimeout(() => {
      enableAnalytics();
    }, 2000);
  }, [cookieState]);

  const setDomainCookie = (value: number) => {
    setCookie(
      COOKIES_ALLOWED_KEY,
      value,
      window.location.hostname !== 'localhost'
        ? { domain: '.knowledgator.com' }
        : undefined
    );
  };

  const handleAllow: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setDomainCookie(1);
    setCookieState(CookieState.ALLOWED);
  };

  const handleDecline: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setDomainCookie(0);
    setCookieState(CookieState.DECLINED);
    sendDeclineCookiesEvent();
  };

  const handleMoreTitleLinkClick: MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
  };

  if (cookieState !== CookieState.NOT_SET) {
    return null;
  }

  return (
    <StyledCookies>
      <Title>
        {t('components.cookies.title')}
        <Separator />
      </Title>
      <Details>
        <Teaser>{t('components.cookies.teaser')}</Teaser>
        <More>
          <MoreTitle onClick={toggleOpened}>
            <Link to="#" onClick={handleMoreTitleLinkClick}>
              {t('components.cookies.more.title')}
            </Link>
            <Icon
              type={IconType.CHEVRON_DOWN}
              style={{ transform: opened ? 'rotate(180deg)' : undefined }}
              large
            />
          </MoreTitle>
          <MoreContent ref={moreContentRef}>
            <MoreContentText>
              {t('components.cookies.more.content')}
            </MoreContentText>
          </MoreContent>
        </More>
      </Details>
      <Actions>
        <Button to="#" onClick={handleAllow}>
          {t('components.cookies.actions.allow')}
        </Button>
        <Button type={ButtonType.QUATERNARY} to="#" onClick={handleDecline}>
          {t('components.cookies.actions.decline')}
        </Button>
      </Actions>
    </StyledCookies>
  );
};

export default Cookies;
