import { FC } from 'react';
import JsonViewer from '@microlink/react-json-view';

import { StyledJsonView } from './styled';

interface Props {
  value: object;
}

const JsonView: FC<Props> = ({ value }) => (
  <StyledJsonView>
    <JsonViewer
      src={value}
      collapsed={2}
      theme="twilight"
      style={{
        padding: '2.5rem',
        backgroundColor: 'transparent',
      }}
    />
  </StyledJsonView>
);

export default JsonView;
