import styled from 'styled-components';

export const StyledSVG = styled.svg<{ $height?: number }>`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.$height ? `${props.$height}px` : '100%')};
`;

export const StyledPath = styled.path``;

export const StyledRect = styled.rect``;

export const StyledText = styled.text``;
