import styled from 'styled-components';

import Link from '@components/Link';

export const StyledHeader = styled.div`
  height: ${(props) => props.theme.header.height}rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  background-color: ${(props) => props.theme.header.backgroundColor};
`;

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
`;

export const Brand = styled.p`
  font-size: 2.3rem;
  margin-left: 2rem;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DesktopMenuWrapper = styled.div`
  margin-left: 4rem;

  @media (max-width: 992px) {
    display: none;
    margin-left: 0;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    margin-left: 4rem;
  }
`;

export const UserMenuWrapper = styled.div`
  margin-left: 2rem;
`;
