import styled from 'styled-components';

import Icon from '@components/Icon';

export const StyledUrlText = styled.div<{ $uiError: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.$uiError
      ? props.theme.fileInput.emptyText.errorLinkColor
      : props.theme.fileInput.emptyText.linkColor};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)<{ $uiError: boolean }>`
  margin-right: 0.5rem;
  stroke: ${(props) =>
    props.$uiError
      ? props.theme.fileInput.emptyText.errorLinkColor
      : props.theme.fileInput.emptyText.linkColor};
`;
