import styled from 'styled-components';

export const StyledExplore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12rem 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin-right: 2.5rem;
  position: relative;

  @media (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
`;

export const Hint = styled.p`
  position: absolute;
  top: calc(100% + 1.5rem);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;

export const Title = styled.h2`
  font-size: 3.2rem;
  margin-bottom: 6rem;
  text-align: center;
`;
