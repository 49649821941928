import { useState } from 'react';

import { ToggleHandler } from './types';

export default (initial: boolean): ToggleHandler => {
  const [state, setState] = useState(initial);

  const toggleState = () => {
    setState((s) => !s);
  };

  const setStateToFalse = () => {
    setState(false);
  };

  const setStateToTrue = () => {
    setState(false);
  };

  return [state, toggleState, setStateToFalse, setStateToTrue];
};
