import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { ButtonType } from '@components/Button/types';
import { UIModel } from '@store/user/types';

import {
  StyledExplore,
  ButtonsWrapper,
  ButtonWrapper,
  Hint,
  Title,
} from './styled';
import { mapModelToApiDocumentation, mapModelToRapidApiLink } from './utils';

interface Props {
  model: UIModel;
}

const Explore: FC<Props> = ({ model }) => {
  const { t } = useTranslation();

  return (
    <StyledExplore>
      <Title>{t('components.explore.title')}</Title>
      <ButtonsWrapper>
        <ButtonWrapper>
          <Button
            external
            type={ButtonType.TERTIARY}
            to={mapModelToRapidApiLink[model]}
          >
            {t('components.explore.api')}
          </Button>
          <Hint>{t('components.explore.hint')}</Hint>
        </ButtonWrapper>
        <Button
          external
          type={ButtonType.SECONDARY}
          to={mapModelToApiDocumentation[model]}
        >
          {t('components.explore.documentation')}
        </Button>
      </ButtonsWrapper>
    </StyledExplore>
  );
};

export default Explore;
