import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  Bar,
  Cell,
  LabelList,
} from 'recharts';

import { mapModelToReadableName } from '@components/Header/utils';
import { UIModel } from '@store/user/types';

import {
  BAR_BORDER_RADIUS,
  BAR_CATEGORY_GAP_PERCENTS,
  BAR_CHART_MARGIN_BOTTOM,
  BAR_CHART_MARGIN_LEFT,
  BAR_CHART_MARGIN_RIGHT,
  BAR_CHART_MARGIN_TOP,
  FONT_SIZE_REM,
  X_AXIS_TICK_MARGIN,
  Y_AXIS_TICK_COUNT,
  Y_AXIS_TICK_MARGIN,
} from './defaults';
import Header from './Header';
import { StyledBarChart, Chart } from './styled';
import { BarChartType } from './types';
import {
  mapBarChartTypeToDomain,
  mapBarChartTypeToTickFormatter,
  mapBarChartTypeToTitle,
  mapModelToBarChartTypeDataPoints,
} from './utils';

interface Props {
  type?: BarChartType;
  model: UIModel;
}

const BarChart: FC<Props> = ({ type = BarChartType.PRECISION, model }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dataPoints = mapModelToBarChartTypeDataPoints[model][type];
  return (
    <StyledBarChart>
      <Header
        title={t(mapBarChartTypeToTitle[type])}
        subTitle={t(mapModelToReadableName[model])}
      />
      <Chart>
        <ResponsiveContainer width="100%" height="100%">
          <RechartsBarChart
            data={dataPoints}
            barCategoryGap={`${BAR_CATEGORY_GAP_PERCENTS}%`}
            margin={{
              top: BAR_CHART_MARGIN_TOP,
              right: BAR_CHART_MARGIN_RIGHT,
              bottom: BAR_CHART_MARGIN_BOTTOM,
              left: BAR_CHART_MARGIN_LEFT,
            }}
          >
            <CartesianGrid vertical={false} stroke={theme.barChart.mainColor} />
            <XAxis
              stroke={theme.barChart.mainColor}
              dataKey="label"
              tick={{
                fill: theme.barChart.tickColor,
                fontSize: `${FONT_SIZE_REM}rem`,
              }}
              tickMargin={X_AXIS_TICK_MARGIN}
            />
            <YAxis
              axisLine={false}
              stroke={theme.barChart.mainColor}
              dataKey="value"
              domain={mapBarChartTypeToDomain[type]}
              tickFormatter={mapBarChartTypeToTickFormatter[type]}
              tick={{
                fontSize: `${FONT_SIZE_REM}rem`,
              }}
              tickMargin={Y_AXIS_TICK_MARGIN}
              tickCount={Y_AXIS_TICK_COUNT}
            />
            <Bar
              dataKey="value"
              radius={[BAR_BORDER_RADIUS, BAR_BORDER_RADIUS, 0, 0]}
            >
              <LabelList
                dataKey="value"
                position="top"
                formatter={mapBarChartTypeToTickFormatter[type]}
              />
              {dataPoints.map((dataPoint, index) => {
                const color = dataPoint.highlight
                  ? theme.barChart.highlihtedBarColor
                  : theme.barChart.barColor;
                return <Cell key={index} fill={color} />;
              })}
            </Bar>
          </RechartsBarChart>
        </ResponsiveContainer>
      </Chart>
    </StyledBarChart>
  );
};

export default BarChart;
