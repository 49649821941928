import { useEffect, useRef } from 'react';

const noop = () => {};

const requestTimeout = (
  fn: VoidFunction,
  delay: number,
  registerCancel: (fn: VoidFunction) => VoidFunction
) => {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(noop);
      return;
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

const useCancelableScheduledWork = (): [
  (fn: VoidFunction) => VoidFunction,
  VoidFunction
] => {
  const cancelCallback = useRef(noop);
  const registerCancel = (fn: VoidFunction) => (cancelCallback.current = fn);
  const cancelScheduledWork = () => cancelCallback.current();

  useEffect(() => {
    return cancelScheduledWork;
  }, []);

  return [registerCancel, cancelScheduledWork];
};

interface Props {
  onClick: VoidFunction;
  onDoubleClick: VoidFunction;
  delay?: number;
}

export default ({
  onClick,
  onDoubleClick,
  delay = 200,
}: Props): [VoidFunction, VoidFunction] => {
  const [registerCancel, cancelScheduledRaf] = useCancelableScheduledWork();

  const handleClick = () => {
    cancelScheduledRaf();
    requestTimeout(onClick, delay, registerCancel);
  };

  const handleDoubleClick = () => {
    cancelScheduledRaf();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};
