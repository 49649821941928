import { FC, MouseEventHandler } from 'react';

import { IconType } from '@components/Icon/types';

import { StyledFileText, StyledIcon } from './styled';

interface Props {
  value: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  uiError?: boolean;
}

const FileText: FC<Props> = ({ value, onClick, uiError }) => (
  <StyledFileText onClick={onClick} $uiError={uiError}>
    <StyledIcon type={IconType.UPLOAD} $uiError={uiError} />
    {value}
  </StyledFileText>
);

export default FileText;
