import { ChangeEvent, DragEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextToTableHighlightedData } from '@containers/Processor/types';
import TextToTableHighlighter from '@components/Highlighter/TextToTable';
import NamedEntityRecognitionHighlighter from '@components/Highlighter/NamedEntityRecognition';
import RelationExtractionHighlighter from '@components/Highlighter/RelationExtraction';
import {
  NamedEntityRecognitionData,
  RelationExtractionData,
  UIModel,
} from '@store/user/types';
import { UIError } from '@store/ui/types';

import FileInput from './FileInput';
import LinkViewer from './LinkViewer';
import TextInput from './TextInput';

interface Props {
  value: string[];
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: VoidFunction;
  handleEdit: VoidFunction;
  handleFocus: VoidFunction;
  isEmpty: boolean;
  isPending: boolean;
  selectedRelations: RelationExtractionData[];
  activeRelations: RelationExtractionData[];
  hoveredRelations: RelationExtractionData[];
  handleToggleHoveredRelation: (relation: RelationExtractionData) => void;
  model: UIModel;
  data: NamedEntityRecognitionData[];
  labels: string[];
  highlightedData: TextToTableHighlightedData[];
  isLink: boolean;
  handleRemoveLink: VoidFunction;
  files: File[];
  onFilesAccepted: (files: File[]) => void;
  handleRemoveFile: (file: File) => void;
  isDragActive: boolean;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  onDrop: VoidFunction;
  uiErrors: UIError[];
}

const Content: FC<Props> = ({
  value,
  onChange,
  handleSubmit,
  handleEdit,
  handleFocus,
  isEmpty,
  isPending,
  selectedRelations,
  activeRelations,
  hoveredRelations,
  handleToggleHoveredRelation,
  model,
  data,
  labels,
  highlightedData,
  isLink,
  handleRemoveLink,
  files,
  onFilesAccepted,
  handleRemoveFile,
  isDragActive,
  onDragEnter,
  onDragLeave,
  onDrop,
  uiErrors,
}) => {
  const { t } = useTranslation();

  if (isEmpty) {
    if (isLink) {
      return (
        <LinkViewer value={value.join('')} handleRemove={handleRemoveLink} />
      );
    }
    return (
      <FileInput
        files={files}
        onFilesAccepted={onFilesAccepted}
        handleRemove={handleRemoveFile}
        handleEdit={handleEdit}
        isDragActive={isDragActive}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        uiErrors={uiErrors}
      />
    );
  }
  if (isPending) {
    return (
      <TextInput
        key={model}
        value={value}
        onChange={onChange}
        onBlur={handleSubmit}
        onFocus={handleFocus}
        placeholder={
          model === UIModel.TEXT_TO_TABLE
            ? t('components.textInput.placeholder').toString()
            : undefined
        }
      />
    );
  }
  if (model === UIModel.TEXT_TO_TABLE) {
    return (
      <TextToTableHighlighter
        sentences={value}
        highlightedData={highlightedData}
        handleEdit={handleEdit}
      />
    );
  }
  if (model === UIModel.NAMED_ENTITY_RECOGNITION) {
    return (
      <NamedEntityRecognitionHighlighter
        sentences={value}
        data={data}
        labels={labels}
        handleEdit={handleEdit}
      />
    );
  }
  return (
    <RelationExtractionHighlighter
      sentences={value}
      selectedRelations={selectedRelations}
      activeRelations={activeRelations}
      hoveredRelations={hoveredRelations}
      handleToggleHoveredRelation={handleToggleHoveredRelation}
      handleEdit={handleEdit}
    />
  );
};

export default Content;
