import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const stepperTheme: DefaultTheme['stepper'] = {
  header: {
    textColor: Color.CORNFLOWER_BLUE,
    actionColor: Color.GRAY,
  },
  step: {
    descriptionBorderColor: Color.TUNDORA,
    descriptionModelColor: Color.CORNFLOWER_BLUE,
    descriptionDetailsColor: Color.GRAY,
  },
  progress: {
    activeBackgroundColor: Color.CORNFLOWER_BLUE,
    inactiveBackgroundColor: Color.SILVER_CHALICE,
  },
};
