import { FC, MouseEvent, PropsWithChildren } from 'react';
import { LinkProps } from 'react-router-dom';

import { StyledLink, StyledExternalLink } from './styled';

export type Props = LinkProps & {
  underline?: boolean;
  external?: boolean;
};

const Link: FC<PropsWithChildren<Props>> = ({
  underline,
  external,
  children,
  onClick,
  to,
  ...linkProps
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return external ? (
    <StyledExternalLink
      {...linkProps}
      $underline={underline}
      href={to.toString()}
      target="__blank"
    >
      {children}
    </StyledExternalLink>
  ) : (
    <StyledLink
      {...linkProps}
      $underline={underline}
      to={to}
      onClick={handleClick}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
