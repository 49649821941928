import { FC } from 'react';

import { StyledProgress, ProgressBarWrapper, ProgressBar } from './styled';

interface Props {
  activeIndex: number;
  count: number;
  handleClick: (index: number) => void;
}

const Progress: FC<Props> = ({ activeIndex, count, handleClick }) => (
  <StyledProgress>
    {Array.from(Array(count).keys()).map((_, index) => (
      <ProgressBarWrapper
        key={index}
        onClick={() => {
          handleClick(index + 1);
        }}
      >
        <ProgressBar $active={activeIndex === index} />
      </ProgressBarWrapper>
    ))}
  </StyledProgress>
);

export default Progress;
