import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const customRelationsTheme: DefaultTheme['customRelations'] = {
  highlighterBackgroundColor: Color.MINE_SHAFT_MEDIUM,
  highlighterBorderColor: Color.TUNDORA,
  tableMaxHeight: 60,
  tableBackgroundColor: Color.MINE_SHAFT_MEDIUM,
  tableBorderColor: Color.TUNDORA,
  tableHeaderBackgroundColor: Color.MINE_SHAFT_MEDIUM,
  tableStaticTextColor: Color.SILVER_CHALICE,
};
