import axios from 'axios';

import { AuthType } from '@containers/Model/types';
import { logoutUser } from '@store/auth/actions';
import store from '@store/index';

import {
  BASE_URL,
  DEFAULT_TIMEOUT,
  NAMED_ENTITY_RECOGNITION_RAPID_API_URL,
  NAMED_ENTITY_RECOGNITION_RAPID_API_KEY,
  TEXT_CLASSIFICATION_RAPID_API_URL,
  TEXT_CLASSIFICATION_RAPID_API_KEY,
  TEXT_TO_TABLE_RAPID_API_KEY,
  TEXT_TO_TABLE_RAPID_API_URL,
  RELATION_EXTRACTION_RAPID_API_URL,
  RELATION_EXTRACTION_RAPID_API_KEY,
} from './defaults';

export const relationExtractionRapidApiClient = axios.create({
  baseURL: RELATION_EXTRACTION_RAPID_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'X-RapidAPI-Key': RELATION_EXTRACTION_RAPID_API_KEY,
  },
});

export const namedEntityRecognitionRapidApiClient = axios.create({
  baseURL: NAMED_ENTITY_RECOGNITION_RAPID_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'X-RapidAPI-Key': NAMED_ENTITY_RECOGNITION_RAPID_API_KEY,
  },
});

export const textClassificationRapidApiClient = axios.create({
  baseURL: TEXT_CLASSIFICATION_RAPID_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'X-RapidAPI-Key': TEXT_CLASSIFICATION_RAPID_API_KEY,
  },
});

export const textToTableRapidApiClient = axios.create({
  baseURL: TEXT_TO_TABLE_RAPID_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'X-RapidAPI-Key': TEXT_TO_TABLE_RAPID_API_KEY,
  },
});

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: DEFAULT_TIMEOUT,
  withCredentials: true,
});

const clients = [httpClient];
clients.forEach((client) => {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status;
      if (status === 401) {
        store.dispatch(logoutUser());
        window.location.href =
          window.location.origin +
          window.location.pathname +
          `?authType=${AuthType.LOGIN}`;
      }
      return Promise.reject(error);
    }
  );
});

export default httpClient;
