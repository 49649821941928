import styled from 'styled-components';

import { FONT_FAMILY } from '@theme/defaults';

export const StyledTextInput = styled.div`
  display: flex;
  flex: auto;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 2rem 5rem;
  margin: 2rem 2.5rem 2rem 0;
  color: inherit;
  line-height: 2.8;
  height: ${(props) => props.theme.highlighter.contentHeight}rem;
  background-color: transparent;
  font-size: 2.1rem;
  font-family: ${FONT_FAMILY};
  resize: none;
  outline: none;
  border: none;
`;
