import { DataPoint } from './types';

export const textClassificationPrecisionDataPoints: DataPoint[] = [
  {
    label: 'Our Model',
    value: 0.87,
    highlight: true,
  },
  {
    label: 'Competitor A',
    value: 0.75,
  },
  {
    label: 'Competitor B',
    value: 0.65,
  },
  {
    label: 'Competitor C',
    value: 0.65,
  },
  {
    label: 'Competitor D',
    value: 0.5,
  },
];

export const textClassificationCostDataPoints: DataPoint[] = [
  {
    label: 'Our Model',
    value: 0.00034,
    highlight: true,
  },
  {
    label: 'Competitor A',
    value: 0.00072,
  },
  {
    label: 'Competitor B',
    value: 0.0016,
  },
  {
    label: 'Competitor C',
    value: 0.00228,
  },
  {
    label: 'Competitor D',
    value: 0.035,
  },
];

export const namedEntityRecognitionPrecisionDataPoints: DataPoint[] = [
  {
    label: 'Our Model',
    value: 0.89,
    highlight: true,
  },
  {
    label: 'Competitor A',
    value: 0.78,
  },
  {
    label: 'Competitor B',
    value: 0.69,
  },
  {
    label: 'Competitor C',
    value: 0.64,
  },
  {
    label: 'Competitor D',
    value: 0.52,
  },
];

export const namedEntityRecognitionCostDataPoints: DataPoint[] = [
  {
    label: 'Our Model',
    value: 0.00064,
    highlight: true,
  },
  {
    label: 'Competitor A',
    value: 0.00072,
  },
  {
    label: 'Competitor B',
    value: 0.0016,
  },
  {
    label: 'Competitor C',
    value: 0.00228,
  },
  {
    label: 'Competitor D',
    value: 0.035,
  },
];
