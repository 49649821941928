import { Dispatch } from 'redux';

import * as api from '@api/functions';
import { getErrorMessage } from '@store/utils';

import {
  CLEAR_MODELS,
  NAMED_ENTITY_RECOGNITION_PROCESS,
  NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED,
  NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED,
  RELATION_EXTRACTION_PROCESS,
  RELATION_EXTRACTION_PROCESS_FULFILLED,
  RELATION_EXTRACTION_PROCESS_REJECTED,
  TEXT_CLASSIFICATION_PROCESS,
  TEXT_CLASSIFICATION_PROCESS_FULFILLED,
  TEXT_CLASSIFICATION_PROCESS_REJECTED,
  TEXT_TO_TABLE_PROCESS,
  TEXT_TO_TABLE_PROCESS_FULFILLED,
  TEXT_TO_TABLE_PROCESS_REJECTED,
} from './actionTypes';
import {
  NamedEntityRecognitionProcess,
  NamedEntityRecognitionProcessFulfilled,
  NamedEntityRecognitionProcessRejected,
  TextClassificationProcess,
  TextClassificationProcessFulfilled,
  TextClassificationProcessRejected,
  TextToTableData,
  TextToTableProcess,
  TextToTableProcessFulfilled,
  TextToTableProcessRejected,
  ClearModels,
  RelationExtractionProcess,
  RelationExtractionProcessFulfilled,
  RelationExtractionProcessRejected,
} from './types';
import { transformRawRelationExtractionData } from './utils';

export const processNamedEntityRecognition = (
  index: number,
  title: string,
  text: string,
  labels: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<NamedEntityRecognitionProcess>({
        type: NAMED_ENTITY_RECOGNITION_PROCESS,
        payload: {
          index,
          name: title,
        },
      });
      const {
        data: { entities },
      } = await api.processNamedEntityRecognition(text, labels);
      dispatch<NamedEntityRecognitionProcessFulfilled>({
        type: NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED,
        payload: {
          index,
          name: title,
          data: entities,
        },
      });
    } catch (error) {
      dispatch<NamedEntityRecognitionProcessRejected>({
        type: NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED,
        payload: {
          index,
          name: title,
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const processTextClassification = (
  index: number,
  title: string,
  text: string,
  labels: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<TextClassificationProcess>({
        type: TEXT_CLASSIFICATION_PROCESS,
        payload: {
          index,
          name: title,
        },
      });
      const {
        data: { outputs },
      } = await api.processTextClassification(text, labels);
      dispatch<TextClassificationProcessFulfilled>({
        type: TEXT_CLASSIFICATION_PROCESS_FULFILLED,
        payload: {
          index,
          name: title,
          data: outputs,
        },
      });
    } catch (error) {
      dispatch<TextClassificationProcessRejected>({
        type: TEXT_CLASSIFICATION_PROCESS_REJECTED,
        payload: {
          index,
          name: title,
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const processTextToTable = (
  index: number,
  title: string,
  text: string,
  columns: string[],
  link: boolean,
  files: File[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<TextToTableProcess>({
        type: TEXT_TO_TABLE_PROCESS,
        payload: {
          index,
          name: title,
        },
      });
      let data: TextToTableData;
      if (files.length > 0) {
        const {
          data: { pages },
        } = await api.preProcessTextToTableFile(files[0], columns);
        const processPromises = [];
        const pageNumbers = Object.keys(pages) as unknown as number[];
        for (const pageNumber of pageNumbers) {
          const pageValue = pages[pageNumber];
          for (const { text, columns } of pageValue.texts) {
            processPromises.push(api.processTextToTable(text, columns));
          }
        }
        const finalTable = await (
          await Promise.all(processPromises)
        ).reduce((acc, processPromise) => {
          const { table } = processPromise.data;
          Object.keys(table).forEach((columnName) => {
            const columnValue = table[columnName];
            if (!acc[columnName]) {
              acc[columnName] = columnValue;
            } else {
              acc[columnName] = [...acc[columnName], ...columnValue];
            }
          });
          return acc;
        }, {} as TextToTableData);
        data = finalTable;
      } else {
        const preProcessResponse = link
          ? await api.preProcessTextToTableLink(text, columns)
          : await api.preProcessTextToTable(text, columns);
        const processPromises = [];
        for (const { text, columns } of preProcessResponse.data.texts) {
          processPromises.push(api.processTextToTable(text, columns));
        }
        const finalTable = await (
          await Promise.all(processPromises)
        ).reduce((acc, processPromise) => {
          const { table } = processPromise.data;
          Object.keys(table).forEach((columnName) => {
            const columnValue = table[columnName];
            if (!acc[columnName]) {
              acc[columnName] = columnValue;
            } else {
              acc[columnName] = [...acc[columnName], ...columnValue];
            }
          });
          return acc;
        }, {} as TextToTableData);
        data = finalTable;
      }
      dispatch<TextToTableProcessFulfilled>({
        type: TEXT_TO_TABLE_PROCESS_FULFILLED,
        payload: {
          index,
          name: title,
          data,
        },
      });
    } catch (error) {
      dispatch<TextToTableProcessRejected>({
        type: TEXT_TO_TABLE_PROCESS_REJECTED,
        payload: {
          index,
          name: title,
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const processRelationExtraction = (
  index: number,
  title: string,
  text: string,
  relations: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<RelationExtractionProcess>({
        type: RELATION_EXTRACTION_PROCESS,
        payload: {
          index,
          name: title,
        },
      });
      const {
        data: { relations: rawData },
      } = await api.processRelationExtraction(text, relations);
      dispatch<RelationExtractionProcessFulfilled>({
        type: RELATION_EXTRACTION_PROCESS_FULFILLED,
        payload: {
          index,
          name: title,
          data: transformRawRelationExtractionData(rawData),
        },
      });
    } catch (error) {
      dispatch<RelationExtractionProcessRejected>({
        type: RELATION_EXTRACTION_PROCESS_REJECTED,
        payload: {
          index,
          name: title,
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const clearModels = () => {
  return (dispatch: Dispatch) => {
    dispatch<ClearModels>({
      type: CLEAR_MODELS,
    });
  };
};
