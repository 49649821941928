import { clamp } from 'lodash';

import { Step } from '@components/Stepper/Step/types';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getTutorialSelector } from '@store/ui/selectors';

import { StepperHandler } from './types';
import { setTutorialStepIndex } from '@store/ui/actions';

export default (steps: Step[]): StepperHandler => {
  const dispatch = useAppDispatch();

  const { stepIndex } = useAppSelector(getTutorialSelector);

  const clampStepIndex = (index: number) => clamp(index, 0, steps.length - 1);

  const handleNextStep = () => {
    dispatch(setTutorialStepIndex(clampStepIndex(stepIndex + 1)));
  };

  const handlePreviousStep = () => {
    dispatch(setTutorialStepIndex(clampStepIndex(stepIndex - 1)));
  };

  const handleStepClick = (index: number) => {
    dispatch(setTutorialStepIndex(clampStepIndex(index)));
  };

  return {
    stepIndex,
    step: steps[stepIndex],
    isFirst: stepIndex === 0,
    isLast: stepIndex === steps.length - 1,
    handleNextStep,
    handlePreviousStep,
    handleStepClick,
  };
};
