import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@components/IconButton';
import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon/Icon';
import { IconType } from '@components/Icon/types';
import useOnClickOutside from '@hooks/useOnClickOutside/useOnClickOutside';
import useToggle from '@hooks/useToggle/useToggle';

import {
  StyledUserMenu,
  MenuDropdown,
  UserInfo,
  UserCredentials,
  UserName,
  UserEmail,
  UserActions,
  UserAction,
} from './styled';

interface Props {
  nickname: string;
  email: string;
  handleLogout: VoidFunction;
}

const UserMenu: FC<Props> = ({ nickname, email, handleLogout }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [opened, toggleOpened, handleClose] = useToggle(false);

  useOnClickOutside(ref, handleClose);

  const { t } = useTranslation();

  return (
    <StyledUserMenu ref={ref}>
      <IconButton type={IconButtonType.SECONDARY} onClick={toggleOpened}>
        <Icon type={IconType.USER} />
      </IconButton>
      {opened && (
        <MenuDropdown>
          <UserInfo>
            <IconButton type={IconButtonType.PLAIN}>
              <Icon type={IconType.USER} />
            </IconButton>
            <UserCredentials>
              <UserName>{nickname}</UserName>
              <UserEmail>{email}</UserEmail>
            </UserCredentials>
          </UserInfo>
          <UserActions>
            <UserAction to="#" onClick={handleLogout}>
              {t('components.userMenu.actions.signOut')}
            </UserAction>
          </UserActions>
        </MenuDropdown>
      )}
    </StyledUserMenu>
  );
};

export default UserMenu;
