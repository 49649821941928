import styled, { css } from 'styled-components';

import IconButton from '@components/IconButton';
import Icon from '@components/Icon/Icon';
import Link from '@components/Link';

export const StyledTabs = styled.div``;

export const TabsContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  overflow-x: auto;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.tabs.titleColor};
  font-size: 2.4rem;
  margin-right: 4rem;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const Tab = styled(Link)<{ $active: boolean }>`
  background-color: ${(props) => props.theme.tabs.tab.backgroundColor};
  color: ${(props) => props.theme.tabs.tab.color};
  border: 1px solid ${(props) => props.theme.tabs.tab.borderColor};
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 8rem;
    position: relative;

    &:after {
      position: absolute;
      right: calc(-4rem - 4px);
      top: 50%;
      transform: translateY(-50%);
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: ${(props) =>
        props.theme.tabs.tab.separatorBackgroundColor};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.tabs.tab.active.backgroundColor};
    color: ${(props) => props.theme.tabs.tab.active.color};
    border: 1px solid ${(props) => props.theme.tabs.tab.active.borderColor};
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) =>
        props.theme.tabs.tab.active.backgroundColor};
      color: ${(props) => props.theme.tabs.tab.active.color};
      border: 1px solid ${(props) => props.theme.tabs.tab.active.borderColor};
    `};
`;

export const StyledIcon = styled(Icon)`
  stroke: ${(props) => props.theme.tabs.iconColor};
`;

export const StyledIconButton = styled(IconButton)`
  border-radius: 50%;
  background-color: ${(props) => props.theme.tabs.iconBackgroundColor};

  &:hover {
    background-color: ${(props) => props.theme.tabs.iconHoverBackgroundColor};

    ${StyledIcon} {
      stroke: ${(props) => props.theme.tabs.iconHoverColor};
    }
  }
`;

export const TutorialWrapper = styled.div<{ $highlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 2.5rem;

  ${(props) =>
    props.$highlight &&
    css`
      border: 1px dashed ${(props) => props.theme.tabs.iconHighlightBorderColor};
      background-color: ${(props) =>
        props.theme.tabs.iconHighlightBackgroundColor};
    `};
`;
