import Layout from '@components/Layout';
import ResetPasswordContainer from '@containers/ResetPassword';

import { StyledResetPassword } from './styled';

const ResetPassword = () => (
  <StyledResetPassword>
    <Layout>{() => <ResetPasswordContainer />}</Layout>
  </StyledResetPassword>
);

export default ResetPassword;
