import { MultiCreatableValue } from '@components/Creatable/types';
import { TextClassificationData } from '@store/user/types';

import { DEFAULT_SCORE } from './defaults';
import { SortedLabel } from './types';

export const convertScore = (score: number) =>
  Math.round(parseFloat(score.toString()) * 100);

export const sortLabelsByScore = (
  labels: MultiCreatableValue,
  data: TextClassificationData
): SortedLabel[] =>
  labels
    .map((label) => ({
      ...label,
      score: data[label.label]
        ? convertScore(data[label.label])
        : DEFAULT_SCORE,
    }))
    .sort((a, b) => b.score - a.score);
