import styled, { css } from 'styled-components';

export const StyledProcessor = styled.div``;

export const InteractiveSpace = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProcessorComponentWrapper = styled.div<{
  $renderRelations: boolean;
  $renderClasses: boolean;
  $renderTable: boolean;
}>`
  flex: 1;
  max-width: 100%;

  ${(props) =>
    props.$renderRelations &&
    css`
      max-width: calc(67.5% - 1.25rem);
      margin-right: 1.25rem;

      @media (max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
      }
    `};

  ${(props) =>
    props.$renderClasses &&
    css`
      max-width: calc(67.5% - 1.25rem);
      margin-right: 1.25rem;

      @media (max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
      }
    `};

  ${(props) =>
    props.$renderTable &&
    css`
      max-width: calc(50% - 1.25rem);
      margin-right: 1.25rem;

      @media (max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
      }
    `};
`;

export const RelationsComponentWrapper = styled.div`
  flex: 1;
  max-width: calc(32.5% - 1.25rem);
  margin-left: 1.25rem;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 2rem;x
    margin-top: 2.5rem;
  }
`;

export const ClassesComponentWrapper = styled.div`
  flex: 1;
  max-width: calc(32.5% - 1.25rem);
  margin-left: 1.25rem;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }
`;

export const TableComponentWrapper = styled.div`
  flex: 1;
  max-width: calc(50% - 1.25rem);
  margin-left: 1.25rem;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 2.5rem;
`;
