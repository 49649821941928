import { FC } from 'react';

import HeaderComponent from '@components/Header';
import { logoutUser } from '@store/auth/actions';
import { getCurrentUserSelector } from '@store/auth/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { UIModel } from '@store/user/types';

import { StyledHeader } from './styled';

interface Props {
  model: UIModel;
}

const Header: FC<Props> = ({ model }) => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getCurrentUserSelector);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <StyledHeader>
      <HeaderComponent
        model={model}
        user={currentUser}
        handleLogout={handleLogout}
      />
    </StyledHeader>
  );
};

export default Header;
