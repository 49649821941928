import { FC } from 'react';
import { DefaultTFuncReturn } from 'i18next';

import { StyledLegend, Section } from './styled';

interface Props {
  leftSection: DefaultTFuncReturn;
  rightSection?: DefaultTFuncReturn;
}

const Legend: FC<Props> = ({ leftSection, rightSection }) => (
  <StyledLegend>
    <Section>{leftSection}</Section>
    {rightSection && <Section>{rightSection}</Section>}
  </StyledLegend>
);

export default Legend;
