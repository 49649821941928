import {
  NAMED_ENTITY_RECOGNITION_HOME_ROUTE,
  RELATION_EXTRACTION_HOME_ROUTE,
  TEXT_CLASSIFICATION_HOME_ROUTE,
  TEXT_TO_TABLE_HOME_ROUTE,
} from '@routes/list';
import { UIModel } from '@store/user/types';

export const mapModelToHref: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]: RELATION_EXTRACTION_HOME_ROUTE,
  [UIModel.NAMED_ENTITY_RECOGNITION]: NAMED_ENTITY_RECOGNITION_HOME_ROUTE,
  [UIModel.TEXT_CLASSIFICATION]: TEXT_CLASSIFICATION_HOME_ROUTE,
  [UIModel.TEXT_TO_TABLE]: TEXT_TO_TABLE_HOME_ROUTE,
};

export const mapHrefToModel: Record<string, UIModel> = {
  '/': UIModel.TEXT_TO_TABLE,
  [`/${TEXT_TO_TABLE_HOME_ROUTE}`]: UIModel.TEXT_TO_TABLE,
  [`/${TEXT_CLASSIFICATION_HOME_ROUTE}`]: UIModel.TEXT_CLASSIFICATION,
  [`/${NAMED_ENTITY_RECOGNITION_HOME_ROUTE}`]: UIModel.NAMED_ENTITY_RECOGNITION,
  [`/${RELATION_EXTRACTION_HOME_ROUTE}`]: UIModel.RELATION_EXTRACTION,
};
