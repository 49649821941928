import styled, { css } from 'styled-components';

import { FONT_FAMILY } from '@theme/defaults';

export const StyledInput = styled.div`
  position: relative;
`;

export const InputComponent = styled.input<{ $addRightPadding: boolean }>`
  width: 100%;
  height: ${(props) => props.theme.input.height}rem;
  border-radius: 1.5rem
  border: 1px solid ${(props) => props.theme.input.borderColor};
  padding: 0.5rem 1.5rem;
  font-family: ${FONT_FAMILY};
  outline: none;
  font-size: inherit;

  ${(props) =>
    props.$addRightPadding &&
    css`
      padding-right: 4.5rem;
    `};
`;
