import '@theme/fonts/index.css';
import GlobalStyle from '@theme/GlobalStyle';

import Routes from './routes';

const App = () => (
  <>
    <GlobalStyle />
    <Routes />
  </>
);

export default App;
