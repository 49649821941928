import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RelationExtractionData } from '@store/user/types';

import Item from './Item';
import { StyledContent, NoData } from './styled';

interface Props {
  relations: RelationExtractionData[];
  selectedRelations: RelationExtractionData[];
  activeRelations: RelationExtractionData[];
  handleToggleSelectedRelation: (relation: RelationExtractionData) => void;
  handleToggleActiveRelation: (relation: RelationExtractionData) => void;
  handleToggleHoveredRelation: (relation: RelationExtractionData) => void;
}

const Content: FC<Props> = ({
  relations,
  selectedRelations,
  activeRelations,
  handleToggleSelectedRelation,
  handleToggleActiveRelation,
  handleToggleHoveredRelation,
}) => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      {relations.length === 0 && (
        <NoData>{t('components.relations.noData')}</NoData>
      )}
      {relations.map((relation, index) => {
        const isActive = activeRelations.find(
          (activeRelation) => activeRelation.id === relation.id
        );
        const isSelected = selectedRelations.find(
          (selectedRelation) => selectedRelation.id === relation.id
        );
        return (
          <Item
            key={index}
            relation={relation}
            isActive={!!isActive}
            isSelected={!!isSelected}
            handleToggleSelectedRelation={() => {
              handleToggleSelectedRelation(relation);
            }}
            handleToggleActiveRelation={() => {
              handleToggleActiveRelation(relation);
            }}
            handleToggleHoveredRelation={() => {
              handleToggleHoveredRelation(relation);
            }}
          />
        );
      })}
    </StyledContent>
  );
};

export default Content;
