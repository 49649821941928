import {
  OPEN_TUTORIAL,
  CLOSE_TUTORIAL,
  ADD_UI_ERROR,
  REMOVE_UI_ERROR,
  CLEAR_UI_ERRORS,
  SET_TUTORIAL_STEP_INDEX,
} from './actionTypes';

export enum UIError {
  GENERAL_TEXT_EMPTY = 'generalTextEmpty',
  TEXT_CLASSIFICATION_LABELS_EMPTY = 'textClassificationLabelsEmpty',
  TEXT_TO_TABLE_COLUMNS_EMPTY = 'textToTableColumnsEmpty',
  NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY = 'namedEntityRecognitionEntitiesEmpty',
  FILE_INPUT_ERROR = 'fileInputError',
  INVALID_URL_ERROR = 'invalidUrlError',
}

interface TutorialState {
  opened: boolean;
  stepIndex: number;
}

export interface UIState {
  tutorial: TutorialState;
  errors: UIError[];
}

export type OpenTutorial = {
  type: typeof OPEN_TUTORIAL;
  payload: number;
};

export type SetTutorialStepIndex = {
  type: typeof SET_TUTORIAL_STEP_INDEX;
  payload: number;
};

export type CloseTutorial = {
  type: typeof CLOSE_TUTORIAL;
};

export type AddUIError = {
  type: typeof ADD_UI_ERROR;
  payload: UIError;
};

export type RemoveUIError = {
  type: typeof REMOVE_UI_ERROR;
  payload: UIError;
};

export type ClearUIErrors = {
  type: typeof CLEAR_UI_ERRORS;
};

export type UIActions =
  | OpenTutorial
  | SetTutorialStepIndex
  | CloseTutorial
  | AddUIError
  | RemoveUIError
  | ClearUIErrors;
