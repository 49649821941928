import { FC, MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';

import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import IconButton from '@components/IconButton';
import { IconButtonType } from '@components/IconButton/types';

import { StyledHeader, NameWrapper, Name } from './styled';

interface Props {
  value: File;
  handleRemove: MouseEventHandler<HTMLDivElement>;
}

const Header: FC<Props> = ({ value, handleRemove }) => {
  const theme = useTheme();

  return (
    <StyledHeader>
      <NameWrapper>
        <IconButton type={IconButtonType.SECONDARY}>
          <Icon type={IconType.FILE} />
        </IconButton>
        <Name>{value.name}</Name>
      </NameWrapper>
      <IconButton type={IconButtonType.PLAIN} onClick={handleRemove}>
        <Icon
          type={IconType.CROSS}
          stroke={theme.fileInput.file.crossIconColor}
        />
      </IconButton>
    </StyledHeader>
  );
};

export default Header;
