import { forwardRef, InputHTMLAttributes, ReactElement } from 'react';
import { DefaultTFuncReturn } from 'i18next';

import { Props as IconButtonProps } from '@components/IconButton/IconButton';

import { StyledInput, InputComponent } from './styled';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  placeholder: DefaultTFuncReturn;
  rightIcon?: ReactElement<IconButtonProps>;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, rightIcon, ...inputProps }, ref) => (
    <StyledInput>
      <InputComponent
        {...inputProps}
        ref={ref}
        placeholder={placeholder}
        $addRightPadding={!!rightIcon}
      />
      {rightIcon}
    </StyledInput>
  )
);

export default Input;
