export const enableAnalytics = () => {
  const gtag = (window as any).gtag;
  if (!gtag) {
    return;
  }
  gtag('consent', 'update', {
    analytics_storage: 'granted',
  });
};

export const sendDeclineCookiesEvent = () => {
  const gtag = (window as any).gtag;
  if (!gtag) {
    return;
  }
  gtag('event', 'click', {
    event_category: 'cookies',
    event_label: 'decline',
  });
};
