import { UIModel } from '@store/user/types';

import {
  NAMED_ENTITY_RECOGNITION_TUTORIAL_KEY,
  RELATION_EXTRACTION_TUTORIAL_KEY,
  TEXT_CLASSIFICATION_TUTORIAL_KEY,
  TEXT_TO_TABLE_TUTORIAL_KEY,
} from './defaults';

export const mapModelToTutorialKey: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]: RELATION_EXTRACTION_TUTORIAL_KEY,
  [UIModel.NAMED_ENTITY_RECOGNITION]: NAMED_ENTITY_RECOGNITION_TUTORIAL_KEY,
  [UIModel.TEXT_CLASSIFICATION]: TEXT_CLASSIFICATION_TUTORIAL_KEY,
  [UIModel.TEXT_TO_TABLE]: TEXT_TO_TABLE_TUTORIAL_KEY,
};

export const isTutorialFinished = (model: UIModel) =>
  localStorage.getItem(mapModelToTutorialKey[model]) === 'true';

export const finishTutorial = (model: UIModel) => {
  localStorage.setItem(mapModelToTutorialKey[model], 'true');
};

export const clearTutorial = () => {
  localStorage.removeItem(RELATION_EXTRACTION_TUTORIAL_KEY);
  localStorage.removeItem(NAMED_ENTITY_RECOGNITION_TUTORIAL_KEY);
  localStorage.removeItem(TEXT_CLASSIFICATION_TUTORIAL_KEY);
  localStorage.removeItem(TEXT_TO_TABLE_TUTORIAL_KEY);
};
