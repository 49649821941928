import { FC, useState } from 'react';

import { SampleText } from '@containers/Processor/types';
import Modal from '@components/Modal';
import { Props as ModalProps } from '@components/Modal/Modal';
import RelationExtraction from '@components/Highlighter/RelationExtraction';
import { SingleSelectValue } from '@components/Select/types';

import Content from './Content';
import Header from './Header';
import {
  StyledCustomRelations,
  HighlighterWrapper,
  TableWrapper,
} from './styled';
import { CustomRelation } from './types';

interface Props extends ModalProps {
  selectedText: SampleText;
}

const CustomRelations: FC<Props> = ({ selectedText, ...modalProps }) => {
  const [relations] = useState<CustomRelation[]>([]);

  const handleRelationChange = (value: SingleSelectValue) => {
    console.log('handleRelationChange');
    console.log(value);
  };

  const handleDescriptionChange = (value: SingleSelectValue) => {
    console.log('handleDescriptionChange');
    console.log(value);
  };

  const handleAddClick = () => {
    console.log('handleAddClick');
  };

  return (
    <Modal {...modalProps}>
      <StyledCustomRelations>
        <HighlighterWrapper>
          <RelationExtraction sentences={selectedText.content} />
        </HighlighterWrapper>
        <TableWrapper>
          <Header
            handleRelationChange={handleRelationChange}
            handleDescriptionChange={handleDescriptionChange}
            handleAddClick={handleAddClick}
          />
          <Content relations={relations} />
        </TableWrapper>
      </StyledCustomRelations>
    </Modal>
  );
};

export default CustomRelations;
