import styled from 'styled-components';

import Button from '@components/Button';

export const StyledHeader = styled.div`
  padding: 0 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.relations.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.theme.relations.headerHeight}rem;
`;

export const Info = styled.div``;

export const Actions = styled.div``;

export const Title = styled.p`
  font-size: 2rem;
`;

export const Count = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.relations.countColor};
  margin-top: 0.5rem;
`;

export const StyledButton = styled(Button)`
  padding: 1rem 1.5rem;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p``;

export const Counter = styled.div`
  font-size: 1.4rem;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.relations.counterBorderColor};
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
`;
