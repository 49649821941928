import { IconType } from '@components/Icon/types';
import {
  NotificationReason,
  NotificationType,
} from '@store/notification/types';

export const mapNotificationTypeToIconType: Record<NotificationType, IconType> =
  {
    [NotificationType.INFO]: IconType.INFO_CIRCLE,
    [NotificationType.WARNING]: IconType.INFO_CIRCLE,
    [NotificationType.ERROR]: IconType.INFO_CIRCLE,
    [NotificationType.SUCCESS]: IconType.INFO_CIRCLE,
  };

export const mapNotificationReasonToMessageId: Record<
  NotificationReason,
  string
> = {
  [NotificationReason.GENERAL_TEXT_EMPTY]:
    'notifications.error.generalTextEmpty',
  [NotificationReason.GENERAL_TEXT_LIMIT_EXCEEDED]:
    'notifications.info.generalTextLimitExceeded',
  [NotificationReason.TEXT_CLASSIFICATION_LABELS_EMPTY]:
    'notifications.error.textClassificationLabelsEmpty',
  [NotificationReason.TEXT_TO_TABLE_COLUMNS_EMPTY]:
    'notifications.error.textToTableColumnsEmpty',
  [NotificationReason.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY]:
    'notifications.error.namedEntityRecognitionEntitiesEmpty',
  [NotificationReason.TEXT_TO_TABLE_COLUMN_NAME_MAX_WIDTH_EXCEEDED]:
    'notifications.error.textToTableColumnNameMaxWidthExceeded',
  [NotificationReason.FILE_INVALID_TYPE]:
    'notifications.error.fileInput.file-invalid-type',
  [NotificationReason.FILE_TOO_LARGE]:
    'notifications.error.fileInput.file-too-large',
  [NotificationReason.TOO_MANY_FILES]:
    'notifications.error.fileInput.too-many-files',
  [NotificationReason.UNKNOWN]: 'notifications.error.fileInput.unknown',
  [NotificationReason.INVALID_URL]: 'notifications.error.invalidUrl',
};
