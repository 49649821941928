import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '@containers/Header';
import Notifications from '@containers/Notifications';
import Cookies from '@components/Cookies';
import Footer from '@components/Footer';
import { mapHrefToModel } from '@components/Tabs/utils';
import VerticalLines from '@components/VerticalLines';
import { UIModel } from '@store/user/types';

import { StyledLayout, Content } from './styled';

interface Props {
  children: (model: UIModel) => ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();

  const model = mapHrefToModel[location.pathname];
  return (
    <StyledLayout>
      <Notifications />
      <Header model={model} />
      <Content>{children(model)}</Content>
      <Cookies />
      <Footer />
      <VerticalLines />
    </StyledLayout>
  );
};

export default Layout;
