import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomRelation } from '@components/CustomRelations/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import { IconButtonType } from '@components/IconButton/types';
import TextCutter from '@components/TextCutter';

import { CUSTOM_RELATION_DESCRIPTIOM_MAX_WORDS } from './defaults';
import {
  StyledContent,
  TableHeader,
  TableColumn,
  StyledIconButton,
  TableRow,
  NoData,
} from './styled';

interface Props {
  relations: CustomRelation[];
}

const Content: FC<Props> = ({ relations }) => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      <TableHeader>
        <TableColumn $xs />
        <TableColumn $m>
          {t('components.customRelations.columns.relation.title')}
        </TableColumn>
        <TableColumn $l>
          {t('components.customRelations.columns.description.title')}
        </TableColumn>
        <TableColumn $s $center>
          {t('components.customRelations.columns.status.title')}
        </TableColumn>
        <TableColumn $xs />
      </TableHeader>
      {relations.length > 0 ? (
        relations.map((relation, index) => (
          <TableRow key={index}>
            <TableColumn $xs $center>
              <StyledIconButton type={IconButtonType.PLAIN}>
                <Icon type={IconType.EYE} />
              </StyledIconButton>
            </TableColumn>
            <TableColumn $m>{relation.relation}</TableColumn>
            <TableColumn $l>
              <TextCutter
                value={relation.description}
                maxWords={CUSTOM_RELATION_DESCRIPTIOM_MAX_WORDS}
              />
            </TableColumn>
            <TableColumn $s $center>
              {t('components.customRelations.columns.status.see')}
            </TableColumn>
            <TableColumn $xs $center>
              <StyledIconButton type={IconButtonType.PLAIN}>
                <Icon type={IconType.MORE_HORIZONTAL} />
              </StyledIconButton>
            </TableColumn>
          </TableRow>
        ))
      ) : (
        <NoData> {t('components.customRelations.noData')}</NoData>
      )}
    </StyledContent>
  );
};

export default Content;
