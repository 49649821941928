import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const linkTheme: DefaultTheme['link'] = {
  color: Color.WHITE,
  hover: {
    color: Color.WHITE,
  },
  active: {
    color: Color.WHITE,
  },
};
