import { FC } from 'react';

import { Line } from './styled';

interface Props {
  count?: number;
}

const VerticalLines: FC<Props> = ({ count = 5 }) => (
  <>
    {Array.from(Array(count).keys()).map((_, index) => (
      <Line key={index} $position={index} />
    ))}
  </>
);

export default VerticalLines;
