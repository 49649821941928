import styled, { keyframes } from 'styled-components';

const slideInLeft = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 1rem;
  }
`;

export const StyledCookies = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9999;
  background-color: ${(props) => props.theme.cookie.backgroundColor};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.cookie.borderColor};
  padding: 2rem 0 2rem 2rem;
  width: 50rem;
  animation-duration: 1s;
  animation-name: ${slideInLeft};
  animation-iteration-count: 1;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 2.4rem;
  white-space: nowrap;
  margin-bottom: 2rem;

  &:after {
    display: inline-block;
    content: '';
    width: 100%;
    height: 5px;
    background-color: ${(props) => props.theme.cookie.lineColor};
  }
`;

export const Separator = styled.div`
  height: 1.4rem;
  width: 7.5px;
  background-color: ${(props) => props.theme.cookie.lineColor};
  margin-left: 4rem;
`;

export const Details = styled.div`
  padding-right: 2rem;
  margin-bottom: 3rem;
`;

export const Teaser = styled.p`
  line-height: 3rem;
`;

export const More = styled.div`
  margin-top: 2rem;
`;

export const MoreTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 1rem;
`;

export const MoreContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export const MoreContentText = styled.p`
  padding-left: 2rem;
  border-left: 1px solid ${(props) => props.theme.cookie.moreBorderColor};
  margin-top: 2rem;
  line-height: 3rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
