import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes';
import { NotificationActions, NotificationState } from './types';

const initialState: NotificationState = {
  notifications: [],
};

const reducer = (
  state = initialState,
  action: NotificationActions
): NotificationState => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.find(
          (notification) =>
            notification.reason === action.payload.notification.reason
        )
          ? state.notifications
          : [...state.notifications, action.payload.notification],
      };
    }
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification !== action.payload.notification
        ),
      };
    default:
      return state;
  }
};

export default reducer;
