import { FC } from 'react';

import { StyledSentence } from './styled';

interface Props {
  value: string | JSX.Element | JSX.Element[];
  hidden?: boolean;
}

const Sentence: FC<Props> = ({ value, hidden }) => (
  <StyledSentence $hidden={hidden}>{value}</StyledSentence>
);

export default Sentence;
