import { FC, MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SampleText } from '@containers/Processor/types';
import { MultiCreatableValue } from '@components/Creatable/types';
import Export from '@components/Export';
import Toggle from '@components/Toggle';
import { Mode } from '@components/Toggle/types';
import JsonView from '@components/JsonView';
import useToggle from '@hooks/useToggle';
import { UIError } from '@store/ui/types';
import { UIModel } from '@store/user/types';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Legend from './Legend';
import {
  StyledClasses,
  SectionTop,
  Title,
  Actions,
  ClassesContent,
} from './styled';
import { ClassesMode } from './types';

interface Props {
  selectedText: SampleText;
  handleResetLabels: VoidFunction;
  handleRemoveLabel: (value: string) => void;
  handleUpdateLabel: (newLabel: string, value: string) => void;
  handleChangePendingLabels: (value: MultiCreatableValue) => void;
  handleRemovePendingLabel: (value: string) => void;
  handleUpdatePendingLabel: (newLabel: string, value: string) => void;
  uiErrors: UIError[];
  handleDownloadJsonFile: VoidFunction;
}

const Classes: FC<Props> = ({
  selectedText,
  handleResetLabels,
  handleRemoveLabel,
  handleUpdateLabel,
  handleChangePendingLabels,
  handleRemovePendingLabel,
  handleUpdatePendingLabel,
  uiErrors,
  handleDownloadJsonFile,
}) => {
  const [clicked, toggleClicked, handleUnclick] = useToggle(false);

  const { t } = useTranslation();

  const [activeMode, setActiveMode] = useState<ClassesMode>(
    ClassesMode.DEFAULT
  );

  const handleModeChange = (value: ClassesMode) => {
    setActiveMode(value);
  };

  const modes: Mode<ClassesMode>[] = useMemo(
    () => [
      {
        title: t('components.classes.mode.default'),
        type: ClassesMode.DEFAULT,
      },
      {
        title: t('components.classes.mode.json'),
        type: ClassesMode.JSON,
      },
    ],
    []
  );

  const handleResetClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    handleResetLabels();
  };

  const { labels, pendingLabels, data } = selectedText.textClassification!;
  const isEmpty = labels.length === 0 && pendingLabels.length === 0;
  const uiError = uiErrors.includes(UIError.TEXT_CLASSIFICATION_LABELS_EMPTY);

  return (
    <StyledClasses>
      <SectionTop>
        <Title>{t('components.classes.title')}</Title>
        {Object.keys(data).length > 0 && (
          <Actions>
            <Export
              model={UIModel.TEXT_CLASSIFICATION}
              handleDownloadJsonFile={handleDownloadJsonFile}
            />
            <Toggle
              active={activeMode}
              onChange={handleModeChange}
              items={modes}
            />
          </Actions>
        )}
      </SectionTop>
      <ClassesContent $clicked={clicked} $uiError={uiError}>
        {activeMode === ClassesMode.DEFAULT ? (
          <>
            <Header
              value={pendingLabels}
              handleChange={handleChangePendingLabels}
              clicked={clicked}
              toggleClicked={toggleClicked}
              handleUnclick={handleUnclick}
              uiError={uiError}
            />
            {!isEmpty && (
              <Legend
                leftSection={t('components.classes.content.legend.label')}
                rightSection={t('components.classes.content.legend.confidence')}
              />
            )}
            <Content
              labels={labels}
              data={data}
              pendingLabels={pendingLabels}
              handleRemoveLabel={handleRemoveLabel}
              handleUpdateLabel={handleUpdateLabel}
              handleRemovePendingLabel={handleRemovePendingLabel}
              handleUpdatePendingLabel={handleUpdatePendingLabel}
              isEmpty={isEmpty}
            />
            {(labels.length > 0 || pendingLabels.length > 0) && (
              <Footer handleResetClick={handleResetClick} />
            )}
          </>
        ) : (
          <JsonView value={data} />
        )}
      </ClassesContent>
    </StyledClasses>
  );
};

export default Classes;
