import { FC, memo } from 'react';
import { useTheme } from 'styled-components';

import { convertRemToPx } from '@utils/css';

import { config } from './config';
import { IconProps } from './types';

const Icon: FC<IconProps> = ({
  type,
  width,
  height,
  large,
  fill,
  stroke,
  strokeWidth,
  strokeLinecap,
  strokeLinejoin,
  style,
  ...svgProps
}) => {
  const theme = useTheme();

  const icon = config[type];

  const iconWidth = convertRemToPx(width || theme.icon.width);
  const iconHeight = convertRemToPx(height || theme.icon.height);
  return (
    <svg
      {...svgProps}
      viewBox={large ? '0 0 24 24' : '0 0 18 18'}
      width={iconWidth}
      height={iconHeight}
      fill={fill || theme.icon.fill}
      stroke={stroke || theme.icon.stroke}
      strokeWidth={strokeWidth || theme.icon.strokeWidth}
      strokeLinecap={strokeLinecap || theme.icon.strokeLinecap}
      strokeLinejoin={strokeLinejoin || theme.icon.strokeLinejoin}
      style={style}
    >
      <path d={icon} />
    </svg>
  );
};

export default memo(Icon);
