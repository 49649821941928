import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { mapModelToContentLimit } from '@containers/Processor/utils';
import { UIModel } from '@store/user/types';

import Limit from './Limit';
import { StyledFooter } from './styled';
import {
  mapContentLimitTypeToCounter,
  mapContentLimitTypeToMaxTextMessageId,
} from './utils';

interface Props {
  model: UIModel;
  textContent: string[];
}

const Footer: FC<Props> = ({ model, textContent }) => {
  const { t } = useTranslation();

  const { type, min, max } = mapModelToContentLimit[model];
  return (
    <StyledFooter>
      <Limit
        current={mapContentLimitTypeToCounter[type](textContent.join(''))}
        min={min}
        maxText={t(mapContentLimitTypeToMaxTextMessageId[type], {
          count: max,
        })}
        max={max}
      />
    </StyledFooter>
  );
};

export default Footer;
