import { defaultState } from '@store/defaults';

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_CLEAR,
  CHANGE_PASSWORD_FULFILLED,
  CHANGE_PASSWORD_REJECTED,
  CHECK_AUTH,
  CHECK_AUTH_FULFILLED,
  CHECK_AUTH_REJECTED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CLEAR,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_REJECTED,
  LOGIN,
  LOGIN_CLEAR,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  REGISTER,
  REGISTER_CLEAR,
  REGISTER_FULFILLED,
  REGISTER_REJECTED,
  RESET_PASSWORD,
  RESET_PASSWORD_CLEAR,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
  VALIDATE_PASSWORD,
  VALIDATE_PASSWORD_CLEAR,
  VALIDATE_PASSWORD_FULFILLED,
  VALIDATE_PASSWORD_REJECTED,
} from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  login: defaultState,
  register: defaultState,
  checkAuth: { ...defaultState, loading: true },
  currentUser: null,
  forgotPassword: defaultState,
  resetPassword: defaultState,
  validatePassword: defaultState,
  changePassword: defaultState,
};

const reducer = (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          success: false,
          error: '',
        },
      };
    case LOGIN_FULFILLED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: true,
          error: '',
        },
        currentUser: action.payload.currentUser,
      };
    case LOGIN_REJECTED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case LOGIN_CLEAR:
      return {
        ...state,
        login: defaultState,
      };
    case REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          loading: true,
          success: false,
          error: '',
        },
      };
    case REGISTER_FULFILLED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          success: true,
          error: '',
        },
        currentUser: action.payload.currentUser,
      };
    case REGISTER_REJECTED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case REGISTER_CLEAR:
      return {
        ...state,
        register: defaultState,
      };
    case LOGOUT:
    case LOGOUT_REJECTED:
      return state;
    case LOGOUT_FULFILLED:
      return {
        ...state,
        currentUser: null,
      };
    case CHECK_AUTH:
      return {
        ...state,
        checkAuth: {
          ...state.checkAuth,
          loading: true,
        },
      };
    case CHECK_AUTH_FULFILLED:
      return {
        ...state,
        checkAuth: {
          ...state.checkAuth,
          loading: false,
          success: true,
          error: '',
        },
        currentUser: action.payload.currentUser,
      };
    case CHECK_AUTH_REJECTED:
      return {
        ...state,
        checkAuth: {
          ...state.checkAuth,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: true,
          success: false,
          error: '',
        },
      };
    case FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case FORGOT_PASSWORD_REJECTED:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case FORGOT_PASSWORD_CLEAR:
      return {
        ...state,
        forgotPassword: defaultState,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
          success: false,
          error: '',
        },
      };
    case RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case RESET_PASSWORD_REJECTED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case RESET_PASSWORD_CLEAR:
      return {
        ...state,
        resetPassword: defaultState,
      };
    case VALIDATE_PASSWORD:
      return {
        ...state,
        validatePassword: {
          ...state.validatePassword,
          loading: true,
          success: false,
          error: '',
        },
      };
    case VALIDATE_PASSWORD_FULFILLED:
      return {
        ...state,
        validatePassword: {
          ...state.validatePassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case VALIDATE_PASSWORD_REJECTED:
      return {
        ...state,
        validatePassword: {
          ...state.validatePassword,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case VALIDATE_PASSWORD_CLEAR:
      return {
        ...state,
        validatePassword: defaultState,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
          success: false,
          error: '',
        },
      };
    case CHANGE_PASSWORD_FULFILLED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case CHANGE_PASSWORD_REJECTED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      };
    case CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        changePassword: defaultState,
      };
    default:
      return state;
  }
};

export default reducer;
