export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';

export const REGISTER = 'REGISTER';
export const REGISTER_FULFILLED = 'REGISTER_FULFILLED';
export const REGISTER_REJECTED = 'REGISTER_REJECTED';
export const REGISTER_CLEAR = 'REGISTER_CLEAR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';

export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_FULFILLED = 'CHECK_AUTH_FULFILLED';
export const CHECK_AUTH_REJECTED = 'CHECK_AUTH_REJECTED';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED';
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED';
export const FORGOT_PASSWORD_CLEAR = 'FORGOT_PASSWORD_CLEAR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR';

export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const VALIDATE_PASSWORD_FULFILLED = 'VALIDATE_PASSWORD_FULFILLED';
export const VALIDATE_PASSWORD_REJECTED = 'VALIDATE_PASSWORD_REJECTED';
export const VALIDATE_PASSWORD_CLEAR = 'VALIDATE_PASSWORD_CLEAR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
export const CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR';
