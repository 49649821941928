import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const dropdownTheme: DefaultTheme['dropdown'] = {
  backgroundColor: Color.BLACK,
  borderColor: Color.TUNDORA,
  icon: {
    hover: {
      backgroundColor: Color.TUNDORA,
    },
  },
  option: {
    hover: {
      backgroundColor: Color.TUNDORA,
    },
  },
};
