import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes';

export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum NotificationReason {
  GENERAL_TEXT_LIMIT_EXCEEDED = 'generalTextLimitExceeded',
  GENERAL_TEXT_EMPTY = 'generalTextEmpty',
  TEXT_CLASSIFICATION_LABELS_EMPTY = 'textClassificationLabelsEmpty',
  TEXT_TO_TABLE_COLUMNS_EMPTY = 'textToTableColumnsEmpty',
  TEXT_TO_TABLE_COLUMN_NAME_MAX_WIDTH_EXCEEDED = 'textToTableColumnNameMaxWidthExceeded',
  NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY = 'namedEntityRecognitionEntitiesEmpty',
  FILE_INVALID_TYPE = 'fileInvalidType',
  FILE_TOO_LARGE = 'fileTooLarge',
  TOO_MANY_FILES = 'tooManyFiles',
  UNKNOWN = 'unknown',
  INVALID_URL = 'invalidUrl',
}

export interface Notification {
  type: NotificationType;
  reason: NotificationReason;
}

export interface NotificationState {
  notifications: Notification[];
}

export type AddNotification = {
  type: typeof ADD_NOTIFICATION;
  payload: {
    notification: Notification;
  };
};

export type RemoveNotification = {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    notification: Notification;
  };
};

export type NotificationActions = AddNotification | RemoveNotification;
