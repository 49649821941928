import { FC, MouseEventHandler } from 'react';

import Header from './Header';
import Preview from './Preview';
import { StyledFile, PreviewWrapper } from './styled';

interface Props {
  value: File;
  handleRemove: MouseEventHandler<HTMLDivElement>;
}

const File: FC<Props> = ({ value, handleRemove }) => (
  <StyledFile>
    <Header value={value} handleRemove={handleRemove} />
    <PreviewWrapper>
      <Preview value={value} />
    </PreviewWrapper>
  </StyledFile>
);

export default File;
