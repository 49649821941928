import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';
import { ButtonType } from '@components/Button/types';

export const buttonTheme: DefaultTheme['button'] = {
  [ButtonType.PRIMARY]: {
    enabled: {
      backgroundColor: Color.CORNFLOWER_BLUE,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
    disabled: {
      backgroundColor: Color.SILVER_CHALICE,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
    hover: {
      backgroundColor: Color.INDIGO,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
    active: {
      backgroundColor: Color.SILVER_CHALICE,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
  },
  [ButtonType.SECONDARY]: {
    enabled: {
      backgroundColor: Color.TRANSPARENT,
      borderColor: Color.CORNFLOWER_BLUE,
      color: Color.WHITE,
    },
    disabled: {
      backgroundColor: Color.SILVER_CHALICE,
      borderColor: Color.SILVER_CHALICE,
      color: Color.WHITE,
    },
    hover: {
      backgroundColor: Color.CORNFLOWER_BLUE,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
    active: {
      backgroundColor: Color.INDIGO,
      borderColor: Color.TRANSPARENT,
      color: Color.WHITE,
    },
  },
  [ButtonType.TERTIARY]: {
    enabled: {
      backgroundColor: Color.PIZZA,
      borderColor: Color.PIZZA,
      color: Color.WHITE,
    },
    disabled: {
      backgroundColor: Color.SILVER_CHALICE,
      borderColor: Color.SILVER_CHALICE,
      color: Color.WHITE,
    },
    hover: {
      backgroundColor: Color.BUTTERED_RUM,
      borderColor: Color.BUTTERED_RUM,
      color: Color.WHITE,
    },
    active: {
      backgroundColor: Color.BUTTERED_RUM,
      borderColor: Color.BUTTERED_RUM,
      color: Color.WHITE,
    },
  },
  [ButtonType.QUATERNARY]: {
    enabled: {
      backgroundColor: Color.MINE_SHAFT,
      borderColor: Color.TUNDORA,
      color: Color.SILVER_CHALICE,
    },
    disabled: {
      backgroundColor: Color.MINE_SHAFT,
      borderColor: Color.TUNDORA,
      color: Color.SILVER_CHALICE,
    },
    hover: {
      backgroundColor: Color.MINE_SHAFT,
      borderColor: Color.TUNDORA,
      color: Color.CORNFLOWER_BLUE,
    },
    active: {
      backgroundColor: Color.MINE_SHAFT,
      borderColor: Color.TUNDORA,
      color: Color.CORNFLOWER_BLUE,
    },
  },
};
