import styled from 'styled-components';

export const StyledLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
`;

export const Min = styled.p``;

export const Incrementor = styled.div`
  display: flex;
  align-items: center;
`;

export const Current = styled.div<{ $error: boolean }>`
  color: ${(props) =>
    props.$error
      ? props.theme.processor.footerLimitErrorColor
      : props.theme.processor.footerCurrentLimitColor};
`;

export const Max = styled.p`
  color: ${(props) => props.theme.processor.footerMaxLimitColor};
`;
