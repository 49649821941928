import styled, { css } from 'styled-components';

import { EntityPosition } from '@components/Relations/Content/Item/types';

export const StyledEntity = styled.div<{
  $selected: boolean;
  $hovered: boolean;
  $position: EntityPosition;
}>`
  display: inline-flex;
  align-items: center;
  border: 1px solid
    ${(props) => props.theme.entity[props.$position].borderColor};
  border-radius: 0.4rem;
  padding: 0 0.5rem;
  line-height: 1.2;

  ${(props) =>
    props.$selected &&
    css`
      border: 1px solid
        ${props.theme.entity[props.$position].selected.borderColor};
    `};

  ${(props) =>
    props.$hovered &&
    css`
      border: 1px solid
        ${props.theme.entity[props.$position].hovered.borderColor};
    `};
`;
