import { createSelector } from 'reselect';

import { AppState } from '@store/rootReducer';

export const getNamedEntityRecognitionProcessDetailsState = (state: AppState) =>
  state.user.namedEntityRecognitionProcessDetails;

export const getTextClassificationProcessDetailsState = (state: AppState) =>
  state.user.textClassificationProcessDetails;

export const getTextToTableProcessDetailsState = (state: AppState) =>
  state.user.textToTableProcessDetails;

export const getRelationExtractionProcessDetailsState = (state: AppState) =>
  state.user.relationExtractionProcessDetails;

export const getNamedEntityRecognitionProcessDetailsSelector = createSelector(
  getNamedEntityRecognitionProcessDetailsState,
  (namedEntityRecognitionProcessDetails) => namedEntityRecognitionProcessDetails
);

export const getTextClassificationProcessDetailsSelector = createSelector(
  getTextClassificationProcessDetailsState,
  (textClassificationProcessDetails) => textClassificationProcessDetails
);

export const getTextToTableProcessDetailsSelector = createSelector(
  getTextToTableProcessDetailsState,
  (textToTableProcessDetails) => textToTableProcessDetails
);

export const getRelationExtractionProcessDetailsSelector = createSelector(
  getRelationExtractionProcessDetailsState,
  (relationExtractionProcessDetails) => relationExtractionProcessDetails
);
