import styled from 'styled-components';

import Link from '@components/Link';

export const StyledFooter = styled.div`
  padding: 0 2.5rem;
  margin: 2rem 2.5rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.classes.actionLinkColor};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.classes.actionLinkColor};
  }
`;
