import styled from 'styled-components';

import Link from '@components/Link';

export const StyledItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 2.5rem;
  color: ${(props) => props.theme.classes.itemColor};
  border-top: 1px solid ${(props) => props.theme.classes.borderColor};
  border-right: 1px solid ${(props) => props.theme.classes.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.classes.borderColor};
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.theme.classes.hover.backgroundColor};
    background-color: ${(props) => props.theme.classes.hover.backgroundColor};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Number = styled.p`
  margin-right: 0.5rem;
`;

export const LabelParent = styled.div`
  margin-right: 5rem;

  &:focus-within {
    width: 100%;
  }
`;

export const Label = styled.p`
  outline: none;

  &:focus {
    background-color: ${(props) => props.theme.classes.itemEditableColor};
  }
`;

export const Score = styled.p``;

export const Actions = styled.div`
  margin-left: 2rem;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.classes.actionLinkColor};

  &:hover {
    color: ${(props) => props.theme.classes.actionLinkColor};
  }
`;
