import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Dot from '@components/Dot';
import { Color } from '@theme/colors';

import { StyledTooltipLabel, DotWrapper, Text, Value } from './styled';

interface Props {
  active: boolean;
  score: string;
}

const TooltipLabel: FC<Props> = ({ active, score }) => {
  const { t } = useTranslation();

  return (
    <StyledTooltipLabel>
      <DotWrapper>
        <Dot color={active ? Color.FUEL_YELLOW : Color.GRAY} />
      </DotWrapper>
      <Text>
        {t('components.highlighter.namedEntityRecognition.sentence.score')}
      </Text>
      <Value>{score}</Value>
    </StyledTooltipLabel>
  );
};

export default TooltipLabel;
