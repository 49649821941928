import styled from 'styled-components';

import Link from '@components/Link';

export const StyledMobileMenu = styled.div`
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  right: 0;
  min-width: 30rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.header.mobileMenu.backgroundColor};
  z-index: 9999;
  margin-top: 1rem;
`;

export const MenuElement = styled(Link)`
  font-size: 2rem;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
