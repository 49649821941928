import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getEmptyCustomText } from '@containers/Home/data';
import { CustomText } from '@containers/Home/types';
import Classes from '@components/Classes';
import { MultiCreatableValue } from '@components/Creatable/types';
import ProcessorComponent from '@components/Processor';
import { mapToTextToTableColumn } from '@components/Processor/Header/utils';
import Submit from '@components/Processor/Submit';
import Relations from '@components/Relations';
import Table from '@components/Table';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { addNotification } from '@store/notification/actions';
import {
  NotificationReason,
  NotificationType,
} from '@store/notification/types';
import {
  clearModels,
  processNamedEntityRecognition,
  processRelationExtraction,
  processTextClassification,
  processTextToTable,
} from '@store/user/actions';
import {
  getNamedEntityRecognitionProcessDetailsSelector,
  getTextToTableProcessDetailsSelector,
  getTextClassificationProcessDetailsSelector,
  getRelationExtractionProcessDetailsSelector,
} from '@store/user/selectors';
import { RelationExtractionData, UIModel } from '@store/user/types';
import { addUIError, clearUIErrors, removeUIError } from '@store/ui/actions';
import { UIError } from '@store/ui/types';
import { getErrorsSelector } from '@store/ui/selectors';
import { Color } from '@theme/colors';

import {
  getDefaultTextToTableColumns,
  getEmptyText,
  namedEntityRecognitionSampleTexts,
  relationExtractionSampleTexts,
  textClassificationSampleTexts,
  textToTableSampleTexts,
} from './data';
import { NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID } from './defaults';
import {
  StyledProcessor,
  InteractiveSpace,
  ProcessorComponentWrapper,
  RelationsComponentWrapper,
  ClassesComponentWrapper,
  TableComponentWrapper,
  SubmitWrapper,
} from './styled';
import {
  SampleText,
  TextStatus,
  TextToTableHighlightedData,
  TextToTableHighlightedElement,
} from './types';
import {
  downloadJSONFile,
  getRowHighlightedData,
  mapModelToContentLimit,
  mapTableDataToCopyFormat,
  mapTableDataToExportFormat,
  validateIfTextToTableColumnIsEmpty,
  validateIfTextToTableColumnsAreDefault,
  validateText,
  validateTextToTableColumnName,
  validateURL,
  getColumnHighlightedData,
} from './utils';

interface Props {
  model: UIModel;
}

const Processor: FC<Props> = ({ model }) => {
  const dispatch = useAppDispatch();

  const namedEntityRecognitionProcessDetails = useAppSelector(
    getNamedEntityRecognitionProcessDetailsSelector
  );
  const textClassificationProcessDetails = useAppSelector(
    getTextClassificationProcessDetailsSelector
  );
  const textToTableProcessDetails = useAppSelector(
    getTextToTableProcessDetailsSelector
  );
  const relationExtractionProcessDetails = useAppSelector(
    getRelationExtractionProcessDetailsSelector
  );
  const uiErrors = useAppSelector(getErrorsSelector);

  const { customTexts, handleCustomTextsChange } = useOutletContext() as {
    customTexts: CustomText[];
    handleCustomTextsChange: (customTexts: CustomText[]) => void;
  };

  const [texts, setTexts] = useState<SampleText[]>([]);
  const [selectedTextIndex, setSelectedTextIndex] = useState(-1);

  const textsRef = useRef(texts);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearModels());
    const customText = customTexts.find(
      (customText) => customText.model === model
    );
    if (model === UIModel.NAMED_ENTITY_RECOGNITION) {
      const newTexts = [
        ...namedEntityRecognitionSampleTexts,
        getEmptyText([], model, customText?.content, customText?.meta),
      ];
      setTexts(newTexts);
      setSelectedTextIndex(newTexts.length - 1);
      return;
    }
    if (model === UIModel.TEXT_CLASSIFICATION) {
      const newTexts = [
        ...textClassificationSampleTexts,
        getEmptyText([], model, customText?.content, customText?.meta),
      ];
      setTexts(newTexts);
      setSelectedTextIndex(newTexts.length - 1);
      return;
    }
    if (model === UIModel.TEXT_TO_TABLE) {
      const newTexts = [
        ...textToTableSampleTexts,
        getEmptyText([], model, customText?.content, customText?.meta),
      ];
      setTexts(newTexts);
      setSelectedTextIndex(newTexts.length - 1);
      return;
    }
    const newTexts = [
      ...relationExtractionSampleTexts,
      getEmptyText([], model, customText?.content, customText?.meta),
    ];
    setTexts(newTexts);
    setSelectedTextIndex(newTexts.length - 1);
  }, [model]);

  useEffect(() => {
    textsRef.current = texts;
  }, [texts]);

  const handleAcceptedFiles = (files: File[]) => {
    if (uiErrors.includes(UIError.GENERAL_TEXT_EMPTY)) {
      dispatch(removeUIError(UIError.GENERAL_TEXT_EMPTY));
    }
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            files,
          };
        }
        return text;
      })
    );
  };

  const handleRemoveFile = (file: File) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            files: text.files.filter((item) => item !== file),
          };
        }
        return text;
      })
    );
  };

  const handleRemoveLink = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            link: false,
            content: [],
          };
        }
        return text;
      })
    );
  };

  const handleTextClick = (index: number) => {
    setTexts((texts) =>
      texts.map((text, textIndex) => {
        if (
          textIndex === index &&
          text.status === TextStatus.PROCESSING_SUCCESS
        ) {
          return {
            ...text,
            status: TextStatus.HIGHLIGHTED,
          };
        }
        return text;
      })
    );
    setSelectedTextIndex(index);
    if (uiErrors.length > 0) {
      dispatch(clearUIErrors());
    }
  };

  const handleTextContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    const { text: cleanText, isValid } = validateText(
      value,
      mapModelToContentLimit[model]
    );
    const isTruncated = value.length > cleanText.length;
    if (isTruncated) {
      dispatch(
        addNotification({
          type: NotificationType.INFO,
          reason: NotificationReason.GENERAL_TEXT_LIMIT_EXCEEDED,
        })
      );
    }
    const { isLike: isUrlLike, isValid: isUrlValid } = validateURL(cleanText);
    if (isUrlLike && !isUrlValid) {
      dispatch(
        addNotification({
          type: NotificationType.ERROR,
          reason: NotificationReason.INVALID_URL,
        })
      );
      dispatch(addUIError(UIError.INVALID_URL_ERROR));
    } else if (uiErrors.includes(UIError.INVALID_URL_ERROR)) {
      dispatch(removeUIError(UIError.INVALID_URL_ERROR));
    }
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            content: [cleanText],
            changed: true,
            link: isUrlValid,
            files: [],
            status: isValid
              ? TextStatus.PENDING_VALID
              : TextStatus.PENDING_ERROR,
          };
        }
        return text;
      })
    );
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return {
            ...customText,
            content: cleanText,
          };
        }
        return customText;
      })
    );
  };

  const handleSubmitTextClick = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              model === UIModel.TEXT_TO_TABLE
                ? text.content.join('').length === 0 || text.link
                  ? TextStatus.EMPTY
                  : TextStatus.READY_FOR_PROCESSING
                : text.status === TextStatus.PENDING_VALID
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            ...(model === UIModel.TEXT_CLASSIFICATION
              ? {
                  textClassification: {
                    ...text.textClassification!,
                    data: text.changed ? {} : text.textClassification!.data,
                  },
                }
              : model === UIModel.NAMED_ENTITY_RECOGNITION
              ? {
                  namedEntityRecognition: {
                    ...text.namedEntityRecognition!,
                    data: text.changed ? [] : text.namedEntityRecognition!.data,
                  },
                }
              : {}),
            changed: false,
          };
        }
        return text;
      })
    );
  };

  const handleEditTextClick = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.files.length > 0 ? text.status : TextStatus.PENDING_VALID,
          };
        }
        return text;
      })
    );
  };

  const handleFocusTextClick = () => {
    if (uiErrors.includes(UIError.GENERAL_TEXT_EMPTY)) {
      dispatch(removeUIError(UIError.GENERAL_TEXT_EMPTY));
    }
  };

  const triggerAutoHighlight = (index: number) => {
    setTexts((texts) =>
      texts.map((text, textIndex) => {
        if (
          textIndex === index &&
          selectedTextIndex === index &&
          text.status === TextStatus.PROCESSING_SUCCESS
        ) {
          return {
            ...text,
            status: TextStatus.HIGHLIGHTED,
          };
        }
        return text;
      })
    );
  };

  useEffect(() => {
    const fileNames = Object.keys(namedEntityRecognitionProcessDetails);
    if (fileNames.length === 0) {
      return;
    }
    setTexts((texts) =>
      texts.map((text) => {
        const file = namedEntityRecognitionProcessDetails[text.title];
        if (!file) {
          return text;
        }
        let newStatus = text.status;
        let newData = text.namedEntityRecognition!.data;
        const { loading, success, error, index, data } = file;
        if (loading) {
          newStatus = TextStatus.PROCESSING_PENDING;
        } else if (success) {
          if (
            [
              TextStatus.PROCESSING_PENDING,
              TextStatus.READY_FOR_PROCESSING,
            ].includes(newStatus)
          ) {
            newStatus = TextStatus.PROCESSING_SUCCESS;
            triggerAutoHighlight(index);
          }
          newData = data;
        } else if (error) {
          newStatus = TextStatus.PROCESSING_ERROR;
        }
        return {
          ...text,
          status: newStatus,
          namedEntityRecognition: {
            ...text.namedEntityRecognition!,
            data: newData,
          },
        };
      })
    );
  }, [namedEntityRecognitionProcessDetails]);

  useEffect(() => {
    const fileNames = Object.keys(textClassificationProcessDetails);
    if (fileNames.length === 0) {
      return;
    }
    setTexts((texts) =>
      texts.map((text) => {
        const file = textClassificationProcessDetails[text.title];
        if (!file) {
          return text;
        }
        let newStatus = text.status;
        let newData = text.textClassification!.data;
        const { loading, success, error, index, data } = file;
        if (loading) {
          newStatus = TextStatus.PROCESSING_PENDING;
        } else if (success) {
          if (
            [
              TextStatus.PROCESSING_PENDING,
              TextStatus.READY_FOR_PROCESSING,
            ].includes(newStatus)
          ) {
            newStatus = TextStatus.PROCESSING_SUCCESS;
            triggerAutoHighlight(index);
          }
          newData = data;
        } else if (error) {
          newStatus = TextStatus.PROCESSING_ERROR;
        }
        return {
          ...text,
          status: newStatus,
          textClassification: {
            ...text.textClassification!,
            labels: [
              ...text.textClassification!.pendingLabels,
              ...text.textClassification!.labels,
            ],
            pendingLabels: [],
            data: newData,
          },
        };
      })
    );
  }, [textClassificationProcessDetails]);

  useEffect(() => {
    const fileNames = Object.keys(textToTableProcessDetails);
    if (fileNames.length === 0) {
      return;
    }
    setTexts((texts) =>
      texts.map((text) => {
        const file = textToTableProcessDetails[text.title];
        if (!file) {
          return text;
        }
        let newStatus = text.status;
        let newData = text.textToTable!.data;
        let highlightedData: TextToTableHighlightedData[] = [];
        let highlightedElement: TextToTableHighlightedElement;
        const { loading, success, error, index, data } = file;
        if (loading) {
          newStatus = TextStatus.PROCESSING_PENDING;
        } else if (success) {
          if (
            [
              TextStatus.PROCESSING_PENDING,
              TextStatus.READY_FOR_PROCESSING,
            ].includes(newStatus)
          ) {
            newStatus = TextStatus.PROCESSING_SUCCESS;
            triggerAutoHighlight(index);
          }
          newData = data;
          highlightedData = getRowHighlightedData(
            text.textToTable!.columns,
            newData,
            0
          );
          highlightedElement = {
            type: 'row',
            index: 0,
          };
        } else if (error) {
          newStatus = TextStatus.PROCESSING_ERROR;
        }
        return {
          ...text,
          status:
            text.files.length > 0 || text.link ? TextStatus.EMPTY : newStatus,
          textToTable: {
            ...text.textToTable!,
            columns: text.textToTable!.columns.map((column) => ({
              ...column,
              updated: false,
            })),
            data: newData,
            highlightedData,
            highlightedElement,
          },
        };
      })
    );
  }, [textToTableProcessDetails]);

  useEffect(() => {
    const fileNames = Object.keys(relationExtractionProcessDetails);
    if (fileNames.length === 0) {
      return;
    }
    setTexts((texts) =>
      texts.map((text) => {
        const file = relationExtractionProcessDetails[text.title];
        if (!file) {
          return text;
        }
        let newStatus = text.status;
        let newRelations = text.relationExtraction!.relations;
        let newActiveRelations = text.relationExtraction!.activeRelations;
        let newSelectedRelations = text.relationExtraction!.selectedRelations;
        const { loading, success, error, index, data } = file;
        if (loading) {
          newStatus = TextStatus.PROCESSING_PENDING;
        } else if (success) {
          if (
            [
              TextStatus.PROCESSING_PENDING,
              TextStatus.READY_FOR_PROCESSING,
            ].includes(newStatus)
          ) {
            newStatus = TextStatus.PROCESSING_SUCCESS;
            triggerAutoHighlight(index);
          }
          if (data) {
            newRelations = data;
            newActiveRelations = newRelations;
            newSelectedRelations =
              newActiveRelations.length > 0 ? [newActiveRelations[0]] : [];
          }
        } else if (error) {
          newStatus = TextStatus.PROCESSING_ERROR;
        }
        return {
          ...text,
          status: newStatus,
          relationExtraction: {
            ...text.relationExtraction!,
            relations: newRelations,
            activeRelations: newActiveRelations,
            selectedRelations: newSelectedRelations,
          },
        };
      })
    );
  }, [relationExtractionProcessDetails]);

  const handleProcessTextClick = () => {
    const text = texts[selectedTextIndex];
    if (text.files.length === 0 && text.content.filter(Boolean).length === 0) {
      dispatch(
        addNotification({
          type: NotificationType.ERROR,
          reason: NotificationReason.GENERAL_TEXT_EMPTY,
        })
      );
      dispatch(addUIError(UIError.GENERAL_TEXT_EMPTY));
      return;
    }
    if (model === UIModel.NAMED_ENTITY_RECOGNITION) {
      const labels = text.namedEntityRecognition!.labels;
      if (labels.length === 0) {
        dispatch(
          addNotification({
            type: NotificationType.ERROR,
            reason: NotificationReason.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY,
          })
        );
        dispatch(addUIError(UIError.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY));
        return;
      }
      dispatch(
        processNamedEntityRecognition(
          selectedTextIndex,
          text.title,
          text.content.join(''),
          labels
        )
      );
    }
    if (model === UIModel.TEXT_CLASSIFICATION) {
      const labels = [
        ...text.textClassification!.labels.map(({ label }) => label),
        ...text.textClassification!.pendingLabels.map(({ label }) => label),
      ];
      if (labels.length === 0) {
        dispatch(
          addNotification({
            type: NotificationType.ERROR,
            reason: NotificationReason.TEXT_CLASSIFICATION_LABELS_EMPTY,
          })
        );
        dispatch(addUIError(UIError.TEXT_CLASSIFICATION_LABELS_EMPTY));
        return;
      }
      dispatch(
        processTextClassification(
          selectedTextIndex,
          text.title,
          text.content.join(''),
          labels
        )
      );
    }
    if (model === UIModel.TEXT_TO_TABLE) {
      const defaultColumns = getDefaultTextToTableColumns();
      const columns = text.textToTable!.columns;
      const columnsEmpty = validateIfTextToTableColumnsAreDefault(
        columns,
        defaultColumns
      );
      if (columnsEmpty) {
        dispatch(
          addNotification({
            type: NotificationType.ERROR,
            reason: NotificationReason.TEXT_TO_TABLE_COLUMNS_EMPTY,
          })
        );
        dispatch(addUIError(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY));
        return;
      }
      dispatch(
        processTextToTable(
          selectedTextIndex,
          text.title,
          text.content.join(''),
          columns.map((column) => column.label),
          text.link,
          text.files
        )
      );
    }
    if (model === UIModel.RELATION_EXTRACTION) {
      dispatch(
        processRelationExtraction(
          selectedTextIndex,
          text.title,
          text.content.join(''),
          []
        )
      );
    }
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return getEmptyCustomText(model);
        }
        return customText;
      })
    );
  };

  const handleToggleSelectedRelation = (
    targetRelation: RelationExtractionData
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const { selectedRelations } = text.relationExtraction!;
          const foundSelectedRelation = selectedRelations.find(
            (selectedRelation) => selectedRelation.id === targetRelation.id
          );
          return {
            ...text,
            relationExtraction: {
              ...text.relationExtraction!,
              selectedRelations: foundSelectedRelation
                ? selectedRelations.filter(
                    (selectedRelation) =>
                      selectedRelation.id !== targetRelation.id
                  )
                : [...selectedRelations, targetRelation],
            },
          };
        }
        return text;
      })
    );
  };

  const handleToggleActiveRelation = (relation: RelationExtractionData) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const { activeRelations } = text.relationExtraction!;
          return {
            ...text,
            relationExtraction: {
              ...text.relationExtraction!,
              activeRelations: activeRelations.find(
                (activeRelation) => activeRelation.id === relation.id
              )
                ? activeRelations.filter(
                    (activeRelation) => activeRelation.id !== relation.id
                  )
                : [...activeRelations, relation],
            },
          };
        }
        return text;
      })
    );
  };

  const handleToggleHoveredRelation = (relation: RelationExtractionData) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const { hoveredRelations } = text.relationExtraction!;
          return {
            ...text,
            relationExtraction: {
              ...text.relationExtraction!,
              hoveredRelations: hoveredRelations.find(
                (hoveredRelation) => hoveredRelation.id === relation.id
              )
                ? hoveredRelations.filter(
                    (hoveredRelation) => hoveredRelation.id !== relation.id
                  )
                : [...hoveredRelations, relation],
            },
          };
        }
        return text;
      })
    );
  };

  const handleChangeNamedEntityRecognitionLabels = (
    value: MultiCreatableValue
  ) => {
    if (
      value.length > 0 &&
      uiErrors.includes(UIError.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY)
    ) {
      dispatch(removeUIError(UIError.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY));
    }
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            namedEntityRecognition: {
              ...text.namedEntityRecognition!,
              labels: value.map((item) => item.label),
            },
          };
        }
        return text;
      })
    );
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return {
            ...customText,
            meta: value,
          };
        }
        return customText;
      })
    );
  };

  const handleResetTextClassificationLabels = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              labels: text.textClassification!.initialLabels,
              pendingLabels: [],
            },
          };
        }
        return text;
      })
    );
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return {
            ...customText,
            meta: [],
          };
        }
        return customText;
      })
    );
  };

  const handleRemoveTextClassificationLabel = (value: string) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              labels: text.textClassification!.labels.filter(
                (label) => label.value !== value
              ),
            },
          };
        }
        return text;
      })
    );
  };

  const handleUpdateTextClassificationLabel = (
    newLabel: string,
    value: string
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              labels: text.textClassification!.labels.map((label) => {
                if (label.value === value) {
                  return {
                    ...label,
                    label: newLabel,
                  };
                }
                return label;
              }),
            },
          };
        }
        return text;
      })
    );
  };

  const handleChangePendingTextClassificationLabels = (
    value: MultiCreatableValue
  ) => {
    if (
      value.length > 0 &&
      uiErrors.includes(UIError.TEXT_CLASSIFICATION_LABELS_EMPTY)
    ) {
      dispatch(removeUIError(UIError.TEXT_CLASSIFICATION_LABELS_EMPTY));
    }
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              pendingLabels: value,
            },
          };
        }
        return text;
      })
    );
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return {
            ...customText,
            meta: value,
          };
        }
        return customText;
      })
    );
  };

  const handleRemovePendingTextClassificationLabel = (value: string) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              pendingLabels: text.textClassification!.pendingLabels.filter(
                (label) => label.value !== value
              ),
            },
          };
        }
        return text;
      })
    );
  };

  const handleUpdatePendingTextClassificationLabel = (
    newLabel: string,
    value: string
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textClassification: {
              ...text.textClassification!,
              pendingLabels: text.textClassification!.pendingLabels.map(
                (label) => {
                  if (label.value === value) {
                    return {
                      ...label,
                      label: newLabel,
                    };
                  }
                  return label;
                }
              ),
            },
          };
        }
        return text;
      })
    );
  };

  const handleAddTextToTableColumn = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const defaultColumns = getDefaultTextToTableColumns();
          const newColumns = [
            ...text.textToTable!.columns,
            ...mapToTextToTableColumn([
              t(NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID),
            ]).map((column) => ({ ...column, new: true })),
          ];
          const columnsEmpty = validateIfTextToTableColumnsAreDefault(
            newColumns,
            defaultColumns
          );
          if (
            !columnsEmpty &&
            uiErrors.includes(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY)
          ) {
            dispatch(removeUIError(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY));
          }
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textToTable: {
              ...text.textToTable!,
              columns: newColumns,
              data: {
                ...text.textToTable!.data,
                ...{
                  [t(NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID)]: [],
                },
              },
            },
          };
        }
        return text;
      })
    );
  };

  const handleDeleteTextToTableColumn = (value: string) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const newColumns = text.textToTable!.columns.filter(
            (column) => column.value !== value
          );
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textToTable: {
              ...text.textToTable!,
              columns:
                newColumns.length === 0
                  ? mapToTextToTableColumn([
                      t(NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID),
                    ])
                  : newColumns,
            },
          };
        }
        return text;
      })
    );
  };

  const handleUpdateTextToTableColumn = (newLabel: string, value: string) => {
    const isColumnNameValid = validateTextToTableColumnName(newLabel);
    if (!isColumnNameValid) {
      dispatch(
        addNotification({
          type: NotificationType.ERROR,
          reason:
            NotificationReason.TEXT_TO_TABLE_COLUMN_NAME_MAX_WIDTH_EXCEEDED,
        })
      );
      return;
    }
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const defaultColumns = getDefaultTextToTableColumns();
          const newColumns = text.textToTable!.columns.map((label) => {
            if (label.value === value) {
              return {
                ...label,
                label: newLabel,
                updated: label.label !== newLabel,
                new: false,
              };
            }
            return label;
          });
          const columnsEmpty = validateIfTextToTableColumnsAreDefault(
            newColumns,
            defaultColumns
          );
          if (
            !columnsEmpty &&
            uiErrors.includes(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY)
          ) {
            dispatch(removeUIError(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY));
          }
          return {
            ...text,
            status:
              text.status === TextStatus.HIGHLIGHTED
                ? TextStatus.READY_FOR_PROCESSING
                : text.status,
            textToTable: {
              ...text.textToTable!,
              columns: newColumns,
              data: {
                ...text.textToTable!.data,
                [newLabel]: text.textToTable!.data[newLabel] || [],
              },
            },
          };
        }
        return text;
      })
    );
  };

  const handleTextToTableRowClick = (
    rowIndex: number,
    isHighlighted: boolean
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            textToTable: {
              ...text.textToTable!,
              highlightedData: isHighlighted
                ? []
                : getRowHighlightedData(
                    text.textToTable!.columns,
                    text.textToTable!.data,
                    rowIndex
                  ),
              highlightedElement: isHighlighted
                ? undefined
                : {
                    type: 'row',
                    index: rowIndex,
                  },
            },
          };
        }
        return text;
      })
    );
  };

  const handleTextToTableColumnClick = (
    value: string,
    color: Color,
    isHighlighted: boolean
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          const clickedColumn = text.textToTable!.columns.find(
            (column) => column.value === value
          );
          if (
            !clickedColumn ||
            !text.textToTable!.data[clickedColumn.label] ||
            validateIfTextToTableColumnIsEmpty(
              clickedColumn.label,
              text.textToTable!.data
            )
          ) {
            return text;
          }
          return {
            ...text,
            textToTable: {
              ...text.textToTable!,
              highlightedData: isHighlighted
                ? []
                : getColumnHighlightedData(
                    clickedColumn.label,
                    text.textToTable!.data,
                    color
                  ),
              highlightedElement: isHighlighted
                ? undefined
                : {
                    type: 'column',
                    index: text.textToTable!.columns.indexOf(clickedColumn),
                  },
            },
          };
        }
        return text;
      })
    );
  };

  const handleTextToTableCellClick = (
    column: string,
    value: string,
    color: Color,
    isHighlighted: boolean
  ) => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return {
            ...text,
            textToTable: {
              ...text.textToTable!,
              highlightedData: isHighlighted
                ? []
                : [{ column, label: value, color }],
              highlightedElement: isHighlighted
                ? undefined
                : {
                    type: 'cell',
                    index: 0,
                  },
            },
          };
        }
        return text;
      })
    );
  };

  const prepareTableTextForCopy = () => {
    const selectedText = texts[selectedTextIndex];
    if (!selectedText || model !== UIModel.TEXT_TO_TABLE) {
      return '';
    }
    return mapTableDataToCopyFormat(selectedText.textToTable!);
  };

  const prepareTableTextForCSV = () => {
    const selectedText = texts[selectedTextIndex];
    if (!selectedText || model !== UIModel.TEXT_TO_TABLE) {
      return [[]];
    }
    return mapTableDataToExportFormat(selectedText.textToTable!);
  };

  const handleDownloadJsonFile = () => {
    const selectedText = texts[selectedTextIndex];
    if (!selectedText) {
      return;
    }
    if (model === UIModel.RELATION_EXTRACTION) {
      downloadJSONFile(selectedText.relationExtraction!.relations);
      return;
    }
    if (model === UIModel.NAMED_ENTITY_RECOGNITION) {
      downloadJSONFile(selectedText.namedEntityRecognition!.data);
      return;
    }
    if (model === UIModel.TEXT_CLASSIFICATION) {
      downloadJSONFile(selectedText.textClassification!.data);
      return;
    }
    if (model === UIModel.TEXT_TO_TABLE) {
      downloadJSONFile(selectedText.textToTable!.data);
      return;
    }
  };

  const handleClear = () => {
    setTexts((texts) =>
      texts.map((text, index) => {
        if (index === selectedTextIndex) {
          return getEmptyText([], model);
        }
        return text;
      })
    );
    handleCustomTextsChange(
      customTexts.map((customText) => {
        if (customText.model === model) {
          return getEmptyCustomText(model);
        }
        return customText;
      })
    );
  };

  const selectedText = texts[selectedTextIndex];

  if (!selectedText) {
    return null;
  }

  const renderRelations = model === UIModel.RELATION_EXTRACTION;
  const renderClasses = model === UIModel.TEXT_CLASSIFICATION;
  const renderTable = model === UIModel.TEXT_TO_TABLE;

  const playAnimation =
    selectedText.status === TextStatus.PROCESSING_PENDING ||
    (selectedText.status === TextStatus.EMPTY &&
      textToTableProcessDetails[selectedText.title]?.loading);
  return (
    <StyledProcessor>
      <InteractiveSpace>
        <ProcessorComponentWrapper
          $renderRelations={renderRelations}
          $renderClasses={renderClasses}
          $renderTable={renderTable}
        >
          <ProcessorComponent
            model={model}
            texts={texts}
            selectedText={selectedText}
            selectedTextIndex={selectedTextIndex}
            handleTextClick={handleTextClick}
            handleEditTextClick={handleEditTextClick}
            handleFocusTextClick={handleFocusTextClick}
            handleSubmitTextClick={handleSubmitTextClick}
            handleTextContentChange={handleTextContentChange}
            handleToggleHoveredRelation={handleToggleHoveredRelation}
            handleChangeNamedEntityRecognitionLabels={
              handleChangeNamedEntityRecognitionLabels
            }
            uiErrors={uiErrors}
            handleRemoveLink={handleRemoveLink}
            onFilesAccepted={handleAcceptedFiles}
            handleRemoveFile={handleRemoveFile}
            handleDownloadJsonFile={handleDownloadJsonFile}
            playAnimation={playAnimation}
            handleProcess={handleProcessTextClick}
            handleClear={handleClear}
          />
        </ProcessorComponentWrapper>
        {renderRelations && (
          <RelationsComponentWrapper>
            <Relations
              selectedText={selectedText}
              handleToggleSelectedRelation={handleToggleSelectedRelation}
              handleToggleActiveRelation={handleToggleActiveRelation}
              handleToggleHoveredRelation={handleToggleHoveredRelation}
              handleDownloadJsonFile={handleDownloadJsonFile}
            />
          </RelationsComponentWrapper>
        )}
        {renderClasses && (
          <ClassesComponentWrapper>
            <Classes
              selectedText={selectedText}
              handleResetLabels={handleResetTextClassificationLabels}
              handleRemoveLabel={handleRemoveTextClassificationLabel}
              handleUpdateLabel={handleUpdateTextClassificationLabel}
              handleChangePendingLabels={
                handleChangePendingTextClassificationLabels
              }
              handleRemovePendingLabel={
                handleRemovePendingTextClassificationLabel
              }
              handleUpdatePendingLabel={
                handleUpdatePendingTextClassificationLabel
              }
              uiErrors={uiErrors}
              handleDownloadJsonFile={handleDownloadJsonFile}
            />
          </ClassesComponentWrapper>
        )}
        {renderTable && (
          <TableComponentWrapper>
            <Table
              selectedText={selectedText}
              handleAddColumn={handleAddTextToTableColumn}
              handleDeleteColumn={handleDeleteTextToTableColumn}
              handleUpdateColumn={handleUpdateTextToTableColumn}
              handleRowClick={handleTextToTableRowClick}
              handleColumnClick={handleTextToTableColumnClick}
              handleCellClick={handleTextToTableCellClick}
              uiErrors={uiErrors}
              handleDownloadJsonFile={handleDownloadJsonFile}
              prepareTableTextForCSV={prepareTableTextForCSV}
              prepareTableTextForCopy={prepareTableTextForCopy}
            />
          </TableComponentWrapper>
        )}
      </InteractiveSpace>
      {model === UIModel.NAMED_ENTITY_RECOGNITION && (
        <SubmitWrapper>
          <Submit
            playAnimation={playAnimation}
            handleProcess={handleProcessTextClick}
          />
        </SubmitWrapper>
      )}
    </StyledProcessor>
  );
};

export default Processor;
