import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTheme } from 'styled-components';

import { ButtonType } from '@components/Button/types';
import Dropdown from '@components/Dropdown';
import { EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK } from '@components/Dropdown/defaults';
import { IconType } from '@components/Icon/types';
import { UIModel } from '@store/user/types';

import { COPY_FORMAT } from './default';
import {
  StyledExport,
  StyledDefaultExportButton,
  StyledControlIcon,
  StyledControlButton,
  ControlText,
  getDropdownContentStyle,
  StyledJSONLink,
  StyledCSVLink,
  getDropdownOptionStyle,
  getDropdownOptionHoverStyle,
} from './styled';

interface Props {
  model: UIModel;
  handleDownloadJsonFile: VoidFunction;
  prepareTableTextForCSV?: () => string[][];
  prepareTableTextForCopy?: () => string;
}

const Export: FC<Props> = ({
  model,
  handleDownloadJsonFile,
  prepareTableTextForCSV,
  prepareTableTextForCopy,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const onDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const onDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <StyledExport ref={ref}>
      {model === UIModel.TEXT_TO_TABLE ? (
        <Dropdown
          onOpen={onDropdownOpen}
          onClose={onDropdownClose}
          control={
            <StyledControlButton
              type={ButtonType.QUATERNARY}
              to="#"
              $open={dropdownOpen}
            >
              <ControlText>{t('components.export.placeholder')}</ControlText>
              <StyledControlIcon type={IconType.CHEVRON_DOWN} large />
            </StyledControlButton>
          }
          contentStyle={getDropdownContentStyle(
            theme,
            ref.current?.getBoundingClientRect().width
          )}
          options={[
            {
              label: (
                <CopyToClipboard
                  options={{ format: COPY_FORMAT }}
                  text={prepareTableTextForCopy?.() || ''}
                >
                  <span>{t('components.export.copy')}</span>
                </CopyToClipboard>
              ),
            },
            {
              label: (
                <StyledJSONLink to="#" onClick={handleDownloadJsonFile}>
                  {t('components.export.json')}
                </StyledJSONLink>
              ),
            },
            {
              label: (
                <StyledCSVLink
                  data={prepareTableTextForCSV?.() || [[]]}
                  filename={new Date().getTime().toString()}
                  className={EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK}
                >
                  {t('components.export.csv')}
                </StyledCSVLink>
              ),
            },
          ]}
          optionStyle={{
            ...getDropdownOptionStyle(theme),
            hover: getDropdownOptionHoverStyle(theme),
          }}
        />
      ) : (
        <StyledDefaultExportButton
          to="#"
          type={ButtonType.QUATERNARY}
          onClick={handleDownloadJsonFile}
        >
          {t('components.export.json')}
        </StyledDefaultExportButton>
      )}
    </StyledExport>
  );
};

export default Export;
