import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

interface HookOptions {
  excludeClassName: string;
}

export default <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  options?: HookOptions
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      const target = event?.target as HTMLElement;
      if (
        !el ||
        el.contains(event?.target as HTMLElement) ||
        (options?.excludeClassName &&
          (target.classList.contains(options.excludeClassName) ||
            target.parentElement?.classList.contains(options.excludeClassName)))
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
