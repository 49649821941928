import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { RelationState } from '@components/Radio/types';
import { RelationExtractionData } from '@store/user/types';

import {
  StyledItem,
  RelationWrapper,
  StyledRadio,
  CommonContent,
  RelationContent,
  Entity,
  Text,
  Type,
  RelationWord,
} from './styled';
import { EntityPosition } from './types';
import { mapEntityTypeToTitleMessageId } from './utils';

interface Props {
  relation: RelationExtractionData;
  isActive: boolean;
  isSelected: boolean;
  handleToggleSelectedRelation: VoidFunction;
  handleToggleActiveRelation: VoidFunction;
  handleToggleHoveredRelation: VoidFunction;
}

const Item: FC<Props> = ({
  relation,
  isActive,
  isSelected,
  handleToggleSelectedRelation,
  handleToggleActiveRelation,
  handleToggleHoveredRelation,
}) => {
  const { t } = useTranslation();

  const { source, relation: relationText, target } = relation;

  const sourceEntityTypeTitle = t(mapEntityTypeToTitleMessageId[source.entity]);
  const targetEntityTypeTitle = t(mapEntityTypeToTitleMessageId[target.entity]);

  const isInactive = !isActive && !isSelected;
  return (
    <StyledItem
      $inactive={isInactive}
      $selected={isSelected}
      onClick={isActive ? handleToggleSelectedRelation : undefined}
      onMouseEnter={isActive ? handleToggleHoveredRelation : undefined}
      onMouseLeave={isActive ? handleToggleHoveredRelation : undefined}
    >
      <RelationWrapper>
        <StyledRadio
          state={
            isSelected
              ? RelationState.SELECTED
              : isActive
              ? RelationState.ACTIVE
              : RelationState.INACTIVE
          }
          onClick={
            isSelected
              ? undefined
              : (e: MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  handleToggleActiveRelation();
                }
          }
        />
        <CommonContent>
          <RelationContent>
            <Entity $position={EntityPosition.SOURCE} $selected={isSelected}>
              <Text>{source.word}</Text>
              {sourceEntityTypeTitle && <Type>{sourceEntityTypeTitle}</Type>}
            </Entity>
            <RelationWord $inactive={isInactive}>
              <Text>{relationText}</Text>
            </RelationWord>
            <Entity $position={EntityPosition.TARGET} $selected={isSelected}>
              <Text>{target.word}</Text>
              {targetEntityTypeTitle && <Type>{targetEntityTypeTitle}</Type>}
            </Entity>
          </RelationContent>
        </CommonContent>
      </RelationWrapper>
    </StyledItem>
  );
};

export default Item;
