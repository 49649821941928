import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const classesTheme: DefaultTheme['classes'] = {
  titleColor: Color.SILVER_CHALICE,
  headerHeight: 7.8,
  maxContentHeight: 45,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  borderColor: Color.TUNDORA,
  borderStyle: 'solid',
  clickedBorderColor: Color.CORNFLOWER_BLUE,
  clickedBorderStyle: 'dashed',
  errorBorderColor: Color.FLUSH_MAHOGANY,
  errorBorderStyle: 'dashed',
  tooltipIconColor: Color.SILVER_CHALICE,
  legendColor: Color.SILVER_CHALICE,
  itemColor: Color.PIZZA,
  pendingItemColor: Color.SILVER_CHALICE,
  itemEditableColor: Color.CORNFLOWER_BLUE_50,
  actionLinkColor: Color.CORNFLOWER_BLUE,
  noDataColor: Color.SILVER_CHALICE,
  hover: {
    backgroundColor: Color.MINE_SHAFT,
  },
};
