export const FONT_SIZE_REM = 1.4;

export const BAR_CHART_MARGIN_TOP = 40;
export const BAR_CHART_MARGIN_LEFT = 30;
export const BAR_CHART_MARGIN_RIGHT = 30;
export const BAR_CHART_MARGIN_BOTTOM = 40;

export const BAR_BORDER_RADIUS = 8;
export const BAR_CATEGORY_GAP_PERCENTS = 25;

export const X_AXIS_TICK_MARGIN = 15;

export const Y_AXIS_TICK_MARGIN = 15;
export const Y_AXIS_TICK_COUNT = 6;
