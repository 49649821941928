import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Legend from '@components/Classes/Legend';
import { MultiCreatableValue } from '@components/Creatable/types';
import { TextClassificationData } from '@store/user/types';

import { DEFAULT_SCORE } from './defaults';
import Item from './Item';
import { StyledContent, NoData } from './styled';
import PendingItem from './PendingItem';
import { sortLabelsByScore } from './utils';

interface Props {
  labels: MultiCreatableValue;
  data: TextClassificationData;
  pendingLabels: MultiCreatableValue;
  handleRemoveLabel: (value: string) => void;
  handleUpdateLabel: (newLabel: string, value: string) => void;
  handleRemovePendingLabel: (value: string) => void;
  handleUpdatePendingLabel: (newLabel: string, value: string) => void;
  isEmpty: boolean;
}

const Content: FC<Props> = ({
  labels,
  data,
  pendingLabels,
  handleRemoveLabel,
  handleUpdateLabel,
  handleRemovePendingLabel,
  handleUpdatePendingLabel,
  isEmpty,
}) => {
  const { t } = useTranslation();

  const sortedLabels = sortLabelsByScore(labels, data);
  return (
    <StyledContent>
      {isEmpty && <NoData>{t('components.classes.content.noData')}</NoData>}
      {sortedLabels.map(({ label, value, score }, index) => (
        <Item
          key={value}
          number={index + 1}
          label={label}
          score={score === DEFAULT_SCORE ? '-' : `${score}%`}
          handleRemove={() => {
            handleRemoveLabel(value);
          }}
          handleUpdate={(newLabel: string) => {
            handleUpdateLabel(newLabel, value);
          }}
        />
      ))}
      {pendingLabels.length > 0 && (
        <>
          <Legend leftSection={t('components.classes.content.legend.new')} />
          {pendingLabels.map(({ label, value }, index) => (
            <PendingItem
              key={value}
              number={index + 1}
              label={label}
              handleRemove={() => {
                handleRemovePendingLabel(value);
              }}
              handleUpdate={(newLabel: string) => {
                handleUpdatePendingLabel(newLabel, value);
              }}
            />
          ))}
        </>
      )}
    </StyledContent>
  );
};

export default Content;
