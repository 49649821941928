import { StyledToggle, ToggleElement } from './styled';
import { Mode } from './types';

interface Props<T> {
  active: T;
  onChange: (value: T) => void;
  items: Mode<T>[];
}

const Toggle = <T,>({ active, onChange, items }: Props<T>) => (
  <StyledToggle>
    {items.map(({ title, type }, index) => (
      <ToggleElement
        key={index}
        $active={active === type}
        onClick={() => {
          onChange(type);
        }}
      >
        {title}
      </ToggleElement>
    ))}
  </StyledToggle>
);

export default Toggle;
