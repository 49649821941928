import styled from 'styled-components';

import { RelationState } from './types';

export const StyledRadio = styled.div<{ $state: RelationState }>`
  cursor: pointer;
  min-width: ${(props) => props.theme.radio.width}rem;
  min-height: ${(props) => props.theme.radio.height}rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.radio[props.$state].backgroundColor};
  border: 1px solid ${(props) => props.theme.radio[props.$state].borderColor};
`;
