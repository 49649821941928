import styled from 'styled-components';

import IconButton from '@components/IconButton';
import { Color } from '@theme/colors';

export const StyledDropdown = styled.div`
  position: relative;
`;

export const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: ${(props) =>
      props.theme.dropdown.icon.hover.backgroundColor};
  }
`;

export const Content = styled.div`
  position: absolute;
  top: calc(-100% - 4rem);
  background-color: ${(props) => props.theme.dropdown.backgroundColor};
  border-radius: 0.4rem;
  border: 1px solid ${(props) => props.theme.dropdown.borderColor};
  min-width: 12.5rem;
`;

export const Option = styled.div<{ $color?: Color; $hoverColor?: Color }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  color: ${(props) => props.$color};

  &:hover {
    background-color: ${(props) =>
      props.theme.dropdown.option.hover.backgroundColor};
    color: ${(props) => props.$hoverColor};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 1rem;
`;

export const Label = styled.div`
  font-size: 1.6rem;
`;
