import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const toggleTheme: DefaultTheme['toggle'] = {
  color: Color.SILVER_CHALICE,
  borderColor: Color.TUNDORA,
  active: {
    color: Color.WHITE,
    backgroundColor: Color.SHIP_COVE,
  },
};
