import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@components/Select';
import { SingleSelectValue } from '@components/Select/types';

import { StyledHeader, SelectWrapper, StyledButton } from './styled';

interface Props {
  handleRelationChange: (value: SingleSelectValue) => void;
  handleDescriptionChange: (value: SingleSelectValue) => void;
  handleAddClick: VoidFunction;
}

const Header: FC<Props> = ({
  handleRelationChange,
  handleDescriptionChange,
  handleAddClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <SelectWrapper>
        <Select
          label={t('components.customRelations.header.relation.label')}
          placeholder={t(
            'components.customRelations.header.relation.placeholder'
          )}
          value={undefined}
          options={[]}
          handleChange={handleRelationChange}
          info
        />
      </SelectWrapper>
      <SelectWrapper>
        <Select
          label={t('components.customRelations.header.description.label')}
          placeholder={t(
            'components.customRelations.header.description.placeholder'
          )}
          value={undefined}
          options={[]}
          handleChange={handleDescriptionChange}
          info
        />
      </SelectWrapper>
      <StyledButton to="#" onClick={handleAddClick} disabled>
        {t('components.customRelations.header.button')}
      </StyledButton>
    </StyledHeader>
  );
};

export default Header;
