import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSProperties } from 'styled-components';
import debounce from 'lodash/debounce';

import Portal from '@components/Portal';

import {
  DEFAULT_MAX_WIDTH,
  DEFAULT_CLASS_NAME,
  DEFAULT_TOOLTIP_ROOT_ID,
  DEFAULT_MARGIN,
} from './defaults';
import { StyledTooltip, TooltipElement } from './styled';
import { TooltipCoordinates, TooltipPlacement } from './types';

interface Props {
  label: ReactNode;
  visible?: boolean;
  className?: string;
  elementStyle?: CSSProperties;
  showArrow?: boolean;
  delay?: number;
  style?: CSSProperties;
  alwaysShow?: boolean;
  maxWidth?: number;
  placement?: TooltipPlacement;
}

const Tooltip: FC<PropsWithChildren<Props>> = ({
  className,
  visible = true,
  label,
  elementStyle,
  showArrow = true,
  delay = 0,
  style,
  alwaysShow,
  maxWidth = DEFAULT_MAX_WIDTH,
  placement = TooltipPlacement.BOTTOM,
  children,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [coordinates, setCoordinates] = useState<TooltipCoordinates | null>(
    null
  );

  const [addTooltipElementMouseLeave, setAddTooltipElementMouseLeave] =
    useState(false);

  useEffect(() => {
    if (!alwaysShow) {
      setCoordinates(null);
      return;
    }
    if (wrapperRef.current) {
      setCoordinates(getCoordinates(wrapperRef.current));
    }
  }, [alwaysShow]);

  const handleMouseOver = debounce(() => {
    if (!coordinates && wrapperRef.current) {
      setCoordinates(getCoordinates(wrapperRef.current));
    }
  }, delay);

  const handleMouseLeave = (e?: MouseEvent<HTMLDivElement>) => {
    if (e) {
      const { relatedTarget } = e;
      const relatedTargetClass = (relatedTarget as any).className;
      if (relatedTargetClass.includes(DEFAULT_CLASS_NAME)) {
        setAddTooltipElementMouseLeave(true);
        return;
      }
    }
    handleMouseOver.cancel();
    setCoordinates(null);
  };

  const handleTooltipElementMouseLeave = () => {
    handleMouseOver.cancel();
    setCoordinates(null);
    setAddTooltipElementMouseLeave(false);
  };

  const getCoordinates = (el: HTMLDivElement): TooltipCoordinates => {
    const rect = el.getBoundingClientRect();
    if (placement === TooltipPlacement.LEFT) {
      return {
        right: window.innerWidth - rect.x,
        top: rect.y + window.scrollY,
        arrow: {
          top: rect.height / 2,
        },
      };
    }
    return {
      left: rect.x,
      top: rect.y + rect.height + window.scrollY,
      arrow: {
        left: rect.width / 2,
      },
    };
  };

  if (!visible) {
    return <>{children}</>;
  }

  return (
    <StyledTooltip
      ref={wrapperRef}
      className={className}
      style={style}
      onMouseOver={alwaysShow ? undefined : handleMouseOver}
      onMouseLeave={alwaysShow ? undefined : handleMouseLeave}
    >
      {children}
      {!!coordinates && (
        <Portal root={DEFAULT_TOOLTIP_ROOT_ID}>
          <TooltipElement
            className={DEFAULT_CLASS_NAME}
            ref={elementRef}
            style={{
              right: coordinates.right,
              left: coordinates.left,
              top: coordinates.top,
              bottom: coordinates.bottom,
              ...(placement === TooltipPlacement.BOTTOM && {
                marginTop: `${DEFAULT_MARGIN}rem`,
              }),
              ...(placement === TooltipPlacement.LEFT && {
                marginRight: `${DEFAULT_MARGIN}rem`,
              }),
              ...elementStyle,
            }}
            $showArrow={showArrow}
            $arrowCoordinates={coordinates.arrow}
            $maxWidth={maxWidth}
            onMouseLeave={
              addTooltipElementMouseLeave
                ? handleTooltipElementMouseLeave
                : undefined
            }
          >
            {label}
          </TooltipElement>
        </Portal>
      )}
    </StyledTooltip>
  );
};

export default Tooltip;
