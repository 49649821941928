import { useEffect, useRef, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthType } from '@containers/Model/types';
import ResetPasswordComponent from '@components/ResetPassword';
import { HOME_ROUTE } from '@routes/list';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { resetPassword, resetPasswordClear } from '@store/auth/actions';
import {
  getResetPasswordErrorSelector,
  getResetPasswordLoadingSelector,
  getResetPasswordSuccessSelector,
} from '@store/auth/selectors';
import { ResetPasswordCredentials } from '@store/auth/types';

import { StyledResetPassword, Title } from './styled';

const ResetPassword = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const token = searchParams.get('token');

  const dispatch = useAppDispatch();

  const loading = useAppSelector(getResetPasswordLoadingSelector);
  const error = useAppSelector(getResetPasswordErrorSelector);
  const success = useAppSelector(getResetPasswordSuccessSelector);

  const [redirect, setRedirect] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      dispatch(resetPasswordClear());
    };
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setRedirect(true);
      }, 1000);
    }
  }, [success]);

  if (!id || !token) {
    return <Navigate to={HOME_ROUTE} />;
  }

  const handleSubmit = (values: Pick<ResetPasswordCredentials, 'password'>) => {
    dispatch(
      resetPassword({
        userId: id,
        resetToken: token,
        ...values,
      })
    );
  };

  if (redirect) {
    return <Navigate to={`${HOME_ROUTE}?authType=${AuthType.LOGIN}`} />;
  }

  return (
    <StyledResetPassword>
      <Title>{t('components.resetPassword.title')}</Title>
      <ResetPasswordComponent
        loading={loading}
        error={error}
        inputRef={inputRef}
        handleSubmit={handleSubmit}
      />
    </StyledResetPassword>
  );
};

export default ResetPassword;
