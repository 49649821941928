import { ChangeEvent, FC, FocusEventHandler, useEffect, useRef } from 'react';

import { TAB_CLASS_NAME } from '@components/Tabs/defaults';

import { StyledTextInput, Textarea } from './styled';

interface Props {
  value: string[];
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: VoidFunction;
  onFocus: VoidFunction;
  placeholder?: string;
}

const TextInput: FC<Props> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
}) => {
  const contentRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    contentRef.current?.focus();
  }, []);

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.relatedTarget?.classList.contains(TAB_CLASS_NAME)) {
      onBlur();
    }
  };

  return (
    <StyledTextInput>
      <Textarea
        ref={contentRef}
        value={value.join('')}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        placeholder={placeholder}
      />
    </StyledTextInput>
  );
};

export default TextInput;
