import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '@components/Logo';
import UserMenu from '@components/UserMenu';
import { CurrentUser } from '@store/auth/types';
import { UIModel } from '@store/user/types';

import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import {
  StyledHeader,
  LogoWrapper,
  Brand,
  MenuWrapper,
  DesktopMenuWrapper,
  MobileMenuWrapper,
  UserMenuWrapper,
} from './styled';
import { MenuItem } from './types';

interface Props {
  model: UIModel;
  user: CurrentUser | null;
  handleLogout: VoidFunction;
}

const Header: FC<Props> = ({ model, user, handleLogout }) => {
  const { t } = useTranslation();

  const menuItems: MenuItem[] = t('components.header.menu', {
    returnObjects: true,
  });

  return (
    <StyledHeader>
      <LogoWrapper to="https://knowledgator.com" external>
        <Logo />
        <Brand>{t('components.header.brand')}</Brand>
      </LogoWrapper>
      <MenuWrapper>
        <DesktopMenuWrapper>
          <DesktopMenu model={model} items={menuItems} />
        </DesktopMenuWrapper>
        <MobileMenuWrapper>
          <MobileMenu model={model} items={menuItems} />
        </MobileMenuWrapper>
        {user && (
          <UserMenuWrapper>
            <UserMenu
              nickname={user.nickname}
              email={user.email}
              handleLogout={handleLogout}
            />
          </UserMenuWrapper>
        )}
      </MenuWrapper>
    </StyledHeader>
  );
};

export default Header;
