import styled from 'styled-components';

export const StyledPendingItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Details = styled.div<{ $pending?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 2.5rem;
  color: ${(props) => props.theme.classes.pendingItemColor};
  border-top: 1px dashed ${(props) => props.theme.classes.borderColor};
  border-right: 1px dashed ${(props) => props.theme.classes.borderColor};
  border-bottom: 1px dashed ${(props) => props.theme.classes.borderColor};
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Number = styled.p`
  margin-right: 0.5rem;
`;

export const LabelParent = styled.div`
  margin-right: 5rem;

  &:focus-within {
    width: 100%;
  }
`;

export const Label = styled.p`
  outline: none;

  &:focus {
    background-color: ${(props) => props.theme.classes.itemEditableColor};
  }
`;

export const Action = styled.div`
  cursor: pointer;
`;
