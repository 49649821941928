import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonType } from '@components/Button/types';
import Button from '@components/Button';
import { mapModelToRapidApiLink } from '@components/Explore/utils';
import { MenuItem } from '@components/Header/types';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useToggle from '@hooks/useToggle';
import { UIModel } from '@store/user/types';

import Burger from './Burger';
import { StyledMobileMenu, Content, MenuElement } from './styled';

interface Props {
  model: UIModel;
  items: MenuItem[];
}

const MobileMenu: FC<Props> = ({ model, items }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [opened, toggleOpeend, handleUnclick] = useToggle(false);

  const { t } = useTranslation();

  useOnClickOutside(elementRef, handleUnclick);

  return (
    <StyledMobileMenu ref={elementRef}>
      <Burger opened={opened} onClick={toggleOpeend} />
      {opened && (
        <Content>
          {items.map((item, index) => (
            <MenuElement key={index} to={item.href} external>
              {item.name}
            </MenuElement>
          ))}
          <Button
            external
            type={ButtonType.TERTIARY}
            to={mapModelToRapidApiLink[model]}
          >
            {t('components.explore.api')}
          </Button>
        </Content>
      )}
    </StyledMobileMenu>
  );
};

export default MobileMenu;
