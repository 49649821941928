import { GroupBase, StylesConfig } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

import IconButton from '@components/IconButton';
import Icon from '@components/Icon';

import { CreatableOption } from './types';

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.creatable.multiValueRemoveBackgroundColor};
  border-radius: 0.3rem;
  margin-right: 0.5rem;

  &:hover {
    background-color: ${(props) =>
      props.theme.creatable.multiValueRemoveHoverBackgroundColor};
  }
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    stroke: ${(props) => props.theme.creatable.multiValueRemoveHoverIconColor};
  }
`;

export const getCreatableStyles = (
  theme: DefaultTheme,
  focused: boolean,
  empty: boolean,
  uiError: boolean
): StylesConfig<CreatableOption, true, GroupBase<CreatableOption>> => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    overflow: 'auto',
    borderRadius: '0.8rem',
    backgroundColor: theme.creatable.backgroundColor,
    boxShadow: 'none',
    minHeight: `${theme.creatable.minHeight}rem`,
    ...(uiError
      ? {
          borderColor: theme.creatable.errorBorderColor,
          borderStyle: theme.creatable.errorBorderStyle,
          ':hover': {
            borderColor: theme.creatable.errorBorderColor,
          },
        }
      : focused
      ? {
          borderColor: theme.creatable.focusBorderColor,
          borderStyle: theme.creatable.focusBorderStyle,
          ':hover': {
            borderColor: theme.creatable.focusBorderColor,
          },
        }
      : {
          maxHeight: `${theme.creatable.minHeight}rem`,
          overflow: 'hidden',
          borderColor: theme.creatable.borderColor,
          ':hover': {
            borderColor: theme.creatable.borderColor,
          },
        }),
  }),
  input: (provided) => ({
    ...provided,
    color: theme.creatable.color,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'flex',
    ...(!empty && {
      display: 'inline-block',
      paddingTop: '0.35rem',
    }),
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: theme.creatable.color,
    ':hover': {
      color: theme.creatable.color,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.5rem',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: theme.creatable.multiValueBackgroundColor,
    borderRadius: '0.4rem',
    alignItems: 'center',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: theme.creatable.color,
    paddingLeft: '0.5rem',
    padding: '0.5rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
});
