import styled from 'styled-components';

import { DEFAULT_STEP_SIZE } from '@containers/Tutorial/defaults';

export const StyledStep = styled.div<{ $size?: number }>`
  width: ${(props) =>
    props.$size ? `${props.$size}rem` : `${DEFAULT_STEP_SIZE}rem`};
  max-width: 100%;
`;

export const AnimationWrapper = styled.div`
  margin-bottom: 4rem;
`;

export const Description = styled.p`
  padding-top: 4rem;
  border-top: 1px solid
    ${(props) => props.theme.stepper.step.descriptionBorderColor};
  font-size: 2.1rem;
  line-height: 3.8rem;

  b {
    color: ${(props) => props.theme.stepper.step.descriptionModelColor};
  }
`;

export const Details = styled.p`
  font-size: 2.1rem;
  line-height: 3.8rem;
  color: ${(props) => props.theme.stepper.step.descriptionDetailsColor};
`;
