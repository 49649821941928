import { DefaultTheme } from 'styled-components';

import { RelationState } from '@components/Radio/types';
import { Color } from '@theme/colors';

export const radioTheme: DefaultTheme['radio'] = {
  width: 1.8,
  height: 1.8,
  [RelationState.INACTIVE]: {
    backgroundColor: Color.TRANSPARENT,
    borderColor: Color.SILVER_CHALICE,
  },
  [RelationState.ACTIVE]: {
    backgroundColor: Color.SILVER_CHALICE,
    borderColor: Color.SILVER_CHALICE,
  },
  [RelationState.SELECTED]: {
    backgroundColor: Color.CORNFLOWER_BLUE,
    borderColor: Color.CORNFLOWER_BLUE,
  },
  [RelationState.HOVERED]: {
    backgroundColor: Color.CORNFLOWER_BLUE,
    borderColor: Color.CORNFLOWER_BLUE,
  },
};
