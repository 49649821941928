import styled, { css } from 'styled-components';

export const StyledHighlighter = styled.div`
  width: 100%;
  padding: 2rem 5rem;
  margin: 2rem 2.5rem 2rem 0;
  height: ${(props) =>
    props.theme.highlighter.contentHeight +
    props.theme.processor.footerHeight}rem;
  overflow-y: auto;
  line-height: 2.8;
  position: relative;
  z-index: 1;
  white-space: pre-wrap;
  font-size: 2.1rem;
`;

export const StyledModelSentence = styled.div<{ $hidden?: boolean }>`
  ${(props) =>
    props.$hidden &&
    css`
      visibility: hidden;
    `}
`;
