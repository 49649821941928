export const TITLE_VERSION = 1;
export const TITLE_VERSION_STEP = 1;

export const NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID =
  'components.table.newColumn';
export const DEFAULT_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID =
  'components.table.defaultColumn';

export const TEXT_TO_TABLE_COLUMN_NAME_MAX_LENGTH = 300;

/* 
  - Starts from "http" or "https"
  - Does not contait any whitespace
*/
export const URL_LIKE_REGEX = /^(http|https):\/\/\S*$/;
