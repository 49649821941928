import { FC, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { LinkProps } from 'react-router-dom';

import { Props as IconButtonProps } from '@components/IconButton/IconButton';

import { StyledButton, StyledExternalButton } from './styled';
import { ButtonType } from './types';

export type Props = LinkProps & {
  type?: ButtonType;
  external?: boolean;
  leftIcon?: ReactElement<IconButtonProps>;
  disabled?: boolean;
};

const Button: FC<PropsWithChildren<Props>> = ({
  type = ButtonType.PRIMARY,
  external,
  leftIcon,
  children,
  to,
  onClick,
  disabled,
  ...linkProps
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      return;
    }
    if (!onClick) {
      return;
    }
    e.preventDefault();
    onClick(e);
  };

  return external ? (
    <StyledExternalButton
      {...linkProps}
      $type={type}
      $disabled={disabled}
      href={to.toString()}
      target="_blank"
    >
      {leftIcon}
      {children}
    </StyledExternalButton>
  ) : (
    <StyledButton
      {...linkProps}
      $type={type}
      $disabled={disabled}
      to={to}
      onClick={handleClick}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
