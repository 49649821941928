import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';

import { SampleText, TextStatus, TextType } from '@containers/Processor/types';
import { MultiCreatableValue } from '@components/Creatable/types';
import Select from '@components/Select';
import {
  SelectOption,
  SelectOptionsOrGroups,
  SingleSelectValue,
} from '@components/Select/types';
import Submit from '@components/Processor/Submit';
import { UIModel } from '@store/user/types';
import { UIError } from '@store/ui/types';

import Entities from './Entities';
import {
  StyledHeader,
  ActionsWrapper,
  ClearAll,
  EntitiesWrapper,
  Topic,
  Title,
} from './styled';
import { mapTextsToSelectOptions, mapToMultiCreatableValue } from './utils';

interface Props {
  model: UIModel;
  texts: SampleText[];
  textStatus: TextStatus;
  selectedTextIndex: number;
  handleTextClick: (index: number) => void;
  handleChangeNamedEntityRecognitionLabels: (
    value: MultiCreatableValue
  ) => void;
  uiErrors: UIError[];
  isDragActive: boolean;
  playAnimation: boolean;
  handleProcess: VoidFunction;
  handleClear: VoidFunction;
}

const Header: FC<Props> = ({
  model,
  texts,
  textStatus,
  selectedTextIndex,
  handleTextClick,
  handleChangeNamedEntityRecognitionLabels,
  uiErrors,
  isDragActive,
  playAnimation,
  handleProcess,
  handleClear,
}) => {
  const [value, setValue] = useState<SingleSelectValue>();
  const [options, setOptions] = useState<SelectOptionsOrGroups>([]);

  useEffect(() => {
    const options = mapTextsToSelectOptions(texts);
    const flatOptions = (options as GroupBase<SelectOption>[])
      .map((option) => option.options)
      .flat();
    const value = flatOptions.find(
      (option) => JSON.parse(option.value).index === selectedTextIndex
    );
    setValue(value);
    setOptions(options);
  }, [texts.length, selectedTextIndex]);

  const { t } = useTranslation();

  const handleChange = (option: SingleSelectValue) => {
    if (option) {
      handleTextClick(JSON.parse(option.value).index);
    }
  };

  return (
    <StyledHeader
      $textStatus={isDragActive ? TextStatus.PENDING_VALID : textStatus}
      $uiError={
        uiErrors.includes(UIError.GENERAL_TEXT_EMPTY) ||
        uiErrors.includes(UIError.FILE_INPUT_ERROR) ||
        uiErrors.includes(UIError.INVALID_URL_ERROR)
      }
    >
      {model === UIModel.NAMED_ENTITY_RECOGNITION && (
        <EntitiesWrapper>
          <Entities
            key={selectedTextIndex}
            initialValue={mapToMultiCreatableValue(
              texts[selectedTextIndex].namedEntityRecognition!.initialLabels
            )}
            value={mapToMultiCreatableValue(
              texts[selectedTextIndex].namedEntityRecognition!.labels
            )}
            handleChange={handleChangeNamedEntityRecognitionLabels}
            uiError={uiErrors.includes(
              UIError.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY
            )}
          />
        </EntitiesWrapper>
      )}
      <Topic>
        <Title>{t('components.processor.header.topic')}</Title>
        <Select
          placeholder={t('components.processor.header.placeholder')}
          options={options}
          value={value}
          handleChange={handleChange}
        />
      </Topic>
      {[
        UIModel.TEXT_TO_TABLE,
        UIModel.TEXT_CLASSIFICATION,
        UIModel.RELATION_EXTRACTION,
      ].includes(model) && (
        <ActionsWrapper>
          {texts[selectedTextIndex].type === TextType.CUSTOM && (
            <ClearAll to="#" onClick={handleClear}>
              {t('components.processor.header.clear')}
            </ClearAll>
          )}
          <Submit playAnimation={playAnimation} handleProcess={handleProcess} />
        </ActionsWrapper>
      )}
    </StyledHeader>
  );
};

export default Header;
