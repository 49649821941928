import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { ButtonType } from '@components/Button/types';
import { mapModelToRapidApiLink } from '@components/Explore/utils';
import { MenuItem } from '@components/Header/types';
import { UIModel } from '@store/user/types';

import { StyledDesktopMenu, MenuElement } from './styled';

interface Props {
  model: UIModel;
  items: MenuItem[];
}

const DesktopMenu: FC<Props> = ({ model, items }) => {
  const { t } = useTranslation();

  return (
    <StyledDesktopMenu>
      {items.map((item, index) => (
        <MenuElement key={index} to={item.href} external>
          {item.name}
        </MenuElement>
      ))}
      <Button
        external
        type={ButtonType.TERTIARY}
        to={mapModelToRapidApiLink[model]}
      >
        {t('components.explore.api')}
      </Button>
    </StyledDesktopMenu>
  );
};

export default DesktopMenu;
