export const BASE_URL = 'https://knowledgator.ai/api';

export const RELATION_EXTRACTION_RAPID_API_URL = 'https://rex7.p.rapidapi.com';
export const NAMED_ENTITY_RECOGNITION_RAPID_API_URL =
  'https://zero-shot-ner.p.rapidapi.com';
export const TEXT_CLASSIFICATION_RAPID_API_URL =
  'https://comprehend-it.p.rapidapi.com';
export const TEXT_TO_TABLE_RAPID_API_URL = 'https://text2table.p.rapidapi.com';

export const RELATION_EXTRACTION_RAPID_API_KEY =
  '61f807a322mshbde8088c5c8345bp1c94d8jsnea1ea26535c8';
export const NAMED_ENTITY_RECOGNITION_RAPID_API_KEY =
  '61f807a322mshbde8088c5c8345bp1c94d8jsnea1ea26535c8';
export const TEXT_CLASSIFICATION_RAPID_API_KEY =
  '61f807a322mshbde8088c5c8345bp1c94d8jsnea1ea26535c8';
export const TEXT_TO_TABLE_RAPID_API_KEY =
  '61f807a322mshbde8088c5c8345bp1c94d8jsnea1ea26535c8';

export const DEFAULT_TIMEOUT = 30 * 1000;
