import { Animation } from '@components/Stepper/Step/types';
import { UIModel } from '@store/user/types';

import NamedEntityRecognitionAnimation1 from './assets/named-entity-recognition/1.json';
import NamedEntityRecognitionAnimation2 from './assets/named-entity-recognition/2.json';
import NamedEntityRecognitionAnimation3 from './assets/named-entity-recognition/3.json';

import TextClassificationAnimation1 from './assets/text-classification/1.json';
import TextClassificationAnimation2 from './assets/text-classification/2.json';
import TextClassificationAnimation3 from './assets/text-classification/3.json';

import TextToTableAnimation1 from './assets/text-to-table/1.json';
import TextToTableAnimation2 from './assets/text-to-table/2.json';
import TextToTableAnimation3 from './assets/text-to-table/3.json';

import {
  DEFAULT_STEP_SIZE,
  LARGE_STEP_SIZE,
  SMALL_STEP_SIZE,
} from './defaults';

export const mapModelToStepsKey: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]: 'components.stepper.steps.relationExtraction',
  [UIModel.NAMED_ENTITY_RECOGNITION]:
    'components.stepper.steps.namedEntityRecognition',
  [UIModel.TEXT_CLASSIFICATION]: 'components.stepper.steps.textClassification',
  [UIModel.TEXT_TO_TABLE]: 'components.stepper.steps.textToTable',
};

export const mapModelToAnimations: Record<UIModel, Animation[]> = {
  [UIModel.RELATION_EXTRACTION]: [],
  [UIModel.NAMED_ENTITY_RECOGNITION]: [
    {
      json: NamedEntityRecognitionAnimation1,
      speedFactor: 1.5,
    },
    {
      json: NamedEntityRecognitionAnimation2,
      speedFactor: 1,
    },
    {
      json: NamedEntityRecognitionAnimation3,
      speedFactor: 0.75,
    },
  ],
  [UIModel.TEXT_CLASSIFICATION]: [
    {
      json: TextClassificationAnimation1,
      speedFactor: 1.5,
    },
    {
      json: TextClassificationAnimation2,
      speedFactor: 2.25,
    },
    {
      json: TextClassificationAnimation3,
      speedFactor: 1.5,
    },
  ],
  [UIModel.TEXT_TO_TABLE]: [
    {
      json: TextToTableAnimation1,
      speedFactor: 1.5,
    },
    {
      json: TextToTableAnimation2,
      speedFactor: 2.5,
    },
    {
      json: TextToTableAnimation3,
      speedFactor: 1.75,
    },
  ],
};

export const mapModelToStepSize: Record<UIModel, number> = {
  [UIModel.RELATION_EXTRACTION]: DEFAULT_STEP_SIZE,
  [UIModel.NAMED_ENTITY_RECOGNITION]: DEFAULT_STEP_SIZE,
  [UIModel.TEXT_CLASSIFICATION]: LARGE_STEP_SIZE,
  [UIModel.TEXT_TO_TABLE]: SMALL_STEP_SIZE,
};
