import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { mapModelToReadableName } from '@components/Header/utils';
import Playground from '@components/Playground';
import { IconButtonType } from '@components/IconButton/types';
import { IconType } from '@components/Icon/types';
import { UIModel } from '@store/user/types';

import { TAB_CLASS_NAME } from './defaults';
import {
  StyledTabs,
  TabsContent,
  TabsBlock,
  Title,
  TabsWrapper,
  Tab,
  TutorialWrapper,
  StyledIconButton,
  StyledIcon,
} from './styled';
import { mapModelToHref } from './utils';

interface Props {
  model: UIModel;
  highlightTutorial: boolean;
  handleOpenTutorial: VoidFunction;
}

const Tabs: FC<Props> = ({
  model: activeModel,
  highlightTutorial,
  handleOpenTutorial,
}) => {
  const { t } = useTranslation();

  return (
    <StyledTabs>
      <Playground>
        <TabsContent>
          <TabsBlock>
            <Title>{t('components.tabs.title')}</Title>
            <TabsWrapper>
              {[
                UIModel.TEXT_TO_TABLE,
                UIModel.TEXT_CLASSIFICATION,
                UIModel.NAMED_ENTITY_RECOGNITION,
              ].map((model, index) => (
                <Tab
                  to={mapModelToHref[model]}
                  key={index}
                  $active={activeModel === model}
                  className={TAB_CLASS_NAME}
                >
                  {t(mapModelToReadableName[model])}
                </Tab>
              ))}
            </TabsWrapper>
          </TabsBlock>
          <TutorialWrapper $highlight={highlightTutorial}>
            <StyledIconButton
              type={IconButtonType.PLAIN}
              onClick={handleOpenTutorial}
            >
              <StyledIcon
                type={IconType.HELP_CIRCLE}
                width={2.4}
                height={2.4}
                large
              />
            </StyledIconButton>
          </TutorialWrapper>
        </TabsContent>
      </Playground>
    </StyledTabs>
  );
};

export default Tabs;
