import { EntityType } from '@store/user/types';

export const mapEntityTypeToTitleMessageId: Record<EntityType, string> = {
  // Business
  [EntityType.PERSON]: 'entityTypes.business.person.title',
  [EntityType.NORP]: 'entityTypes.business.norp.title',
  [EntityType.FAC]: 'entityTypes.business.fac.title',
  [EntityType.ORG]: 'entityTypes.business.org.title',
  [EntityType.GPE]: 'entityTypes.business.gpe.title',
  [EntityType.LOC]: 'entityTypes.business.loc.title',
  [EntityType.PRODUCT]: 'entityTypes.business.product.title',
  [EntityType.EVENT]: 'entityTypes.business.event.title',
  [EntityType.WORK_OF_ART]: 'entityTypes.business.workOfArt.title',
  [EntityType.LAW]: 'entityTypes.business.law.title',
  [EntityType.LANGUAGE]: 'entityTypes.business.language.title',
  [EntityType.DATE]: 'entityTypes.business.date.title',
  [EntityType.TIME]: 'entityTypes.business.time.title',
  [EntityType.PERCENT]: 'entityTypes.business.percent.title',
  [EntityType.MONEY]: 'entityTypes.business.money.title',
  [EntityType.QUANTITY]: 'entityTypes.business.quantity.title',
  [EntityType.ORDINAL]: 'entityTypes.business.ordinal.title',
  [EntityType.CARDINAL]: 'entityTypes.business.cardinal.title',
  [EntityType.PRON]: 'entityTypes.business.pron.title',
  [EntityType.PROPN]: 'entityTypes.business.propn.title',
  [EntityType.NOUN]: 'entityTypes.business.noun.title',
  [EntityType.NUM]: 'entityTypes.business.num.title',
  [EntityType.ADJ]: 'entityTypes.business.adj.title',

  // Scientific
  [EntityType.ACTIVITY]: 'entityTypes.scientific.activity.title',
  [EntityType.ANATOMICAL_STRUCTURE]:
    'entityTypes.scientific.anatomicalStructure.title',
  [EntityType.BODY_SUBSTANCE]: 'entityTypes.scientific.bodySubstance.title',
  [EntityType.CELLS_AND_THEIR_COMPONENTS]:
    'entityTypes.scientific.cellsAndTheirComponents.title',
  [EntityType.CHEMICALS]: 'entityTypes.scientific.chemicals.title',
  [EntityType.CLINICAL_DRUG]: 'entityTypes.scientific.clinicalDrug.title',
  [EntityType.DISORDER]: 'entityTypes.scientific.disorder.title',
  [EntityType.DISORDERS]: 'entityTypes.scientific.disorders.title',
  [EntityType.EVENTS]: 'entityTypes.scientific.events.title',
  [EntityType.FINDINGS_OR_PHENOTYPES]:
    'entityTypes.scientific.findingsOrPhenotypes.title',
  [EntityType.FUNCTION]: 'entityTypes.scientific.function.title',
  [EntityType.GENE_AND_GENE_PRODUCTS]:
    'entityTypes.scientific.geneAndGeneProducts.title',
  [EntityType.GENES]: 'entityTypes.scientific.genes.title',
  [EntityType.GEOGRAPHICAL_AREAS]:
    'entityTypes.scientific.geographicalAreas.title',
  [EntityType.GROUP]: 'entityTypes.scientific.group.title',
  [EntityType.INTELLECTUAL]: 'entityTypes.scientific.intellectual.title',
  [EntityType.INTELLECTUAL_PROPERTY]:
    'entityTypes.scientific.intellectualProperty.title',
  [EntityType.LOCATION]: 'entityTypes.scientific.location.title',
  [EntityType.LOCATIONS]: 'entityTypes.scientific.locations.title',
  [EntityType.MEDICAL_PROCEDURE]:
    'entityTypes.scientific.medicalProcedure.title',
  [EntityType.ORGANISM]: 'entityTypes.scientific.organism.title',
  [EntityType.ORGANISMS]: 'entityTypes.scientific.organisms.title',
  [EntityType.ORGANIZATION]: 'entityTypes.scientific.organization.title',
  [EntityType.ORGANIZATIONS]: 'entityTypes.scientific.organizations.title',
  [EntityType.PHENOTYPE]: 'entityTypes.scientific.phenotype.title',
  [EntityType.PRODUCTS]: 'entityTypes.scientific.products.title',
  [EntityType.REGULATION_OR_LAW]:
    'entityTypes.scientific.regulationOrLaw.title',
  [EntityType.SIGNALING_MOLECULES]:
    'entityTypes.scientific.signalingMolecules.title',
  [EntityType.UNLABELLED]: 'entityTypes.scientific.unlabelled.title',

  // Other
  [EntityType.OTHER]: 'entityTypes.other.title',
};
