import styled, { css } from 'styled-components';

import Radio from '@components/Radio';

import { EntityPosition } from './types';

export const StyledItem = styled.div<{
  $inactive: boolean;
  $selected: boolean;
}>`
  padding: 2rem 2.5rem;

  ${(props) =>
    props.$inactive
      ? css`
          color: ${(props) => props.theme.relations.typeColor};
        `
      : props.$selected
      ? css`
          background-color: ${(props) =>
            props.theme.relations.hover.backgroundColor};
          border-top-right-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        `
      : css`
          cursor: pointer;

          &:hover {
            background-color: ${(props) =>
              props.theme.relations.hover.backgroundColor};
            border-top-right-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
          }
        `}
`;

export const RelationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledRadio = styled(Radio)`
  margin-right: 1.5rem;
  position: relative;
  top: 1.25rem;
`;

export const CommonContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RelationContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, fit-content(100%));
`;

export const Entity = styled.div<{
  $position: EntityPosition;
  $selected: boolean;
}>`
  overflow: hidden;
  ${({ $position, $selected, theme }) =>
    $selected &&
    css`
      color: ${theme.entity[$position].selected.backgroundColor};
    `};
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Type = styled.p`
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: ${(props) => props.theme.relations.typeColor};
`;

const SeparatorStyles = css<{ $inactive: boolean }>`
  content: '';
  display: inline-block;
  width: 1.5rem;
  border: 1px solid
    ${(props) =>
      props.$inactive
        ? props.theme.relations.typeColor
        : props.theme.relations.separatorColor};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.8rem;
`;

export const RelationWord = styled.div`
  position: relative;
  padding: 0 5rem;
  overflow: hidden;
  height: fit-content;

  &::before {
    ${SeparatorStyles};
    left: 1.75rem;
  }

  &::after {
    ${SeparatorStyles};
    right: 1.75rem;
  }
`;
