import uniqBy from 'lodash/uniqBy';

import { NamedEntityRecognitionData } from '@store/user/types';

import { NEW_LINE_SYMBOL } from '../defaults';

/*
  TODO: Refactor and add unit tests and cover as much edge cases as possible
*/
export const prepareNamedEntityRecognitionText = (
  text: string,
  data: NamedEntityRecognitionData[],
  labels: string[]
) => {
  if (data.length === 0) {
    return text;
  }
  const sortedItems = uniqBy(
    data.filter((item) => item.entity !== 'other'),
    (item) => item.word && item.start && item.end
  )
    .slice()
    .sort((a, b) => a.start - b.start);
  let newText = text;
  let offset = 0;
  sortedItems.forEach((item) => {
    const activeClassName = labels
      .map((label) => label.toLowerCase())
      .includes(item.entity.toLowerCase())
      ? ' active'
      : '';
    const start = newText.substring(0, item.start + offset);
    const end = newText.substring(item.end + offset, newText.length + offset);
    let inject = `<div class="entity${activeClassName}" data-score="${
      item.score
    }">${item.word.replaceAll(NEW_LINE_SYMBOL, '')}<span class="type">${
      item.entity
    }</span></div>`;
    const lineBreaksCount = (item.word.match(/\n/g) || []).length;
    if (lineBreaksCount > 0) {
      inject = `${Array.from(Array(lineBreaksCount))
        .map(() => NEW_LINE_SYMBOL)
        .join('')}${inject}`;
    }
    newText = `${start}${inject}${end}`;
    const htmlElementOffset =
      `<div class="entity${activeClassName}" data-score="${item.score}"><span class="type">${item.entity}</span></div>`
        .length;
    offset += htmlElementOffset;
  });
  return newText;
};
