import { useEffect, useState } from 'react';

import { DEFAULT_RESET_TIME } from './defaults';
import { ClickCounterHandler } from './types';

export default (resetTime = DEFAULT_RESET_TIME): ClickCounterHandler => {
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount((state) => state + 1);
  };

  const handleReset = () => {
    setCount(0);
  };

  useEffect(() => {
    const timerId = setTimeout(handleReset, resetTime);
    return () => {
      clearTimeout(timerId);
    };
  }, [resetTime, count]);

  return {
    count,
    handleClick,
    handleReset,
  };
};
