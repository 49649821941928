import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SampleText } from '@containers/Processor/types';
import CustomRelations from '@components/CustomRelations';
import Export from '@components/Export';
import Toggle from '@components/Toggle';
import { Mode } from '@components/Toggle/types';
import JsonView from '@components/JsonView';
import { RelationExtractionData, UIModel } from '@store/user/types';

import Content from './Content';
import Header from './Header';
import {
  StyledRelations,
  SectionTop,
  Title,
  Actions,
  RelationsContent,
} from './styled';
import { RelationsMode } from './types';

interface Props {
  selectedText: SampleText;
  handleToggleSelectedRelation: (relation: RelationExtractionData) => void;
  handleToggleActiveRelation: (relation: RelationExtractionData) => void;
  handleToggleHoveredRelation: (relation: RelationExtractionData) => void;
  handleDownloadJsonFile: VoidFunction;
}

const Relations: FC<Props> = ({
  selectedText,
  handleToggleSelectedRelation,
  handleToggleActiveRelation,
  handleToggleHoveredRelation,
  handleDownloadJsonFile,
}) => {
  const [customRelationsModalOpened, setCustomRelationsModalOpened] =
    useState(false);

  const { t } = useTranslation();

  const [activeMode, setActiveMode] = useState<RelationsMode>(
    RelationsMode.DEFAULT
  );

  const handleModeChange = (value: RelationsMode) => {
    setActiveMode(value);
  };

  const handleCustomRelationsModalOpen = () => {
    setCustomRelationsModalOpened(true);
  };

  const handleCustomRelationsModalClose = () => {
    setCustomRelationsModalOpened(false);
  };

  const modes: Mode<RelationsMode>[] = useMemo(
    () => [
      {
        title: t('components.relations.mode.default'),
        type: RelationsMode.DEFAULT,
      },
      {
        title: t('components.relations.mode.json'),
        type: RelationsMode.JSON,
      },
    ],
    []
  );

  const { relations, activeRelations, selectedRelations } =
    selectedText.relationExtraction!;
  return (
    <StyledRelations>
      <SectionTop>
        <Title>{t('components.relations.title')}</Title>
        {relations.length > 0 && (
          <Actions>
            <Export
              model={UIModel.RELATION_EXTRACTION}
              handleDownloadJsonFile={handleDownloadJsonFile}
            />
            <Toggle
              active={activeMode}
              onChange={handleModeChange}
              items={modes}
            />
          </Actions>
        )}
      </SectionTop>
      <RelationsContent>
        {activeMode === RelationsMode.DEFAULT ? (
          <>
            <Header
              count={relations.length}
              handleCustomRelationsModalOpen={handleCustomRelationsModalOpen}
            />
            <Content
              relations={relations}
              selectedRelations={selectedRelations}
              activeRelations={activeRelations}
              handleToggleSelectedRelation={handleToggleSelectedRelation}
              handleToggleActiveRelation={handleToggleActiveRelation}
              handleToggleHoveredRelation={handleToggleHoveredRelation}
            />
          </>
        ) : (
          <JsonView value={relations} />
        )}
        <CustomRelations
          selectedText={selectedText}
          isOpen={customRelationsModalOpened}
          handleClose={handleCustomRelationsModalClose}
          info
        />
      </RelationsContent>
    </StyledRelations>
  );
};

export default Relations;
