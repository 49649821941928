import { FC } from 'react';
import { useTheme } from 'styled-components';

import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import IconButton from '@components/IconButton';
import { IconButtonType } from '@components/IconButton/types';

import { StyledLink, NameWrapper, Name } from './styled';

interface Props {
  value: string;
  handleRemove: VoidFunction;
}

const Link: FC<Props> = ({ value, handleRemove }) => {
  const theme = useTheme();

  return (
    <StyledLink>
      <NameWrapper>
        <IconButton type={IconButtonType.SECONDARY}>
          <Icon type={IconType.LINK} />
        </IconButton>
        <Name>{value}</Name>
      </NameWrapper>
      <IconButton type={IconButtonType.PLAIN} onClick={handleRemove}>
        <Icon
          type={IconType.CROSS}
          stroke={theme.linkViewer.link.crossIconColor}
        />
      </IconButton>
    </StyledLink>
  );
};

export default Link;
