import styled from 'styled-components';

import { Color } from '@theme/colors';

export const StyledElement = styled.div<{ $borderColor: Color }>`
  border: 1px solid ${(props) => props.$borderColor};
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  line-height: 1.2;
  border-radius: 0.4rem;
`;

export const Column = styled.div<{ $backgroundColor: Color }>`
  background-color: ${(props) => props.$backgroundColor};
  color: initial;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.3rem;
  padding: 0.25rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
`;
