import { FC, memo, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useTheme } from 'styled-components';

import Loader from '@components/Loader';
import { convertRemToPx } from '@utils/css';

import { StyledPreview, LoaderWrapper } from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

interface Props {
  value: File;
}

const Preview: FC<Props> = ({ value }) => {
  const [numPages, setNumPages] = useState(0);

  const theme = useTheme();

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <StyledPreview>
      <Document
        file={URL.createObjectURL(value)}
        onLoadSuccess={onLoadSuccess}
        loading={
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        }
      >
        {Array.from(Array(numPages)).map((_, index) => (
          <Page
            key={index}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={index + 1}
            width={convertRemToPx(theme.fileInput.file.width)}
          />
        ))}
      </Document>
    </StyledPreview>
  );
};

export default memo(Preview);
