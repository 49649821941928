import { FC } from 'react';

import { Color } from '@theme/colors';

import { StyledDot } from './styled';

interface Props {
  className?: string;
  color: Color;
}

const Dot: FC<Props> = ({ className, color }) => (
  <StyledDot className={className} $color={color} />
);

export default Dot;
