import { AxiosError } from 'axios';

import { UNKNOWN_ERROR } from './defaults';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    return error.response?.data?.errors?.[0]?.message || UNKNOWN_ERROR;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return UNKNOWN_ERROR;
};
