import styled from 'styled-components';

import Playground from '@components/Playground';

export const StyledModel = styled.div``;

export const StyledBarChartsPlayground = styled(Playground)`
  margin-top: 5rem;
`;

export const BarChartsTitle = styled.p`
  font-size: 2.4rem;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.barChart.mainColor};
`;

export const BarChartsWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BarChartComponent = styled.div`
  flex: 1;
  max-width: calc(50% - 2.5rem);

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &:first-child {
    margin-right: 2.5rem;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-left: 2.5rem;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 2.5rem;
    }
  }
`;
