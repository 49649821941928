import { HTMLReactParserOptions, Element, Text } from 'html-react-parser';

import { EntityPosition } from '@components/Relations/Content/Item/types';
import { RelationExtractionData } from '@store/user/types';

import {
  ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE,
  ALGORITHM_POSITION_ATTRIBUTE_NAME,
  ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE,
} from './defaults';
import Entity from './Sentence/Entity';

export const getOptions = (
  relations: RelationExtractionData[],
  handleToggleHoveredRelation?: (relation: RelationExtractionData) => void
): HTMLReactParserOptions => ({
  replace: (domNode) => {
    const elementDomNode = domNode as Element;
    if (
      elementDomNode.attribs &&
      elementDomNode.attribs[ALGORITHM_POSITION_ATTRIBUTE_NAME]
    ) {
      const relationId = (elementDomNode.children[0] as Text).data;
      let position = elementDomNode.attribs[ALGORITHM_POSITION_ATTRIBUTE_NAME];
      let selected = false;
      let hovered = false;
      if (position.includes(ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE)) {
        selected = true;
        position = position.replace(
          ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE,
          ''
        );
      }
      if (position.includes(ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE)) {
        hovered = true;
        position = position.replace(
          ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE,
          ''
        );
      }
      return (
        <Entity
          selected={selected}
          hovered={hovered}
          position={position as EntityPosition}
          relations={relations}
          relationId={parseInt(relationId, 10)}
          handleToggleHoveredRelation={handleToggleHoveredRelation}
        />
      );
    }
    return domNode;
  },
});

export const getTextSizeInPx = (
  text: string,
  container: HTMLElement,
  fontSize: number
) => {
  const containerClone = container.cloneNode();

  const element = document.createElement('p');
  element.style.display = 'inline-block';
  element.style.lineHeight = 'normal';
  element.style.fontSize = `${fontSize}px`;
  element.textContent = text;

  containerClone.appendChild(element);

  document.body.appendChild(containerClone);

  const { width, height } = element.getBoundingClientRect();

  document.body.removeChild(containerClone);

  return { width, height };
};
