import styled, { css } from 'styled-components';

import Tooltip from '@components/Tooltip';

export const Type = styled.div`
  background-color: ${(props) => props.theme.entity.source.borderColor};
  color: ${(props) => props.theme.entity.source.selected.typeColor};
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.3rem;
  padding: 0.25rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
`;

export const StyledEntity = styled.div<{
  $active: boolean;
}>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.entity.source.borderColor};
  border-radius: 0.4rem;
  padding: 0 0.5rem;
  line-height: 1.2;

  ${(props) =>
    props.$active &&
    css`
      border: 1px solid ${props.theme.entity.source.selected.borderColor};

      ${Type} {
        background-color: ${props.theme.entity.source.selected
          .typeBackgroundColor};
      }
    `};
`;

export const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  align-items: center;
`;
