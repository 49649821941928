import { TextToTableData } from '@store/user/types';
import { Color } from '@theme/colors';

import { DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL } from './defaults';

export const colorPalette = [
  Color.NEW_ORLEANS,
  Color.COLD_PURPLE,
  Color.HIPPIE_GREEN,
  Color.PIZZA,
  Color.KOBI,
  Color.BURNT_SIENNA,
  Color.INDIGO,
  Color.ROMAN,
  Color.HALF_BAKED,
  Color.SANTA_FE,
  Color.APPLE,
  Color.CONGO_BROWN,
  Color.PLUM,
  Color.ENVY,
];

export const getColumnColor = (index: number, palette: Color[]) =>
  index < palette.length ? palette[index] : palette[index % palette.length];

export const getMaxElements = (data: TextToTableData) =>
  Object.keys(data).reduce((acc, column) => {
    const { length } = data[column];
    if (length > acc) {
      return length;
    }
    return acc;
  }, 0);

export const fillData = (data: TextToTableData, maxElements: number) => {
  const result: TextToTableData = {};
  Object.keys(data).forEach((column) => {
    const elements = data[column];
    if (elements.length < maxElements) {
      const lengthDifference = maxElements - elements.length;
      const emptyArray = Array(lengthDifference).fill(
        DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL
      );
      result[column] = [...elements, ...emptyArray];
    } else {
      result[column] = elements;
    }
  });
  return result;
};
