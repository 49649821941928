import { Extension, MimeType } from './types';

export const DEFAULT_ACCEPT: Record<MimeType, [Extension]> = {
  [MimeType.PDF]: ['.pdf'],
};

export const DEFAULT_MAX_FILES = 1;

export const MEGAYTES_TO_BYTES = 1000_000;
export const DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES = 5;
export const DEFAULT_MAX_FILE_SIZE_IN_BYTES =
  DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES * MEGAYTES_TO_BYTES;
