import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const LinkStyle = css<{ $underline?: boolean }>`
  display: inline-block;
  color: ${(props) => props.theme.link.color};

  ${(props) =>
    props.$underline
      ? css`
          text-decoration: underline;
        `
      : css`
          text-decoration: none;
        `};

  &:hover {
    color: ${(props) => props.theme.link.hover.color};
  }

  &:active {
    color: ${(props) => props.theme.link.active.color};
  }
`;

export const StyledLink = styled(Link)<{ $underline?: boolean }>`
  ${LinkStyle};
`;

export const StyledExternalLink = styled.a<{ $underline?: boolean }>`
  ${LinkStyle};
`;
