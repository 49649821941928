import styled from 'styled-components';

export const StyledLink = styled.div`
  width: ${(props) => props.theme.linkViewer.link.width}rem;
  padding: 2rem;
  border-radius: 1.4rem;
  background-color: ${(props) => props.theme.linkViewer.link.backgroundColor};
  color: ${(props) => props.theme.linkViewer.link.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.p`
  margin: 0 2rem;
  word-break: break-all;
`;
