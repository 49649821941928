import { DefaultTheme } from 'styled-components';

import { breakpointsTheme } from './breakpoints';
import {
  animationTheme,
  barChartTheme,
  benchmarkTheme,
  buttonTheme,
  chipTheme,
  classesTheme,
  cookieTheme,
  creatableTheme,
  customRelationsTheme,
  dotTheme,
  dropdownTheme,
  entityTheme,
  errorTheme,
  exportTheme,
  fileInputTheme,
  footerTheme,
  forgotPasswordTheme,
  genericTheme,
  headerTheme,
  highlighterTheme,
  horizontalLinesTheme,
  iconButtonTheme,
  iconTheme,
  inputTheme,
  linkTheme,
  linkViewerTheme,
  loaderTheme,
  loginTheme,
  logoTheme,
  modalTheme,
  notificationTheme,
  playgroundTheme,
  processorTheme,
  radioTheme,
  registerTheme,
  relationsTheme,
  resetPasswordTheme,
  scrollbarTheme,
  selectTheme,
  stepperTheme,
  tableTheme,
  tabsTheme,
  textCutterTheme,
  toggleTheme,
  tooltipTheme,
  userMenuTheme,
  verticalLinesTheme,
} from './components';

export const defaultTheme: DefaultTheme = {
  animation: animationTheme,
  barChart: barChartTheme,
  benchmark: benchmarkTheme,
  button: buttonTheme,
  chip: chipTheme,
  classes: classesTheme,
  cookie: cookieTheme,
  customRelations: customRelationsTheme,
  creatable: creatableTheme,
  dot: dotTheme,
  dropdown: dropdownTheme,
  entity: entityTheme,
  error: errorTheme,
  export: exportTheme,
  fileInput: fileInputTheme,
  footer: footerTheme,
  forgotPassword: forgotPasswordTheme,
  generic: genericTheme,
  header: headerTheme,
  highlighter: highlighterTheme,
  horizontalLines: horizontalLinesTheme,
  icon: iconTheme,
  iconButton: iconButtonTheme,
  input: inputTheme,
  link: linkTheme,
  linkViewer: linkViewerTheme,
  loader: loaderTheme,
  logo: logoTheme,
  login: loginTheme,
  modal: modalTheme,
  notification: notificationTheme,
  playground: playgroundTheme,
  processor: processorTheme,
  radio: radioTheme,
  register: registerTheme,
  relations: relationsTheme,
  resetPassword: resetPasswordTheme,
  scrollbar: scrollbarTheme,
  select: selectTheme,
  stepper: stepperTheme,
  table: tableTheme,
  tabs: tabsTheme,
  textCutter: textCutterTheme,
  toggle: toggleTheme,
  tooltip: tooltipTheme,
  userMenu: userMenuTheme,
  verticalLines: verticalLinesTheme,
  ...breakpointsTheme,
};
