import { TFunction } from 'i18next';

import { RawStep, Step } from '@components/Stepper/Step/types';
import { UIModel } from '@store/user/types';

import {
  mapModelToAnimations,
  mapModelToStepSize,
  mapModelToStepsKey,
} from './utils';

export const getSteps = (t: TFunction, model: UIModel): Step[] =>
  (
    t(mapModelToStepsKey[model], {
      returnObjects: true,
    }) as RawStep[]
  ).map((step, index) => ({
    ...step,
    size: mapModelToStepSize[model],
    ...(index > 0 && {
      animation: mapModelToAnimations[model][index - 1],
    }),
  }));
