import { SVGProps } from 'react';
import { CSSProperties } from 'styled-components';

export type IconProps = SVGProps<SVGSVGElement> & {
  type: IconType;
  width?: number;
  height?: number;
  large?: boolean;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  style?: CSSProperties;
};

export enum IconType {
  HELP_CIRCLE = 'helpCircle',
  PLUS = 'plus',
  CROSS = 'cross',
  CROSS_CIRCLE = 'crossCircle',
  CHECK = 'check',
  EDIT = 'edit',
  LOADER = 'loader',
  INFO_CIRCLE = 'infoCircle',
  EYE = 'eye',
  EYE_OFF = 'eyeOff',
  MORE_HORIZONTAL = 'moreHorizontal',
  MORE_VERTICAL = 'moreVertical',
  CHEVRON_DOWN = 'chevronDown',
  CHEVRON_LEFT = 'chevronLeft',
  CHEVRON_RIGHT = 'chevronRight',
  ARROW_DOWN = 'arrowDown',
  ARROW_UP_RIGHT = 'arrowUpRight',
  GOOGLE = 'google',
  USER = 'user',
  BIN = 'bin',
  LINK = 'link',
  UPLOAD = 'upload',
  FILE = 'file',
}
