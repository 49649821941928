import styled, { css } from 'styled-components';

import { DEFAULT_ARROW_SIZE } from './defaults';
import { TooltipCoordinates } from './types';

export const TooltipElement = styled.div<{
  $showArrow: boolean;
  $arrowCoordinates?: TooltipCoordinates['arrow'];
  $maxWidth?: number;
}>`
  background-color: ${(props) => props.theme.tooltip.backgroundColor};
  color: ${(props) => props.theme.tooltip.color};
  text-align: center;
  position: absolute;
  z-index: ${(props) => props.theme.tooltip.zIndex};
  padding: 1rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;

  ${(props) =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth}rem;
    `};

  ${(props) =>
    props.$showArrow &&
    css`
      &:before {
        content: '';
        position: absolute;
        border: ${DEFAULT_ARROW_SIZE}rem solid transparent;
        border-color: transparent transparent
          ${props.theme.tooltip.backgroundColor}
          ${props.theme.tooltip.backgroundColor};

        ${props.$arrowCoordinates?.left
          ? css`
              transform: rotate(135deg);
              top: -${DEFAULT_ARROW_SIZE}rem;
              left: calc(
                ${props.$arrowCoordinates?.left || 0}px -
                  ${DEFAULT_ARROW_SIZE}rem
              );
            `
          : css`
              transform: rotate(225deg);
              right: -${DEFAULT_ARROW_SIZE}rem;
              top: calc(
                ${props.$arrowCoordinates?.top || 0}px -
                  ${DEFAULT_ARROW_SIZE}rem
              );
            `}
      }
    `};
`;

export const StyledTooltip = styled.div``;
