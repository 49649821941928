import { KeyboardEvent } from 'react';

import { MacrosHandler, MacrosKey, MacrosProps } from './types';

export default ({ keysWithListeners }: MacrosProps): MacrosHandler => {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    const listener = keysWithListeners[e.key as MacrosKey];
    if (listener) {
      listener();
    }
  };

  return {
    handleKeyDown,
  };
};
