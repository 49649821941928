import { FC } from 'react';
import { Trans } from 'react-i18next';

import Animation from './Animation';
import { StyledStep, AnimationWrapper, Description, Details } from './styled';
import { Animation as AnimationType } from './types';

interface Props {
  animationKey: number;
  animation?: AnimationType;
  description: string;
  details?: string;
  size?: number;
}

const Step: FC<Props> = ({
  animationKey,
  animation,
  description,
  details,
  size,
}) => (
  <StyledStep $size={size}>
    {animation && (
      <AnimationWrapper key={animationKey}>
        <Animation json={animation.json} speedFactor={animation.speedFactor} />
      </AnimationWrapper>
    )}
    <Description>
      <Trans components={{ b: <b /> }}>{description}</Trans>
    </Description>
    {details && <Details>{details}</Details>}
  </StyledStep>
);

export default Step;
