import { FC } from 'react';

import { StyledBurger, Bar, Toggle } from './styled';

interface Props {
  opened: boolean;
  onClick: VoidFunction;
}

const Burger: FC<Props> = ({ opened, onClick }) => (
  <StyledBurger onClick={onClick}>
    <Toggle />
    <Bar $top $open={opened} />
    <Bar $middle $open={opened} />
    <Bar $bottom $open={opened} />
  </StyledBurger>
);

export default Burger;
