import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const barChartTheme: DefaultTheme['barChart'] = {
  chartHeight: 42.5,
  mainColor: Color.SILVER_CHALICE,
  tickColor: Color.WHITE,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  borderColor: Color.TUNDORA,
  barColor: Color.INDIGO,
  highlihtedBarColor: Color.PIZZA,
};
