import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ButtonType } from './types';

const ButtonStyle = css<{ $type: ButtonType; $disabled?: boolean }>`
  display: inline-block;
  background-color: ${(props) =>
    props.theme.button[props.$type].enabled.backgroundColor};
  border: 1px solid
    ${(props) => props.theme.button[props.$type].enabled.borderColor};
  color: ${(props) => props.theme.button[props.$type].enabled.color};
  padding: 1rem 5rem;
  border-radius: 0.8rem;
  text-decoration: none;
  font-size: 2rem;

  ${(props) =>
    props.$disabled
      ? css`
          cursor: not-allowed;
          background-color: ${props.theme.button[props.$type].disabled
            .backgroundColor};
          border: 1px solid
            ${props.theme.button[props.$type].disabled.borderColor};
          color: ${props.theme.button[props.$type].disabled.color};
        `
      : css`
          &:hover {
            background-color: ${props.theme.button[props.$type].hover
              .backgroundColor};
            border-color: ${props.theme.button[props.$type].hover.borderColor};
            color: ${props.theme.button[props.$type].hover.color};
          }

          &:active {
            background-color: ${props.theme.button[props.$type].active
              .backgroundColor};
            border-color: ${props.theme.button[props.$type].active.borderColor};
            color: ${props.theme.button[props.$type].active.color};
          }
        `};
`;

export const StyledButton = styled(Link)`
  ${ButtonStyle};
`;

export const StyledExternalButton = styled.a`
  position: relative;
  ${ButtonStyle};
`;
