import styled, { css } from 'styled-components';

export const StyledBurger = styled.div`
  position: relative;
  cursor: pointer;
`;

export const Toggle = styled.div`
  width: 25px;
  height: 21px;
  display: flex;
  flex-direction: column;
`;

export const Bar = styled.div<{
  $top?: boolean;
  $middle?: boolean;
  $bottom?: boolean;
  $open: boolean;
}>`
  position: absolute;
  top: 50%;
  margin-top: -1.5px;
  height: 3px;
  width: 100%;
  border-radius: 4px;
  background-color: ${(props) =>
    props.theme.header.mobileMenu.burgerBarBackgroundColor};
  transition: all 0.2s ease;

  ${(props) =>
    props.$top &&
    css`
      transform: translate(0, -9px);

      ${props.$open &&
      css`
        transform: translate(0, 0) rotate(45deg);
      `};
    `};

  ${(props) =>
    props.$middle &&
    props.$open &&
    css`
      opacity: 0;
    `};

  ${(props) =>
    props.$bottom &&
    css`
      transform: translate(0, 9px);

      ${props.$open &&
      css`
        transform: translate(0, 0) rotate(-45deg);
      `};
    `};
`;
