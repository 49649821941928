import styled from 'styled-components';

import IconButton from '@components/IconButton';
import { NotificationType } from '@store/notification/types';

export const StyledNotification = styled.div<{ $type: NotificationType }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 3rem;
  z-index: ${(props) => props.theme.notification.zIndex};
  height: ${(props) => props.theme.notification.height}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.notification[props.$type].backgroundColor};
  color: ${(props) => props.theme.notification[props.$type].color};
  border: 1px solid
    ${(props) => props.theme.notification[props.$type].borderColor};
`;

export const Text = styled.p``;

export const ActionIconButton = styled(IconButton)`
  position: absolute;
  right: 3rem;
`;
