import uniqBy from 'lodash/uniqBy';

import { EntityPosition } from '@components/Relations/Content/Item/types';
import { RelationExtractionData } from '@store/user/types';

import {
  ALGORITHM_POSITION_ATTRIBUTE_NAME,
  ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE,
  ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE,
} from './defaults';
import { AlgoEntity } from './types';

export const transformSources = (
  activeRelations: RelationExtractionData[],
  selectedRelations: RelationExtractionData[],
  hoveredRelations: RelationExtractionData[]
): AlgoEntity[] =>
  activeRelations.map(({ source, id }) => ({
    position: EntityPosition.SOURCE,
    value: source,
    id,
    selected: !!selectedRelations.find(
      (selectedRelation) => selectedRelation.id === id
    ),
    hovered: !!hoveredRelations.find(
      (hoveredRelation) => hoveredRelation.id === id
    ),
  }));

export const transformTargets = (
  activeRelations: RelationExtractionData[],
  selectedRelations: RelationExtractionData[],
  hoveredRelations: RelationExtractionData[]
): AlgoEntity[] =>
  activeRelations.map(({ target, id }) => ({
    position: EntityPosition.TARGET,
    value: target,
    id,
    selected: !!selectedRelations.find(
      (selectedRelation) => selectedRelation.id === id
    ),
    hovered: !!hoveredRelations.find(
      (hoveredRelation) => hoveredRelation.id === id
    ),
  }));

/*
  TODO: Refactor and add unit tests and cover as much edge cases as possible
*/
export const prepareRelationExtractionText = (
  text: string,
  activeRelations: RelationExtractionData[],
  selectedRelations: RelationExtractionData[],
  hoveredRelations: RelationExtractionData[]
) => {
  if (activeRelations.length === 0) {
    return text;
  }
  const transformedSources = transformSources(
    activeRelations,
    selectedRelations,
    hoveredRelations
  );
  const transformedTargets = transformTargets(
    activeRelations,
    selectedRelations,
    hoveredRelations
  );
  const commonTransformedRelations = uniqBy(
    [...transformedSources, ...transformedTargets],
    (relation) =>
      relation.value.word && relation.value.start && relation.value.end
  )
    .slice()
    .sort((a, b) => a.value.start - b.value.start);

  let newText = text;
  let offset = 0;

  commonTransformedRelations.forEach(
    ({ value: { start, word, end }, position, id, selected, hovered }) => {
      const textStart = newText.substring(0, start + offset);
      const textEnd = newText.substring(end + offset, newText.length + offset);

      newText = `${textStart}<span ${ALGORITHM_POSITION_ATTRIBUTE_NAME}="${position}${
        selected ? `${ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE}` : ''
      }${
        hovered ? `${ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE}` : ''
      }">${id}</span>${textEnd}`;

      const defaultOffset = 23 + ALGORITHM_POSITION_ATTRIBUTE_NAME.length;
      const contentChangeOffset = word.length - id.toString().length;
      const selectedOffset = selected
        ? ALGORITHM_POSITION_ATTRIBUTE_SELECTED_VALUE.length
        : 0;
      const hoveredOffset = hovered
        ? ALGORITHM_POSITION_ATTRIBUTE_HOVERED_VALUE.length
        : 0;

      offset +=
        defaultOffset - contentChangeOffset + selectedOffset + hoveredOffset;

      return true;
    }
  );
  return newText;
};
