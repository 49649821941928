import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Animation from '@components/Animation';
import Button from '@components/Button';

import { StyledSubmit } from './styled';

interface Props {
  playAnimation: boolean;
  handleProcess: VoidFunction;
}

const Submit: FC<Props> = ({ playAnimation, handleProcess }) => {
  const { t } = useTranslation();

  return (
    <StyledSubmit>
      <Animation play={playAnimation}>
        <Button to="#" onClick={playAnimation ? undefined : handleProcess}>
          {t('components.processor.header.submit')}
        </Button>
      </Animation>
    </StyledSubmit>
  );
};

export default Submit;
