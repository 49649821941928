import { FC, PropsWithChildren } from 'react';

import { StyledFormGroup } from './styled';

interface Props {
  noMargin?: boolean;
}

const FormGroup: FC<PropsWithChildren<Props>> = ({ noMargin, children }) => (
  <StyledFormGroup $noMargin={noMargin}>{children}</StyledFormGroup>
);

export default FormGroup;
