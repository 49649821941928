import { MultiCreatableValue } from '@components/Creatable/types';
import {
  mapToMultiCreatableValue,
  mapToTextToTableColumn,
} from '@components/Processor/Header/utils';
import { DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL } from '@components/Table/defaults';
import i18n from '@i18n/config';
import {
  RelationExtractionData,
  TextToTableData,
  UIModel,
} from '@store/user/types';

import {
  DEFAULT_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID,
  NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID,
} from './defaults';
import {
  SampleText,
  TextStatus,
  TextType,
  NamedEntityRecognitionTextTopic,
  TextClassificationTextTopic,
  TextToTableTextTopic,
  TextToTableSampleText,
  RelationExtractionTextTopic,
} from './types';
import {
  mapNamedEntityRecognitionTextTopicToContentMessageId,
  mapNamedEntityRecognitionTextTopicToDataMessageId,
  mapNamedEntityRecognitionTextTopicToLabelsMessageId,
  mapNamedEntityRecognitionTextTopicToTitleMessageId,
  mapTextClassificationTextTopicToContentMessageId,
  mapTextClassificationTextTopicToLabelsMessageId,
  mapTextClassificationTextTopicToDataMessageId,
  mapTextClassificationTextTopicToTitleMessageId,
  mapTextToTableTextTopicToColumnsMessageId,
  mapTextToTableTextTopicToTitleMessageId,
  mapTextToTableTextTopicToContentMessageId,
  mapTextToTableTextTopicToDataMessageId,
  generateTextTitle,
  mapRelationExtractionTextTopicToTitleMessageId,
  mapRelationExtractionTextTopicToContentMessageId,
  mapRelationExtractionTextTopicToDataMessageId,
} from './utils';

export const getDefaultTextToTableColumns = () =>
  mapToTextToTableColumn([
    i18n.t(DEFAULT_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID, { number: 1 }),
    i18n.t(DEFAULT_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID, { number: 2 }),
    i18n.t(DEFAULT_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID, { number: 3 }),
  ]);

export const getDefaultTextToTableCells = (count = 8): TextToTableData => {
  const defaultColumns = getDefaultTextToTableColumns();
  return defaultColumns.reduce((acc, item) => {
    acc[item.label] = Array.from(Array(count)).map(
      () => DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL
    );
    return acc;
  }, {} as TextToTableData);
};

export const getDefaultTextToTableSampleText = (): TextToTableSampleText => ({
  initialColumns: mapToTextToTableColumn([
    i18n.t(NEW_TEXT_TO_TABLE_COLUMN_LABEL_MESSAGE_ID),
  ]),
  columns: getDefaultTextToTableColumns(),
  data: getDefaultTextToTableCells(),
  highlightedData: [],
  highlightedElement: undefined,
});

const prepareRelationExtractionSampleText = (
  topic: RelationExtractionTextTopic
): SampleText => {
  const data: RelationExtractionData[] = JSON.parse(
    i18n.t(mapRelationExtractionTextTopicToDataMessageId[topic], {
      returnObjects: true,
    })
  );
  const isDataEmpty = Object.keys(data).length === 0;
  const relations = isDataEmpty ? [] : data;
  const activeRelations = isDataEmpty ? [] : relations;
  const selectedRelations = isDataEmpty
    ? []
    : activeRelations.length > 0
    ? [activeRelations[0]]
    : [];
  return {
    title: i18n.t(mapRelationExtractionTextTopicToTitleMessageId[topic]),
    content: i18n.t(mapRelationExtractionTextTopicToContentMessageId[topic], {
      returnObjects: true,
    }),
    files: [],
    status: TextStatus.READY_FOR_PROCESSING,
    type: TextType.SAMPLE,
    changed: false,
    link: false,
    namedEntityRecognition: {
      initialLabels: [],
      labels: [],
      data: [],
    },
    textClassification: {
      initialLabels: [],
      labels: [],
      pendingLabels: [],
      data: {},
    },
    textToTable: getDefaultTextToTableSampleText(),
    relationExtraction: {
      relations,
      activeRelations,
      selectedRelations,
      hoveredRelations: [],
    },
  };
};

export const relationExtractionSampleTexts: SampleText[] = Object.values(
  RelationExtractionTextTopic
).map(prepareRelationExtractionSampleText);

const prepareNamedEntityRecognitionSampleText = (
  topic: NamedEntityRecognitionTextTopic
): SampleText => ({
  title: i18n.t(mapNamedEntityRecognitionTextTopicToTitleMessageId[topic]),
  content: i18n.t(mapNamedEntityRecognitionTextTopicToContentMessageId[topic], {
    returnObjects: true,
  }),
  files: [],
  status: TextStatus.READY_FOR_PROCESSING,
  type: TextType.SAMPLE,
  changed: false,
  link: false,
  namedEntityRecognition: {
    initialLabels: i18n.t(
      mapNamedEntityRecognitionTextTopicToLabelsMessageId[topic],
      {
        returnObjects: true,
      }
    ),
    labels: i18n.t(mapNamedEntityRecognitionTextTopicToLabelsMessageId[topic], {
      returnObjects: true,
    }),
    data: JSON.parse(
      i18n.t(mapNamedEntityRecognitionTextTopicToDataMessageId[topic], {
        returnObjects: true,
      })
    ),
  },
  textClassification: {
    initialLabels: [],
    labels: [],
    pendingLabels: [],
    data: {},
  },
  textToTable: getDefaultTextToTableSampleText(),
  relationExtraction: {
    relations: [],
    activeRelations: [],
    selectedRelations: [],
    hoveredRelations: [],
  },
});

export const namedEntityRecognitionSampleTexts: SampleText[] = Object.values(
  NamedEntityRecognitionTextTopic
).map(prepareNamedEntityRecognitionSampleText);

const prepareTextClassificationSampleText = (
  topic: TextClassificationTextTopic
): SampleText => ({
  title: i18n.t(mapTextClassificationTextTopicToTitleMessageId[topic]),
  content: i18n.t(mapTextClassificationTextTopicToContentMessageId[topic], {
    returnObjects: true,
  }),
  files: [],
  status: TextStatus.READY_FOR_PROCESSING,
  type: TextType.SAMPLE,
  changed: false,
  link: false,
  namedEntityRecognition: {
    initialLabels: [],
    labels: [],
    data: [],
  },
  textClassification: {
    initialLabels: mapToMultiCreatableValue(
      i18n.t(mapTextClassificationTextTopicToLabelsMessageId[topic], {
        returnObjects: true,
      })
    ),
    labels: mapToMultiCreatableValue(
      i18n.t(mapTextClassificationTextTopicToLabelsMessageId[topic], {
        returnObjects: true,
      })
    ),
    pendingLabels: [],
    data: JSON.parse(
      i18n.t(mapTextClassificationTextTopicToDataMessageId[topic], {
        returnObjects: true,
      })
    ),
  },
  textToTable: getDefaultTextToTableSampleText(),
  relationExtraction: {
    relations: [],
    activeRelations: [],
    selectedRelations: [],
    hoveredRelations: [],
  },
});

export const textClassificationSampleTexts: SampleText[] = Object.values(
  TextClassificationTextTopic
).map(prepareTextClassificationSampleText);

const prepareTextToTableSampleText = (
  topic: TextToTableTextTopic
): SampleText => ({
  title: i18n.t(mapTextToTableTextTopicToTitleMessageId[topic]),
  content: i18n.t(mapTextToTableTextTopicToContentMessageId[topic], {
    returnObjects: true,
  }),
  files: [],
  status: TextStatus.READY_FOR_PROCESSING,
  type: TextType.SAMPLE,
  changed: false,
  link: false,
  namedEntityRecognition: {
    initialLabels: [],
    labels: [],
    data: [],
  },
  textClassification: {
    initialLabels: [],
    labels: [],
    pendingLabels: [],
    data: {},
  },
  textToTable: {
    initialColumns: mapToTextToTableColumn(
      i18n.t(mapTextToTableTextTopicToColumnsMessageId[topic], {
        returnObjects: true,
      })
    ),
    columns: mapToTextToTableColumn(
      i18n.t(mapTextToTableTextTopicToColumnsMessageId[topic], {
        returnObjects: true,
      })
    ),
    data: JSON.parse(
      i18n.t(mapTextToTableTextTopicToDataMessageId[topic], {
        returnObjects: true,
      })
    ),
    highlightedData: [],
    highlightedElement: undefined,
  },
  relationExtraction: {
    relations: [],
    activeRelations: [],
    selectedRelations: [],
    hoveredRelations: [],
  },
});

export const textToTableSampleTexts: SampleText[] = Object.values(
  TextToTableTextTopic
).map(prepareTextToTableSampleText);

export const getEmptyText = (
  textTitles: string[],
  model: UIModel,
  defaultContent?: string,
  defaultMeta?: MultiCreatableValue<string>
): SampleText => ({
  title: generateTextTitle(textTitles),
  content: defaultContent ? [defaultContent] : [],
  files: [],
  status:
    model === UIModel.TEXT_TO_TABLE && !defaultContent
      ? TextStatus.EMPTY
      : TextStatus.PENDING_ERROR,
  type: TextType.CUSTOM,
  changed: false,
  link: false,
  namedEntityRecognition: {
    initialLabels: [],
    labels: defaultMeta ? defaultMeta.map((item) => item.label) : [],
    data: [],
  },
  textClassification: {
    initialLabels: [],
    labels: [],
    pendingLabels: defaultMeta || [],
    data: {},
  },
  textToTable: getDefaultTextToTableSampleText(),
  relationExtraction: {
    relations: [],
    activeRelations: [],
    selectedRelations: [],
    hoveredRelations: [],
  },
});
