import { FC } from 'react';

import { Color } from '@theme/colors';

import { StyledElement, Column } from './styled';

interface Props {
  label: string;
  column: string;
  color: Color;
}

const Element: FC<Props> = ({ label, column, color }) => (
  <StyledElement $borderColor={color}>
    {label}
    <Column $backgroundColor={color}>{column}</Column>
  </StyledElement>
);

export default Element;
