import { DefaultState } from '@store/types';

import {
  CLEAR_MODELS,
  NAMED_ENTITY_RECOGNITION_PROCESS,
  NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED,
  NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED,
  RELATION_EXTRACTION_PROCESS,
  RELATION_EXTRACTION_PROCESS_FULFILLED,
  RELATION_EXTRACTION_PROCESS_REJECTED,
  TEXT_CLASSIFICATION_PROCESS,
  TEXT_CLASSIFICATION_PROCESS_FULFILLED,
  TEXT_CLASSIFICATION_PROCESS_REJECTED,
  TEXT_TO_TABLE_PROCESS,
  TEXT_TO_TABLE_PROCESS_FULFILLED,
  TEXT_TO_TABLE_PROCESS_REJECTED,
} from './actionTypes';

export enum UIModel {
  RELATION_EXTRACTION = 'RELATION_EXTRACTION',
  NAMED_ENTITY_RECOGNITION = 'NAMED_ENTITY_RECOGNITION',
  TEXT_CLASSIFICATION = 'TEXT_CLASSIFICATION',
  TEXT_TO_TABLE = 'TEXT_TO_TABLE',
}

export interface NamedEntityRecognitionData {
  end: number;
  entity: string;
  score: number;
  start: number;
  word: string;
}

export type TextClassificationData = Record<string, number>;

export interface TextToTablePreProcessData {
  columns: string[];
  columns_tokens: number;
  text: string;
  text_tokens: number;
  total_tokens: number;
}

export type TextToTableData = Record<string, string[]>;

export enum EntityType {
  // Business
  PERSON = 'PERSON',
  NORP = 'NORP',
  FAC = 'FAC',
  ORG = 'ORG',
  GPE = 'GPE',
  LOC = 'LOC',
  PRODUCT = 'PRODUCT',
  EVENT = 'EVENT',
  WORK_OF_ART = 'WORK_OF_ART',
  LAW = 'LAW',
  LANGUAGE = 'LANGUAGE',
  DATE = 'DATE',
  TIME = 'TIME',
  PERCENT = 'PERCENT',
  MONEY = 'MONEY',
  QUANTITY = 'QUANTITY',
  ORDINAL = 'ORDINAL',
  CARDINAL = 'CARDINAL',
  PRON = 'PRON',
  PROPN = 'PROPN',
  NOUN = 'NOUN',
  NUM = 'NUM',
  ADJ = 'ADJ',

  // Scientific
  ACTIVITY = 'ACTIVITY',
  ANATOMICAL_STRUCTURE = 'ANATOMICAL STRUCTURE',
  BODY_SUBSTANCE = 'BODY SUBSTANCE',
  CELLS_AND_THEIR_COMPONENTS = 'CELLS AND THEIR COMPONENTS',
  CHEMICALS = 'CHEMICALS',
  CLINICAL_DRUG = 'CLINICAL DRUG',
  DISORDER = 'DISORDER',
  DISORDERS = 'DISORDERS',
  EVENTS = 'EVENTS',
  FINDINGS_OR_PHENOTYPES = 'FINDINGS/PHENOTYPES',
  FUNCTION = 'FUNCTION',
  GENE_AND_GENE_PRODUCTS = 'GENE AND GENE PRODUCTS',
  GENES = 'GENES',
  GEOGRAPHICAL_AREAS = 'GEOGRAPHICAL AREAS',
  GROUP = 'GROUP',
  INTELLECTUAL = 'INTELLECTUAL',
  INTELLECTUAL_PROPERTY = 'INTELLECTUAL PROPERTY',
  LOCATION = 'LOCATION',
  LOCATIONS = 'LOCATIONS',
  MEDICAL_PROCEDURE = 'MEDICAL PROCEDURE',
  ORGANISM = 'ORGANISM',
  ORGANISMS = 'ORGANISMS',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATIONS = 'ORGANIZATIONS',
  PHENOTYPE = 'PHENOTYPE',
  PRODUCTS = 'PRODUCTS',
  REGULATION_OR_LAW = 'REGULATION OR LAW',
  SIGNALING_MOLECULES = 'SIGNALING MOLECULES',
  UNLABELLED = 'Unlabelled',

  // Other
  OTHER = 'other',
}

export interface Entity {
  end: number;
  entity: EntityType;
  score: number;
  start: number;
  word: string;
}

export interface RawRelationExtractionData {
  relation: string;
  score: number;
  source: Entity;
  target: Entity;
}
export interface RelationExtractionData extends RawRelationExtractionData {
  id: number;
}

export interface UserState {
  namedEntityRecognitionProcessDetails: Record<
    string,
    DefaultState & {
      data: NamedEntityRecognitionData[];
      index: number;
    }
  >;
  textClassificationProcessDetails: Record<
    string,
    DefaultState & {
      data: TextClassificationData;
      index: number;
    }
  >;
  textToTableProcessDetails: Record<
    string,
    DefaultState & {
      data: TextToTableData;
      index: number;
    }
  >;
  relationExtractionProcessDetails: Record<
    string,
    DefaultState & {
      data: RelationExtractionData[];
      index: number;
    }
  >;
}

export type NamedEntityRecognitionProcess = {
  type: typeof NAMED_ENTITY_RECOGNITION_PROCESS;
  payload: {
    name: string;
    index: number;
  };
};

export type NamedEntityRecognitionProcessFulfilled = {
  type: typeof NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED;
  payload: {
    name: string;
    index: number;
    data: NamedEntityRecognitionData[];
  };
};

export type NamedEntityRecognitionProcessRejected = {
  type: typeof NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED;
  payload: {
    name: string;
    index: number;
    error: string;
  };
};

export type TextClassificationProcess = {
  type: typeof TEXT_CLASSIFICATION_PROCESS;
  payload: {
    name: string;
    index: number;
  };
};

export type TextClassificationProcessFulfilled = {
  type: typeof TEXT_CLASSIFICATION_PROCESS_FULFILLED;
  payload: {
    name: string;
    index: number;
    data: TextClassificationData;
  };
};

export type TextClassificationProcessRejected = {
  type: typeof TEXT_CLASSIFICATION_PROCESS_REJECTED;
  payload: {
    name: string;
    index: number;
    error: string;
  };
};

export type TextToTableProcess = {
  type: typeof TEXT_TO_TABLE_PROCESS;
  payload: {
    name: string;
    index: number;
  };
};

export type TextToTableProcessFulfilled = {
  type: typeof TEXT_TO_TABLE_PROCESS_FULFILLED;
  payload: {
    name: string;
    index: number;
    data: TextToTableData;
  };
};

export type TextToTableProcessRejected = {
  type: typeof TEXT_TO_TABLE_PROCESS_REJECTED;
  payload: {
    name: string;
    index: number;
    error: string;
  };
};

export type RelationExtractionProcess = {
  type: typeof RELATION_EXTRACTION_PROCESS;
  payload: {
    name: string;
    index: number;
  };
};

export type RelationExtractionProcessFulfilled = {
  type: typeof RELATION_EXTRACTION_PROCESS_FULFILLED;
  payload: {
    name: string;
    index: number;
    data: RelationExtractionData[];
  };
};

export type RelationExtractionProcessRejected = {
  type: typeof RELATION_EXTRACTION_PROCESS_REJECTED;
  payload: {
    name: string;
    index: number;
    error: string;
  };
};

export type ClearModels = {
  type: typeof CLEAR_MODELS;
};

export type UserActions =
  | NamedEntityRecognitionProcess
  | NamedEntityRecognitionProcessFulfilled
  | NamedEntityRecognitionProcessRejected
  | TextClassificationProcess
  | TextClassificationProcessFulfilled
  | TextClassificationProcessRejected
  | TextToTableProcess
  | TextToTableProcessFulfilled
  | TextToTableProcessRejected
  | RelationExtractionProcess
  | RelationExtractionProcessFulfilled
  | RelationExtractionProcessRejected
  | ClearModels;
