export enum MimeType {
  PDF = 'application/pdf',
}

export type Extension = '.pdf';

export type FileInputError =
  | 'file-invalid-type'
  | 'file-too-large'
  | 'too-many-files'
  | 'unknown';
