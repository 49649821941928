import { createSelector } from 'reselect';

import { AppState } from '@store/rootReducer';

export const getNotificationsState = (state: AppState) =>
  state.notification.notifications;

export const getNotificationsSelector = createSelector(
  getNotificationsState,
  (notifications) => notifications
);
