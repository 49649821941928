import styled, { css } from 'styled-components';

import { EntityPosition } from '@components/Relations/Content/Item/types';

export const StyledType = styled.div<{
  $selected: boolean;
  $hovered: boolean;
  $position: EntityPosition;
}>`
  background-color: ${(props) =>
    props.theme.entity[props.$position].typeBackgroundColor};
  color: ${(props) => props.theme.entity[props.$position].typeColor};
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0.3rem;
  padding: 0.25rem;
  margin-left: 0.5rem;

  ${(props) =>
    props.$selected &&
    css`
      background-color: ${props.theme.entity[props.$position].selected
        .typeBackgroundColor};
      color: ${props.theme.entity[props.$position].selected.typeColor};
    `};

  ${(props) =>
    props.$hovered &&
    css`
      background-color: ${props.theme.entity[props.$position].hovered
        .typeBackgroundColor};
      color: ${props.theme.entity[props.$position].hovered.typeColor};
    `};
`;
