import {
  FC,
  FormEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextToTableHighlightedData,
  TextToTableHighlightedElement,
} from '@containers/Processor/types';
import { ButtonType } from '@components/Button/types';
import { DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL } from '@components/Table/defaults';
import { IconType } from '@components/Icon/types';
import useClickPrevention from '@hooks/useClickPrevention';
import useForceUpdate from '@hooks/useForceUpdate';
import { Color } from '@theme/colors';

import Cell from './Cell';
import { TABLE_ROW_CLASS_NAME } from './Cell/default';
import {
  StyledColumn,
  StyledActionColumn,
  StyledActionColumnButton,
  StyledActionColumnIcon,
  StyledNumberColumn,
  Content,
  LabelParent,
  Label,
  StyledDot,
  StyledDropdown,
} from './styled';

interface Props {
  label: string;
  isUpdated: boolean;
  isNew: boolean;
  data: string[];
  color: Color;
  handleDelete: VoidFunction;
  handleUpdate: (newLabel: string) => void;
  handleColumnClick: (isHighlighted: boolean) => void;
  handleCellClick: (value: string, isHighlighted: boolean) => void;
  highlightedData: TextToTableHighlightedData[];
  isColumnHighlighted: boolean;
  isAnyRowHighlighted: boolean;
  uiError: boolean;
  maxRowHeights: number[];
}

export const Column: FC<Props> = ({
  label,
  isUpdated,
  isNew,
  data,
  color,
  handleDelete,
  handleUpdate,
  handleColumnClick,
  handleCellClick,
  highlightedData,
  isColumnHighlighted,
  isAnyRowHighlighted,
  uiError,
  maxRowHeights,
}) => {
  const text = useRef(label);

  useEffect(() => {
    if (text.current !== label) {
      text.current = label;
      forceUpdate();
    }
  }, [label]);

  const labelRef = useRef<HTMLParagraphElement>(null);

  const [hover, setHover] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  const isHovered = hover || dropdownOpen || isColumnHighlighted;

  const handleColumnContentClick = () => {
    if (!isNew && !isUpdated) {
      handleColumnClick(isColumnHighlighted);
    }
  };

  const handleColumnContentDoubleClick = () => {
    handleLabelFocus();
  };

  const [handlePreventiveClick, handlePreventiveDoubleClick] =
    useClickPrevention({
      onClick: handleColumnContentClick,
      onDoubleClick: handleColumnContentDoubleClick,
    });

  const handleLabelFocus = () => {
    const element = labelRef.current;
    if (!element) {
      return;
    }
    element.focus();
    if (window.getSelection && document.createRange) {
      const range = document.createRange();
      range.selectNodeContents(element);
      const selection = window.getSelection();
      if (!selection) {
        return;
      }
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  useEffect(() => {
    if (isNew) {
      handleLabelFocus();
    }
  }, [isNew]);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const onDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const onDropdownClose = () => {
    setDropdownOpen(false);
  };

  const handleInput: FormEventHandler<HTMLParagraphElement> = (event) => {
    text.current = event.currentTarget.textContent || '';
  };

  const handleBlur = () => {
    if (label !== text.current) {
      handleUpdate(text.current);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLParagraphElement> = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        labelRef.current?.blur();
    }
  };

  const handleCellContentClick = (value: string, isHighlighted: boolean) => {
    handleCellClick(value, isHighlighted);
  };

  return (
    <StyledColumn $greyOut={isUpdated} $uiError={uiError}>
      <Content
        className={`${TABLE_ROW_CLASS_NAME}-0`}
        $uiError={uiError}
        $highlight={isHovered}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handlePreventiveClick}
        onDoubleClick={handlePreventiveDoubleClick}
        $height={maxRowHeights[0]}
      >
        <LabelParent>
          <Label
            ref={labelRef}
            contentEditable
            suppressContentEditableWarning
            onInput={handleInput}
            onBlur={handleBlur}
            onFocus={handleLabelFocus}
            onKeyDown={handleKeyDown}
          >
            {label}
          </Label>
        </LabelParent>
        {isHovered ? (
          <StyledDropdown
            onOpen={onDropdownOpen}
            onClose={onDropdownClose}
            options={[
              {
                label: t('components.table.actions.edit'),
                icon: IconType.EDIT,
                onClick: handleLabelFocus,
              },
              {
                label: t('components.table.actions.delete'),
                icon: IconType.BIN,
                onClick: handleDelete,
              },
            ]}
          />
        ) : (
          <StyledDot color={color} />
        )}
      </Content>
      {data?.map((label, cellIndex) => {
        const isEmpty = label === DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL;
        const isCellHighlighted = !!highlightedData.find(
          (highlightedItem) => highlightedItem.label === label
        );
        return (
          <Cell
            className={`${TABLE_ROW_CLASS_NAME}-${cellIndex + 1}`}
            key={cellIndex}
            label={label}
            handleClick={() => {
              handleCellContentClick(
                label,
                isCellHighlighted &&
                  !isColumnHighlighted &&
                  !isAnyRowHighlighted
              );
            }}
            highlight={isCellHighlighted}
            isLast={cellIndex === data.length - 1}
            isEmpty={isEmpty}
            uiError={uiError}
            height={maxRowHeights[cellIndex + 1]}
          />
        );
      })}
    </StyledColumn>
  );
};

interface ActionColumnProps {
  handleAdd: VoidFunction;
  uiError: boolean;
  maxRowHeights: number[];
}

export const ActionColumn: FC<ActionColumnProps> = ({
  handleAdd,
  uiError,
  maxRowHeights,
}) => (
  <StyledActionColumn $uiError={uiError}>
    <Content
      className={`${TABLE_ROW_CLASS_NAME}-0`}
      $uiError={uiError}
      $height={maxRowHeights[0]}
    >
      <StyledActionColumnButton
        type={ButtonType.QUATERNARY}
        to="#"
        onClick={handleAdd}
      >
        <StyledActionColumnIcon type={IconType.PLUS} />
      </StyledActionColumnButton>
    </Content>
  </StyledActionColumn>
);

interface NumberColumnProps {
  isEmpty: boolean;
  count: number;
  highlightedElement: TextToTableHighlightedElement;
  handleRowClick: (index: number, isHighlighted: boolean) => void;
  uiError: boolean;
  maxRowHeights: number[];
}

export const NumberColumn: FC<NumberColumnProps> = ({
  isEmpty,
  count,
  highlightedElement,
  handleRowClick,
  uiError,
  maxRowHeights,
}) => (
  <StyledNumberColumn>
    <Content
      className={`${TABLE_ROW_CLASS_NAME}-0`}
      $uiError={uiError}
      $height={maxRowHeights[0]}
    />
    {Array(count)
      .fill('')
      .map((_, index) => {
        const isRowHighlighted =
          highlightedElement?.type === 'row' &&
          highlightedElement?.index === index;
        return (
          <Cell
            key={index}
            className={`${TABLE_ROW_CLASS_NAME}-${index + 1}`}
            label={(index + 1).toString()}
            handleClick={() => {
              handleRowClick(index, isRowHighlighted);
            }}
            highlight={isRowHighlighted}
            isLast={index === count - 1}
            isEmpty={isEmpty}
            uiError={uiError}
            height={maxRowHeights[index + 1]}
          />
        );
      })}
  </StyledNumberColumn>
);
