import { FC, FormEventHandler, useRef } from 'react';

import {
  StyledPendingItem,
  Details,
  LabelWrapper,
  Number,
  LabelParent,
  Label,
  Action,
} from './styled';

interface Props {
  number: number;
  label: string;
  handleRemove: VoidFunction;
  handleUpdate: (newLabel: string) => void;
}

const PendingItem: FC<Props> = ({
  number,
  label,
  handleRemove,
  handleUpdate,
}) => {
  const text = useRef(label);

  const handleInput: FormEventHandler<HTMLParagraphElement> = (event) => {
    text.current = event.currentTarget.textContent || '';
  };

  const handleBlur = () => {
    handleUpdate(text.current);
  };

  return (
    <StyledPendingItem>
      <Details>
        <LabelWrapper>
          <Number>{number}.</Number>
          <LabelParent>
            <Label
              contentEditable
              suppressContentEditableWarning
              onInput={handleInput}
              onBlur={handleBlur}
            >
              {text.current}
            </Label>
          </LabelParent>
        </LabelWrapper>
        <Action onClick={handleRemove}>-</Action>
      </Details>
    </StyledPendingItem>
  );
};

export default PendingItem;
