import validator from 'validator';

import { NEW_LINE_SYMBOL, TAB_SYMBOL } from '@components/Highlighter/defaults';
import { DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL } from '@components/Table/defaults';
import {
  colorPalette,
  getColumnColor,
  getMaxElements,
} from '@components/Table/utils';
import { TextToTableData, UIModel } from '@store/user/types';
import { Color } from '@theme/colors';

import {
  TITLE_VERSION,
  TITLE_VERSION_STEP,
  TEXT_TO_TABLE_COLUMN_NAME_MAX_LENGTH,
  URL_LIKE_REGEX,
} from './defaults';
import {
  ContentLimit,
  ContentLimitType,
  NamedEntityRecognitionTextTopic,
  RelationExtractionTextTopic,
  TextClassificationTextTopic,
  TextToTableColumn,
  TextToTableHighlightedData,
  TextToTableSampleText,
  TextToTableTextTopic,
  ValidateTextResult,
} from './types';

export const mapRelationExtractionTextTopicToTitleMessageId: Record<
  RelationExtractionTextTopic,
  string
> = {
  [RelationExtractionTextTopic.SCIENTIFIC]:
    'textTopics.relationExtraction.scientific.title',
  [RelationExtractionTextTopic.TECHNOLOGY]:
    'textTopics.relationExtraction.technology.title',
  [RelationExtractionTextTopic.FINANCE]:
    'textTopics.relationExtraction.finance.title',
  [RelationExtractionTextTopic.BUSINESS]:
    'textTopics.relationExtraction.business.title',
  [RelationExtractionTextTopic.HEALTHCARE]:
    'textTopics.relationExtraction.healthcare.title',
  [RelationExtractionTextTopic.SOCIAL]:
    'textTopics.relationExtraction.social.title',
};

export const mapRelationExtractionTextTopicToContentMessageId: Record<
  RelationExtractionTextTopic,
  string
> = {
  [RelationExtractionTextTopic.SCIENTIFIC]:
    'textTopics.relationExtraction.scientific.content',
  [RelationExtractionTextTopic.TECHNOLOGY]:
    'textTopics.relationExtraction.technology.content',
  [RelationExtractionTextTopic.FINANCE]:
    'textTopics.relationExtraction.finance.content',
  [RelationExtractionTextTopic.BUSINESS]:
    'textTopics.relationExtraction.business.content',
  [RelationExtractionTextTopic.HEALTHCARE]:
    'textTopics.relationExtraction.healthcare.content',
  [RelationExtractionTextTopic.SOCIAL]:
    'textTopics.relationExtraction.social.content',
};

export const mapRelationExtractionTextTopicToDataMessageId: Record<
  RelationExtractionTextTopic,
  string
> = {
  [RelationExtractionTextTopic.SCIENTIFIC]:
    'textTopics.relationExtraction.scientific.data',
  [RelationExtractionTextTopic.TECHNOLOGY]:
    'textTopics.relationExtraction.technology.data',
  [RelationExtractionTextTopic.FINANCE]:
    'textTopics.relationExtraction.finance.data',
  [RelationExtractionTextTopic.BUSINESS]:
    'textTopics.relationExtraction.business.data',
  [RelationExtractionTextTopic.HEALTHCARE]:
    'textTopics.relationExtraction.healthcare.data',
  [RelationExtractionTextTopic.SOCIAL]:
    'textTopics.relationExtraction.social.data',
};

export const mapNamedEntityRecognitionTextTopicToTitleMessageId: Record<
  NamedEntityRecognitionTextTopic,
  string
> = {
  [NamedEntityRecognitionTextTopic.LIFE_SCIENCES]:
    'textTopics.namedEntityRecognition.lifeSciences.title',
  [NamedEntityRecognitionTextTopic.NEWS]:
    'textTopics.namedEntityRecognition.news.title',
  [NamedEntityRecognitionTextTopic.USER_REVIEW]:
    'textTopics.namedEntityRecognition.userReview.title',
};

export const mapNamedEntityRecognitionTextTopicToContentMessageId: Record<
  NamedEntityRecognitionTextTopic,
  string
> = {
  [NamedEntityRecognitionTextTopic.LIFE_SCIENCES]:
    'textTopics.namedEntityRecognition.lifeSciences.content',
  [NamedEntityRecognitionTextTopic.NEWS]:
    'textTopics.namedEntityRecognition.news.content',
  [NamedEntityRecognitionTextTopic.USER_REVIEW]:
    'textTopics.namedEntityRecognition.userReview.content',
};

export const mapNamedEntityRecognitionTextTopicToLabelsMessageId: Record<
  NamedEntityRecognitionTextTopic,
  string
> = {
  [NamedEntityRecognitionTextTopic.LIFE_SCIENCES]:
    'textTopics.namedEntityRecognition.lifeSciences.labels',
  [NamedEntityRecognitionTextTopic.NEWS]:
    'textTopics.namedEntityRecognition.news.labels',
  [NamedEntityRecognitionTextTopic.USER_REVIEW]:
    'textTopics.namedEntityRecognition.userReview.labels',
};

export const mapNamedEntityRecognitionTextTopicToDataMessageId: Record<
  NamedEntityRecognitionTextTopic,
  string
> = {
  [NamedEntityRecognitionTextTopic.LIFE_SCIENCES]:
    'textTopics.namedEntityRecognition.lifeSciences.data',
  [NamedEntityRecognitionTextTopic.NEWS]:
    'textTopics.namedEntityRecognition.news.data',
  [NamedEntityRecognitionTextTopic.USER_REVIEW]:
    'textTopics.namedEntityRecognition.userReview.data',
};

export const mapTextClassificationTextTopicToTitleMessageId: Record<
  TextClassificationTextTopic,
  string
> = {
  [TextClassificationTextTopic.INTENT_RECOGNITION]:
    'textTopics.textClassification.intentRecognition.title',
  [TextClassificationTextTopic.SENTIMENT_ANALYSIS]:
    'textTopics.textClassification.sentimentAnalysis.title',
  [TextClassificationTextTopic.INFLUENCER_QUEALIFICATION]:
    'textTopics.textClassification.influencerQualification.title',
  [TextClassificationTextTopic.CONTENT_TAGGING]:
    'textTopics.textClassification.contentTagging.title',
  [TextClassificationTextTopic.LIFE_SCIENCES]:
    'textTopics.textClassification.lifeSciences.title',
};

export const mapTextClassificationTextTopicToContentMessageId: Record<
  TextClassificationTextTopic,
  string
> = {
  [TextClassificationTextTopic.INTENT_RECOGNITION]:
    'textTopics.textClassification.intentRecognition.content',
  [TextClassificationTextTopic.SENTIMENT_ANALYSIS]:
    'textTopics.textClassification.sentimentAnalysis.content',
  [TextClassificationTextTopic.INFLUENCER_QUEALIFICATION]:
    'textTopics.textClassification.influencerQualification.content',
  [TextClassificationTextTopic.CONTENT_TAGGING]:
    'textTopics.textClassification.contentTagging.content',
  [TextClassificationTextTopic.LIFE_SCIENCES]:
    'textTopics.textClassification.lifeSciences.content',
};

export const mapTextClassificationTextTopicToLabelsMessageId: Record<
  TextClassificationTextTopic,
  string
> = {
  [TextClassificationTextTopic.INTENT_RECOGNITION]:
    'textTopics.textClassification.intentRecognition.labels',
  [TextClassificationTextTopic.SENTIMENT_ANALYSIS]:
    'textTopics.textClassification.sentimentAnalysis.labels',
  [TextClassificationTextTopic.INFLUENCER_QUEALIFICATION]:
    'textTopics.textClassification.influencerQualification.labels',
  [TextClassificationTextTopic.CONTENT_TAGGING]:
    'textTopics.textClassification.contentTagging.labels',
  [TextClassificationTextTopic.LIFE_SCIENCES]:
    'textTopics.textClassification.lifeSciences.labels',
};

export const mapTextClassificationTextTopicToDataMessageId: Record<
  TextClassificationTextTopic,
  string
> = {
  [TextClassificationTextTopic.INTENT_RECOGNITION]:
    'textTopics.textClassification.intentRecognition.data',
  [TextClassificationTextTopic.SENTIMENT_ANALYSIS]:
    'textTopics.textClassification.sentimentAnalysis.data',
  [TextClassificationTextTopic.INFLUENCER_QUEALIFICATION]:
    'textTopics.textClassification.influencerQualification.data',
  [TextClassificationTextTopic.CONTENT_TAGGING]:
    'textTopics.textClassification.contentTagging.data',
  [TextClassificationTextTopic.LIFE_SCIENCES]:
    'textTopics.textClassification.lifeSciences.data',
};

export const mapTextToTableTextTopicToTitleMessageId: Record<
  TextToTableTextTopic,
  string
> = {
  [TextToTableTextTopic.LiFE_SCIENCES]:
    'textTopics.textToTable.lifeSciences.title',
  [TextToTableTextTopic.NEWS]: 'textTopics.textToTable.news.title',
  [TextToTableTextTopic.USER_REVIEW]: 'textTopics.textToTable.userReview.title',
};

export const mapTextToTableTextTopicToContentMessageId: Record<
  TextToTableTextTopic,
  string
> = {
  [TextToTableTextTopic.LiFE_SCIENCES]:
    'textTopics.textToTable.lifeSciences.content',
  [TextToTableTextTopic.NEWS]: 'textTopics.textToTable.news.content',
  [TextToTableTextTopic.USER_REVIEW]:
    'textTopics.textToTable.userReview.content',
};

export const mapTextToTableTextTopicToColumnsMessageId: Record<
  TextToTableTextTopic,
  string
> = {
  [TextToTableTextTopic.LiFE_SCIENCES]:
    'textTopics.textToTable.lifeSciences.columns',
  [TextToTableTextTopic.NEWS]: 'textTopics.textToTable.news.columns',
  [TextToTableTextTopic.USER_REVIEW]:
    'textTopics.textToTable.userReview.columns',
};

export const mapTextToTableTextTopicToDataMessageId: Record<
  TextToTableTextTopic,
  string
> = {
  [TextToTableTextTopic.LiFE_SCIENCES]:
    'textTopics.textToTable.lifeSciences.data',
  [TextToTableTextTopic.NEWS]: 'textTopics.textToTable.news.data',
  [TextToTableTextTopic.USER_REVIEW]: 'textTopics.textToTable.userReview.data',
};

export const mapModelToContentLimit: Record<UIModel, ContentLimit> = {
  [UIModel.RELATION_EXTRACTION]: {
    type: ContentLimitType.WORDS,
    min: 3,
    max: 1000,
    truncate: true,
  },
  [UIModel.NAMED_ENTITY_RECOGNITION]: {
    type: ContentLimitType.WORDS,
    min: 1,
    max: 1000,
    truncate: true,
  },
  [UIModel.TEXT_CLASSIFICATION]: {
    type: ContentLimitType.WORDS,
    min: 1,
    max: 1000,
    truncate: true,
  },
  [UIModel.TEXT_TO_TABLE]: {
    type: ContentLimitType.WORDS,
    min: 1,
    max: 1000,
    truncate: true,
  },
};

export const generateTextTitle = (
  textTitles: string[],
  version = TITLE_VERSION,
  step = TITLE_VERSION_STEP
): string => {
  const newTitle = `Custom_${version}`;
  const newTitleExists = !!textTitles.find((title) => title === newTitle);
  if (newTitleExists) {
    return generateTextTitle(textTitles, version + step, step);
  }
  return newTitle;
};

export const countWords = (text: string) => (text ? text.split(' ').length : 0);

export const countChars = (text: string) => text.length;

export const removeExcessiveWords = (text: string, maxWords: number) =>
  text.split(' ').splice(0, maxWords).join(' ');

export const removeExcessiveChars = (text: string, maxChars: number) =>
  text.substring(0, maxChars);

export const validateText = (
  text: string,
  cotnentLimit: ContentLimit
): ValidateTextResult => {
  if (!text) {
    return {
      text,
      isValid: false,
    };
  }
  const { type, min, max, truncate } = cotnentLimit;
  if (type === ContentLimitType.WORDS) {
    const wordsCount = countWords(text);
    if (wordsCount < min) {
      return {
        text,
        isValid: false,
      };
    }
    if (wordsCount > max) {
      if (truncate) {
        return {
          text: removeExcessiveWords(text, max),
          isValid: true,
        };
      }
      return {
        text,
        isValid: false,
      };
    }
    return {
      text,
      isValid: true,
    };
  }
  const charsCount = countChars(text);
  if (charsCount < min) {
    return {
      text,
      isValid: false,
    };
  }
  if (charsCount > max) {
    if (truncate) {
      return {
        text: removeExcessiveChars(text, max),
        isValid: true,
      };
    }
    return {
      text,
      isValid: false,
    };
  }
  return {
    text,
    isValid: true,
  };
};

export const validateURL = (value: string) => ({
  isLike: URL_LIKE_REGEX.test(value),
  isValid: validator.isURL(value),
});

export const validateTextToTableColumnName = (
  value: string,
  maxLength = TEXT_TO_TABLE_COLUMN_NAME_MAX_LENGTH
) => value.length <= maxLength;

export const mapTableDataToExportFormat = (text: TextToTableSampleText) => {
  const { columns, data } = text!;
  const maxElements = getMaxElements(data);
  const table = [columns.map((column) => column.label)];
  for (let i = 0; i < maxElements; i++) {
    table.push(columns.map((column) => data[column.label]?.[i]));
  }
  return table;
};

export const mapTableDataToCopyFormat = (text: TextToTableSampleText) => {
  const table = mapTableDataToExportFormat(text);
  return table.map((row) => row.join(TAB_SYMBOL)).join(NEW_LINE_SYMBOL);
};

export const downloadJSONFile = (data: object) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${new Date().getTime()}.json`;
  link.click();
};

export const validateIfTextToTableDataIsEmpty = (data: TextToTableData) =>
  Object.keys(data).every((columnLabel) =>
    validateIfTextToTableColumnIsEmpty(columnLabel, data)
  );

export const validateIfTextToTableColumnIsEmpty = (
  columnLabel: string,
  data: TextToTableData
) =>
  data[columnLabel].every(
    (item) => item === DEFAULT_TEXT_TO_TABLE_COLUMN_CELL_FILL
  );

export const validateIfTextToTableColumnsAreDefault = (
  columns: TextToTableColumn[],
  defaultColumns: TextToTableColumn[]
) =>
  columns.length === defaultColumns.length &&
  columns.every((column) =>
    defaultColumns.find((defaultColumn) => column.label === defaultColumn.label)
  );

export const getRowHighlightedData = (
  columns: TextToTableColumn[],
  data: TextToTableData,
  rowIndex: number
): TextToTableHighlightedData[] =>
  Object.keys(data)
    .filter((columnLabel) => {
      const column = columns.find((column) => column.label === columnLabel);
      if (!column) {
        return false;
      }
      return !validateIfTextToTableColumnIsEmpty(column.label, data);
    })
    .map((columnLabel) => ({
      column: columnLabel,
      label: data[columnLabel][rowIndex],
      color: getColumnColor(
        columns.findIndex((column) => column.label === columnLabel),
        colorPalette
      ),
    }));

export const getColumnHighlightedData = (
  columnLabel: string,
  data: TextToTableData,
  color: Color
): TextToTableHighlightedData[] =>
  (data[columnLabel] || []).map((item) => ({
    column: columnLabel,
    label: item,
    color,
  }));
