import { DragEventHandler, FC, MouseEventHandler, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UIError } from '@store/ui/types';

import File from './File';
import FileText from './FileText';
import UrlText from './UrlText';
import {
  StyledFileInput,
  StyledDropzone,
  FilesWrapper,
  EmptyText,
  Text,
} from './styled';

interface Props {
  files: File[];
  onFilesAccepted: (files: File[]) => void;
  handleRemove: (file: File) => void;
  handleEdit: VoidFunction;
  isDragActive: boolean;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  onDrop: VoidFunction;
  uiErrors: UIError[];
}

const FileInput: FC<Props> = ({
  files,
  onFilesAccepted,
  handleRemove,
  handleEdit,
  isDragActive,
  onDragEnter,
  onDragLeave,
  onDrop,
  uiErrors,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const handleFileClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    inputRef.current?.click();
  };

  const uiError =
    uiErrors.includes(UIError.GENERAL_TEXT_EMPTY) ||
    uiErrors.includes(UIError.FILE_INPUT_ERROR);
  return (
    <StyledFileInput onClick={handleEdit}>
      <StyledDropzone
        inputRef={inputRef}
        $isDragActive={isDragActive}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onFilesAccepted={onFilesAccepted}
        uiErrors={uiErrors}
      >
        {files.length > 0 ? (
          <FilesWrapper>
            {files.map((file) => (
              <File
                key={file.name}
                value={file}
                handleRemove={(e) => {
                  e.stopPropagation();
                  handleRemove(file);
                }}
              />
            ))}
          </FilesWrapper>
        ) : (
          <EmptyText $uiError={uiError}>
            {isDragActive ? (
              <FileText value={t('components.fileInput.file.dragActive')} />
            ) : (
              <Trans
                i18nKey="components.fileInput.title"
                components={{
                  text: <Text $uiError={uiError} />,
                  url: (
                    <UrlText
                      value={t('components.fileInput.url')}
                      uiError={uiError}
                    />
                  ),
                  file: (
                    <FileText
                      value={t('components.fileInput.file.default')}
                      onClick={handleFileClick}
                      uiError={uiError}
                    />
                  ),
                }}
              />
            )}
          </EmptyText>
        )}
      </StyledDropzone>
    </StyledFileInput>
  );
};

export default FileInput;
