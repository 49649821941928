export const NAMED_ENTITY_RECOGNITION_PROCESS =
  'NAMED_ENTITY_RECOGNITION_PROCESS';
export const NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED =
  'NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED';
export const NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED =
  'NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED';

export const TEXT_CLASSIFICATION_PROCESS = 'TEXT_CLASSIFICATION_PROCESS';
export const TEXT_CLASSIFICATION_PROCESS_FULFILLED =
  'TEXT_CLASSIFICATION_PROCESS_FULFILLED';
export const TEXT_CLASSIFICATION_PROCESS_REJECTED =
  'TEXT_CLASSIFICATION_PROCESS_REJECTED';

export const TEXT_TO_TABLE_PROCESS = 'TEXT_TO_TABLE_PROCESS';
export const TEXT_TO_TABLE_PROCESS_FULFILLED =
  'TEXT_TO_TABLE_PROCESS_FULFILLED';
export const TEXT_TO_TABLE_PROCESS_REJECTED = 'TEXT_TO_TABLE_PROCESS_REJECTED';

export const RELATION_EXTRACTION_PROCESS = 'RELATION_EXTRACTION_PROCESS';
export const RELATION_EXTRACTION_PROCESS_FULFILLED =
  'RELATION_EXTRACTION_PROCESS_FULFILLED';
export const RELATION_EXTRACTION_PROCESS_REJECTED =
  'RELATION_EXTRACTION_PROCESS_REJECTED';

export const CLEAR_MODELS = 'CLEAR_MODELS';
