import styled from 'styled-components';

import { TextStatus } from '@containers/Processor/types';
import Link from '@components/Link';

export const StyledHeader = styled.div<{
  $textStatus: TextStatus;
  $uiError: boolean;
}>`
  padding: 0 2.5rem;
  border-bottom-width: 1px;
  border-bottom-style: ${(props) =>
    props.$uiError
      ? props.theme.processor.errorBorderStyle
      : props.theme.processor[props.$textStatus].borderStyle};
  border-bottom-color: ${(props) =>
    props.$uiError
      ? props.theme.processor.errorBorderColor
      : props.theme.processor[props.$textStatus].borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.theme.processor.header.height}rem;
  overflow-x: auto;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-left: 4rem;
`;

export const ClearAll = styled(Link)`
  white-space: nowrap;
  color: ${(props) => props.theme.processor.header.textColor};
`;

export const EntitiesWrapper = styled.div`
  margin-right: 5rem;
  flex: auto;
`;

export const Topic = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.p`
  margin-right: 2rem;
  white-space: nowrap;
  font-size: 2rem;
`;
