import { Dispatch } from 'redux';

import {
  ADD_UI_ERROR,
  CLEAR_UI_ERRORS,
  CLOSE_TUTORIAL,
  OPEN_TUTORIAL,
  REMOVE_UI_ERROR,
  SET_TUTORIAL_STEP_INDEX,
} from './actionTypes';
import {
  AddUIError,
  ClearUIErrors,
  CloseTutorial,
  OpenTutorial,
  RemoveUIError,
  SetTutorialStepIndex,
  UIError,
} from './types';

export const openTutorial = (index = 0) => {
  return async (dispatch: Dispatch) => {
    dispatch<OpenTutorial>({
      type: OPEN_TUTORIAL,
      payload: index,
    });
  };
};

export const setTutorialStepIndex = (index: number) => {
  return async (dispatch: Dispatch) => {
    dispatch<SetTutorialStepIndex>({
      type: SET_TUTORIAL_STEP_INDEX,
      payload: index,
    });
  };
};

export const closeTutorial = () => {
  return async (dispatch: Dispatch) => {
    dispatch<CloseTutorial>({
      type: CLOSE_TUTORIAL,
    });
  };
};

export const addUIError = (error: UIError) => {
  return async (dispatch: Dispatch) => {
    dispatch<AddUIError>({
      type: ADD_UI_ERROR,
      payload: error,
    });
  };
};

export const removeUIError = (error: UIError) => {
  return async (dispatch: Dispatch) => {
    dispatch<RemoveUIError>({
      type: REMOVE_UI_ERROR,
      payload: error,
    });
  };
};

export const clearUIErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch<ClearUIErrors>({
      type: CLEAR_UI_ERRORS,
    });
  };
};
