import { FC, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { mapModelToReadableName } from '@components/Header/utils';
import Modal from '@components/Modal';
import Stepper from '@components/Stepper';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { UIModel } from '@store/user/types';
import { closeTutorial, openTutorial } from '@store/ui/actions';
import { getTutorialSelector } from '@store/ui/selectors';
import { finishTutorial, isTutorialFinished } from '@store/ui/utils';

import { getSteps } from './data';

interface Props extends PropsWithChildren {
  model: UIModel;
}

const Tutorial: FC<Props> = ({ model, children }) => {
  const dispatch = useAppDispatch();

  const tutorial = useAppSelector(getTutorialSelector);

  const { t } = useTranslation();

  useEffect(() => {
    const isFinished = isTutorialFinished(model);
    if (!isFinished) {
      dispatch(openTutorial());
    }
  }, [model]);

  const handleFinish = () => {
    dispatch(closeTutorial());
    finishTutorial(model);
  };

  if (!tutorial.opened) {
    return <>{children}</>;
  }

  const steps = getSteps(t, model);
  return (
    <>
      {children}
      <Modal isOpen={tutorial.opened}>
        <Stepper
          title={`${t(mapModelToReadableName[model])} - ${t(
            'components.stepper.hint'
          )}`}
          steps={steps}
          handleFinish={handleFinish}
        />
      </Modal>
    </>
  );
};

export default Tutorial;
