import { ReactSVG } from 'react-svg';
import { useTheme } from 'styled-components';

import { convertRemToPx } from '@utils/css';

import { LOGO_IMAGE_PATH } from './defaults';

const Logo = () => {
  const theme = useTheme();

  const { width, height } = theme.logo;
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.setAttribute(
          'style',
          `display: flex; width: ${convertRemToPx(
            width
          )}px; height: ${convertRemToPx(height)}px`
        );
      }}
      src={LOGO_IMAGE_PATH}
    />
  );
};

export default Logo;
