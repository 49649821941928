import { CORE_FONT_SIZE_PX } from '@theme/defaults';

export const convertRemToPx = (value: number) => {
  const htmlElements = document.getElementsByTagName('html');
  if (htmlElements.length === 0) {
    return value * CORE_FONT_SIZE_PX;
  }
  const htmlElement = htmlElements[0];
  /* For Tests */
  if (htmlElement.innerHTML === '<head></head><body></body>') {
    return value * CORE_FONT_SIZE_PX;
  }
  const { fontSize } = window.getComputedStyle(htmlElement);
  const intFontSize = parseInt(fontSize, 10);
  return value * (intFontSize > 10 ? 10 : intFontSize);
};

export const convertPxToRem = (value: number) => {
  const htmlElements = document.getElementsByTagName('html');
  if (htmlElements.length === 0) {
    return value / CORE_FONT_SIZE_PX;
  }
  const htmlElement = htmlElements[0];
  /* For Tests */
  if (htmlElement.innerHTML === '<head></head><body></body>') {
    return value / CORE_FONT_SIZE_PX;
  }
  const { fontSize } = window.getComputedStyle(htmlElement);
  return value / parseInt(fontSize, 10);
};
