import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledHeader,
  Info,
  Actions,
  Title,
  Count,
  StyledButton,
  ButtonContent,
  Text,
  Counter,
} from './styled';

interface Props {
  count: number;
  handleCustomRelationsModalOpen: VoidFunction;
}

const Header: FC<Props> = ({ count, handleCustomRelationsModalOpen }) => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <Info>
        <Title>{t('components.relations.header.title')}</Title>
        <Count>{t('components.relations.header.count', { count })}</Count>
      </Info>
      <Actions>
        <StyledButton to="#" onClick={handleCustomRelationsModalOpen}>
          <ButtonContent>
            <Text>{t('components.relations.header.add')}</Text>
            <Counter>{count}</Counter>
          </ButtonContent>
        </StyledButton>
      </Actions>
    </StyledHeader>
  );
};

export default Header;
