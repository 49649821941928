import { DefaultTheme } from 'styled-components';

import { NotificationType } from '@store/notification/types';
import { Color } from '@theme/colors';

export const notificationTheme: DefaultTheme['notification'] = {
  height: 9,
  zIndex: 9999,
  [NotificationType.INFO]: {
    backgroundColor: Color.HAWKES_BLUE,
    iconColor: Color.CORNFLOWER_BLUE,
    color: Color.CORNFLOWER_BLUE,
    borderColor: Color.CORNFLOWER_BLUE,
  },
  [NotificationType.WARNING]: {
    backgroundColor: Color.HAWKES_BLUE,
    iconColor: Color.CORNFLOWER_BLUE,
    color: Color.CORNFLOWER_BLUE,
    borderColor: Color.CORNFLOWER_BLUE,
  },
  [NotificationType.ERROR]: {
    backgroundColor: Color.CHABLIS,
    iconColor: Color.FLUSH_MAHOGANY,
    color: Color.FLUSH_MAHOGANY,
    borderColor: Color.FLUSH_MAHOGANY,
  },
  [NotificationType.SUCCESS]: {
    backgroundColor: Color.HAWKES_BLUE,
    iconColor: Color.CORNFLOWER_BLUE,
    color: Color.CORNFLOWER_BLUE,
    borderColor: Color.CORNFLOWER_BLUE,
  },
};
