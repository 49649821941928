import styled from 'styled-components';

export const StyledProgress = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBarWrapper = styled.div`
  height: 2.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const ProgressBar = styled.div<{ $active: boolean }>`
  width: ${(props) => (props.$active ? '3.4rem' : '1.2rem')};
  height: 0.8rem;
  border-radius: 0.2rem;
  background-color: ${(props) =>
    props.$active
      ? props.theme.stepper.progress.activeBackgroundColor
      : props.theme.stepper.progress.inactiveBackgroundColor};
`;
