import styled, { css } from 'styled-components';

export const StyledSentence = styled.div<{
  $hidden?: boolean;
}>`
  ${(props) =>
    props.$hidden &&
    css`
      visibility: hidden;
    `};
`;
