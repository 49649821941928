import styled from 'styled-components';

const mapPositionToTop: Record<number, number> = {
  0: 0,
  1: 0.4,
  2: 0.8,
  3: 1.2,
  4: 2,
  5: 2.8,
  6: 4.4,
  7: 6,
};

export const Line = styled.div<{ $position: number }>`
  width: 100%;
  background-color: ${(props) => props.theme.horizontalLines.backgroundColor};
  height: 1px;
  position: absolute;
  top: ${(props) => mapPositionToTop[props.$position]}rem;
`;
