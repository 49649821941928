import { DefaultTheme } from 'styled-components';

import { RelationState } from '@components/Radio/types';
import { Color } from '@theme/colors';

export const relationsTheme: DefaultTheme['relations'] = {
  titleColor: Color.SILVER_CHALICE,
  headerHeight: 7.8,
  maxContentHeight: 45,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  borderColor: Color.TUNDORA,
  counterBorderColor: Color.WHITE,
  countColor: Color.SILVER_CHALICE,
  separatorColor: Color.WHITE,
  typeColor: Color.SILVER_CHALICE,
  hover: {
    backgroundColor: Color.MINE_SHAFT,
  },
  noDataColor: Color.SILVER_CHALICE,
  [RelationState.INACTIVE]: {
    textColor: Color.TRANSPARENT,
    lineColor: Color.TRANSPARENT,
    rectBackgroundColor: Color.TRANSPARENT,
    rectBorderColor: Color.TRANSPARENT,
  },
  [RelationState.ACTIVE]: {
    textColor: Color.TRANSPARENT,
    lineColor: Color.TRANSPARENT,
    rectBackgroundColor: Color.TRANSPARENT,
    rectBorderColor: Color.TRANSPARENT,
  },
  [RelationState.SELECTED]: {
    textColor: Color.MINE_SHAFT,
    lineColor: Color.CORNFLOWER_BLUE,
    rectBackgroundColor: Color.ALTO,
    rectBorderColor: Color.CORNFLOWER_BLUE,
  },
  [RelationState.HOVERED]: {
    textColor: Color.WHITE,
    lineColor: Color.CORNFLOWER_BLUE,
    rectBackgroundColor: Color.CORNFLOWER_BLUE,
    rectBorderColor: Color.CORNFLOWER_BLUE,
  },
};
