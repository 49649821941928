import { DefaultTheme } from 'styled-components';

import { TextStatus } from '@containers/Processor/types';
import { Color } from '@theme/colors';

export const processorTheme: DefaultTheme['processor'] = {
  titleColor: Color.SILVER_CHALICE,
  header: {
    height: 7.8,
    textColor: Color.SILVER_CHALICE,
  },
  entitiesLinkColor: Color.CORNFLOWER_BLUE,
  footerMaxLimitColor: Color.SILVER_CHALICE,
  footerCurrentLimitColor: Color.INDIGO,
  footerLimitErrorColor: Color.FLUSH_MAHOGANY,
  footerHeight: 10,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  selectedSentenceBackgroundColor: Color.MINE_SHAFT,
  errorBorderColor: Color.FLUSH_MAHOGANY,
  errorBorderStyle: 'dashed',
  [TextStatus.EMPTY]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
  [TextStatus.PENDING_ERROR]: {
    borderStyle: 'dashed',
    borderColor: Color.INDIGO,
  },
  [TextStatus.PENDING_VALID]: {
    borderStyle: 'dashed',
    borderColor: Color.INDIGO,
  },
  [TextStatus.READY_FOR_PROCESSING]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
  [TextStatus.PROCESSING_PENDING]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
  [TextStatus.PROCESSING_ERROR]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
  [TextStatus.PROCESSING_SUCCESS]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
  [TextStatus.HIGHLIGHTED]: {
    borderStyle: 'solid',
    borderColor: Color.TUNDORA,
  },
};
