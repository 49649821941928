import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  root: string;
  wrapperElement?: string;
}

const Portal: FC<PropsWithChildren<Props>> = ({
  root,
  wrapperElement = 'div',
  children,
}) => {
  const mount = document.getElementById(root);
  const el = document.createElement(wrapperElement);

  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
    }
    return () => {
      if (mount && mount.hasChildNodes()) {
        mount.removeChild(el);
      }
    };
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
