import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      process.env.NODE_ENV !== 'production' ? [thunk, logger] : [thunk]
    ),
  devTools: process.env.NODE_ENV !== 'production',
});
