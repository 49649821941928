import { FC } from 'react';

import { EntityPosition } from '@components/Relations/Content/Item/types';
import { RelationExtractionData } from '@store/user/types';

import { StyledEntity } from './styled';
import Type from './Type';

interface Props {
  selected: boolean;
  hovered: boolean;
  position: EntityPosition;
  relations: RelationExtractionData[];
  relationId: number;
  handleToggleHoveredRelation?: (relation: RelationExtractionData) => void;
}

const Entity: FC<Props> = ({
  selected,
  hovered,
  position,
  relations,
  relationId,
  handleToggleHoveredRelation,
}) => {
  const foundRelation = relations.find(
    (relation) => relation.id === relationId
  );

  if (!foundRelation) {
    return null;
  }

  const { word, entity, start, end } = foundRelation[position];
  return (
    <StyledEntity
      className={`${start}-${end}`}
      $selected={selected}
      $hovered={hovered}
      $position={position}
      onMouseEnter={() => {
        handleToggleHoveredRelation?.(foundRelation);
      }}
      onMouseLeave={() => {
        handleToggleHoveredRelation?.(foundRelation);
      }}
    >
      {word}
      <Type
        value={entity}
        selected={selected}
        hovered={hovered}
        position={position}
      />
    </StyledEntity>
  );
};

export default Entity;
