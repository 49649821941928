import styled from 'styled-components';

import Tooltip from '@components/Tooltip';

export const StyledHeader = styled.div<{
  $clicked: boolean;
  $uiError: boolean;
}>`
  padding: 0 2.5rem;
  border-bottom-width: 1px;
  border-bottom-style: ${(props) =>
    props.$uiError
      ? props.theme.classes.errorBorderStyle
      : props.$clicked
      ? props.theme.classes.clickedBorderStyle
      : props.theme.classes.borderStyle};
  border-bottom-color: ${(props) =>
    props.$uiError
      ? props.theme.classes.errorBorderColor
      : props.$clicked
      ? props.theme.classes.clickedBorderColor
      : props.theme.classes.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.theme.classes.headerHeight}rem;
`;

export const Title = styled.div`
  font-size: 2rem;
  position: relative;
`;

export const CreatableSpace = styled.div`
  position: relative;
  width: 100%;
  margin-left: 5rem;
`;

export const CreatableWrapper = styled.div`
  position: absolute;
  top: -${(props) => props.theme.creatable.minHeight / 2}rem;
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  position: absolute;
  top: -0.5rem;
  right: -3rem;
`;
