import {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEvent,
  useState,
} from 'react';
import { InputActionMeta } from 'react-select';
import { useTranslation } from 'react-i18next';

import Creatable from '@components/Creatable';
import { MultiCreatableValue } from '@components/Creatable/types';
import { mapToMultiCreatableValue } from '@components/Processor/Header/utils';

import {
  StyledEntities,
  CreatableWrapper,
  StyledLink,
  CreatableSpace,
  Label,
} from './styled';

interface Props {
  initialValue: MultiCreatableValue;
  value: MultiCreatableValue;
  handleChange: (value: MultiCreatableValue) => void;
  uiError: boolean;
}

const Entities: FC<Props> = ({
  initialValue,
  value,
  handleChange,
  uiError,
}) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [defaultValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (
      actionMeta.action !== 'input-blur' &&
      actionMeta.action !== 'menu-close'
    ) {
      setInputValue(value);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        handleChange([...value, ...mapToMultiCreatableValue([inputValue])]);
        setInputValue('');
    }
  };

  const handleResetClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    handleChange(defaultValue);
    setInputValue('');
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
    setFocused(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setFocused(false);
    if (inputValue) {
      handleChange([...value, ...mapToMultiCreatableValue([inputValue])]);
      setInputValue('');
    }
  };

  return (
    <StyledEntities>
      <Label>{t('components.processor.header.entities.label')}</Label>
      <CreatableSpace $focused={focused}>
        <CreatableWrapper>
          <Creatable
            placeholder={t('components.processor.header.entities.placeholder')}
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            value={value}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            focused={focused}
            uiError={uiError}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          />
        </CreatableWrapper>
      </CreatableSpace>
      {value.length > 0 && (
        <StyledLink to="#" onClick={handleResetClick}>
          {t('components.processor.header.entities.reset')}
        </StyledLink>
      )}
    </StyledEntities>
  );
};

export default Entities;
