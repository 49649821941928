import { FC } from 'react';

import { StyledHeader, Title, SubTitle } from './styled';

interface Props {
  title: string;
  subTitle: string;
}

const Header: FC<Props> = ({ title, subTitle }) => (
  <StyledHeader>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
  </StyledHeader>
);

export default Header;
