import styled from 'styled-components';

const mapPositionToLeft: Record<number, number> = {
  0: 10,
  1: 30,
  2: 50,
  3: 70,
  4: 90,
};

export const Line = styled.div<{ $position: number }>`
  height: 100%;
  background-color: ${(props) => props.theme.verticalLines.backgroundColor};
  width: 1px;
  position: fixed;
  left: ${(props) => mapPositionToLeft[props.$position]}%;
  opacity: 0.1;
  z-index: -1;
`;
