import styled from 'styled-components';

import { TextStatus } from '@containers/Processor/types';

export const StyledProcessor = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProcessorContent = styled.div<{
  $textStatus: TextStatus;
  $uiError: boolean;
}>`
  background-color: ${(props) => props.theme.processor.backgroundColor};
  border-width: 1px;
  border-style: ${(props) =>
    props.$uiError
      ? props.theme.processor.errorBorderStyle
      : props.theme.processor[props.$textStatus].borderStyle};
  border-color: ${(props) =>
    props.$uiError
      ? props.theme.processor.errorBorderColor
      : props.theme.processor[props.$textStatus].borderColor};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
`;

export const ProcessorTitle = styled.h2`
  color: ${(props) => props.theme.processor.titleColor};
  font-size: 2.4rem;
  line-height: 5rem;
`;

export const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const ProcessorJsonView = styled.div`
  max-height: 67rem;
  overflow-y: auto;
`;
