import { FC, ReactElement, useEffect, useRef, useState } from 'react';

import { Props as ButtonProps } from '@components/Button/Button';

import { StyledAnimation, Scaler, Text } from './styled';

interface Props {
  children: ReactElement<ButtonProps>;
  play?: boolean;
}

const Animation: FC<Props> = ({ play, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!play) {
      return;
    }

    setTimeout(() => {
      if (!containerRef.current) {
        return;
      }
      setWidth(containerRef.current.getBoundingClientRect().width);
    }, 100);
  }, [play]);

  if (!play) {
    return children;
  }

  const buttonText = children.props.children;

  return (
    <StyledAnimation ref={containerRef} $maxWidth={width}>
      <Scaler />
      <Text>{buttonText}</Text>
      {children}
    </StyledAnimation>
  );
};

export default Animation;
