import {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useRef,
  useState,
} from 'react';
import { InputActionMeta } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Creatable from '@components/Creatable';
import { MultiCreatableValue } from '@components/Creatable/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import { mapToMultiCreatableValue } from '@components/Processor/Header/utils';
import useOnClickOutside from '@hooks/useOnClickOutside';

import {
  StyledHeader,
  Title,
  CreatableSpace,
  CreatableWrapper,
  StyledTooltip,
} from './styled';

interface Props {
  value: MultiCreatableValue;
  handleChange: (value: MultiCreatableValue) => void;
  clicked: boolean;
  toggleClicked: VoidFunction;
  handleUnclick: VoidFunction;
  uiError: boolean;
}

const Header: FC<Props> = ({
  value,
  handleChange,
  clicked,
  toggleClicked,
  handleUnclick,
  uiError,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();
  const theme = useTheme();

  useOnClickOutside(elementRef, handleUnclick);

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (
      actionMeta.action !== 'input-blur' &&
      actionMeta.action !== 'menu-close'
    ) {
      setInputValue(value);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (inputValue) {
          event.preventDefault();
          handleChange([...value, ...mapToMultiCreatableValue([inputValue])]);
          setInputValue('');
        }
    }
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
    toggleClicked();
    setFocused(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setFocused(false);
    if (inputValue) {
      handleChange([...value, ...mapToMultiCreatableValue([inputValue])]);
      setInputValue('');
    }
  };

  return (
    <StyledHeader $clicked={clicked} $uiError={uiError}>
      <Title>
        {t('components.classes.header.title')}
        <StyledTooltip label={t('components.classes.header.tooltip')}>
          <Icon
            type={IconType.HELP_CIRCLE}
            stroke={theme.classes.tooltipIconColor}
            large
          />
        </StyledTooltip>
      </Title>
      <CreatableSpace ref={elementRef}>
        <CreatableWrapper>
          <Creatable
            placeholder={t('components.classes.header.placeholder')}
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            value={value}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            focused={focused}
            uiError={uiError}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          />
        </CreatableWrapper>
      </CreatableSpace>
    </StyledHeader>
  );
};

export default Header;
