import { FC, MouseEventHandler, ReactElement } from 'react';

import { IconProps } from '@components/Icon/types';

import { StyledIconButton } from './styled';
import { IconButtonType } from './types';

export interface Props {
  type?: IconButtonType;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children: ReactElement<IconProps>;
}

const IconButton: FC<Props> = ({
  type = IconButtonType.PRIMARY,
  className,
  onClick,
  children,
}) => (
  <StyledIconButton className={className} $type={type} onClick={onClick}>
    {children}
  </StyledIconButton>
);

export default IconButton;
