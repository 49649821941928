import styled from 'styled-components';

import Link from '@components/Link';

export const StyledFooter = styled.div`
  position: relative;
`;

export const Content = styled.div`
  padding: 9rem 6.5rem;
`;

export const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SectionBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Brand = styled(Link)`
  font-size: 2.4rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;

export const Year = styled.span``;

export const Social = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-align: center;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Policy = styled.div`
  display: flex;
  align-items: center;
`;
