import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const benchmarkTheme: DefaultTheme['benchmark'] = {
  headerHeight: 7.8,
  chartHeight: 36,
  backgroundColor: Color.MINE_SHAFT_MEDIUM,
  borderColor: Color.TUNDORA,
  mainColor: Color.SILVER_CHALICE,
  dotColor: Color.CORNFLOWER_BLUE,
  activeDotColor: Color.PIZZA,
  lineColor: Color.CORNFLOWER_BLUE,
  labelColor: Color.WHITE,
  tooltipBackgroundColor: Color.WHITE,
  tooltipColor: Color.MINE_SHAFT,
  tooltipIconColor: Color.CORNFLOWER_BLUE,
  tooltipIconHoverColor: Color.INDIGO,
};
