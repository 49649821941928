import styled, { css } from 'styled-components';

export const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.toggle.borderColor};
  border-radius: 0.8rem;
  padding: 0.75rem;
`;

export const ToggleElement = styled.div<{ $active: boolean }>`
  color: ${(props) => props.theme.toggle.color};
  cursor: pointer;
  border-radius: 0.8rem;
  padding: 0.5rem 1.5rem;

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) => props.theme.toggle.active.backgroundColor};
      color: ${(props) => props.theme.toggle.active.color};
    `};
`;
