import Layout from '@components/Layout/Layout';
import { useTranslation } from 'react-i18next';

import { StyledNotFound, Title } from './styled';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <StyledNotFound>
      <Layout>{() => <Title>{t('pages.notFound.title')}</Title>}</Layout>
    </StyledNotFound>
  );
};

export default NotFound;
