import { UIModel } from '@store/user/types';

import {
  namedEntityRecognitionCostDataPoints,
  namedEntityRecognitionPrecisionDataPoints,
  textClassificationCostDataPoints,
  textClassificationPrecisionDataPoints,
} from './data';
import { BarChartType, DataPoint } from './types';

export const mapBarChartTypeToTitle: Record<BarChartType, string> = {
  [BarChartType.PRECISION]: 'components.barCharts.precision.title',
  [BarChartType.COST]: 'components.barCharts.cost.title',
};

export const mapBarChartTypeToDomain: Record<BarChartType, [number, number]> = {
  [BarChartType.PRECISION]: [0, 1],
  [BarChartType.COST]: [0, 0],
};

export const mapBarChartTypeToTickFormatter: Record<
  BarChartType,
  (value: any) => string
> = {
  [BarChartType.PRECISION]: (value) => `${value * 100}%`,
  [BarChartType.COST]: (value) => `$${value}`,
};

export const mapModelToBarChartTypeDataPoints: Record<
  UIModel,
  Record<BarChartType, DataPoint[]>
> = {
  [UIModel.NAMED_ENTITY_RECOGNITION]: {
    [BarChartType.PRECISION]: namedEntityRecognitionPrecisionDataPoints,
    [BarChartType.COST]: namedEntityRecognitionCostDataPoints,
  },
  [UIModel.TEXT_CLASSIFICATION]: {
    [BarChartType.PRECISION]: textClassificationPrecisionDataPoints,
    [BarChartType.COST]: textClassificationCostDataPoints,
  },
  [UIModel.TEXT_TO_TABLE]: {
    [BarChartType.PRECISION]: [],
    [BarChartType.COST]: [],
  },
  [UIModel.RELATION_EXTRACTION]: {
    [BarChartType.PRECISION]: [],
    [BarChartType.COST]: [],
  },
};
