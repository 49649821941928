import { v4 as uuidv4 } from 'uuid';

import { MultiCreatableValue } from '@components/Creatable/types';
import { SelectOption, SelectOptionsOrGroups } from '@components/Select/types';
import {
  SampleText,
  TextToTableColumn,
  TextType,
} from '@containers/Processor/types';

export const mapToMultiCreatableValue = (
  values: string[]
): MultiCreatableValue =>
  values.map((value) => ({
    label: value,
    value: `${value}-${uuidv4()}`,
  }));

export const mapTextsToSelectOptions = (
  texts: SampleText[]
): SelectOptionsOrGroups => {
  const customGroup: SelectOption[] = [];
  const sampleGroup: SelectOption[] = [];

  texts.forEach(({ type, title }, index) => {
    if (type === TextType.CUSTOM) {
      customGroup.unshift({
        label: title,
        value: JSON.stringify({
          index,
          value: `${title}-${uuidv4()}`,
        }),
      });
    } else {
      sampleGroup.push({
        label: title,
        value: JSON.stringify({
          index,
          value: `${title}-${uuidv4()}`,
        }),
      });
    }
  });

  return [
    {
      label: '',
      options: customGroup,
    },
    {
      label: '',
      options: sampleGroup,
    },
  ];
};

export const mapToTextToTableColumn = (values: string[]): TextToTableColumn[] =>
  values.map((value) => ({
    label: value,
    value: `${value}-${uuidv4()}`,
    updated: false,
    new: false,
  }));
