import styled from 'styled-components';

export const StyledHeader = styled.div`
  padding: 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.barChart.borderColor};
`;

export const Title = styled.p`
  font-size: 2rem;
  text-transform: uppercase;
`;

export const SubTitle = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.barChart.mainColor};
  margin-top: 0.5rem;
`;
