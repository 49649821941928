import styled from 'styled-components';

export const StyledAnimation = styled.div<{ $maxWidth: number }>`
  position: relative;
  border-radius: 0.8rem;
  overflow: hidden;
  pointer-events: none;

  @keyframes scaleAnimation {
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(${(props) => props.$maxWidth});
    }
    100% {
      transform: scaleX(0);
    }
  }
`;

export const Scaler = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  background-color: ${(props) => props.theme.animation.backgroundColor};
  animation-name: scaleAnimation;
  animation-duration: ${(props) => props.theme.animation.duration}s;
  animation-iteration-count: infinite;
`;

export const Text = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
`;
