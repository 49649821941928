import styled, { css } from 'styled-components';

import IconButton from '@components/IconButton';

export const StyledContent = styled.div`
  max-height: ${(props) => props.theme.customRelations.tableMaxHeight}rem;
  overflow-y: auto;
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.customRelations.tableBorderColor};
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.theme.customRelations.tableHeaderBackgroundColor};
  color: ${(props) => props.theme.customRelations.tableStaticTextColor};
  border-bottom: 1px solid
    ${(props) => props.theme.customRelations.tableBorderColor};
`;

export const TableColumn = styled.div<{
  $xs?: boolean;
  $s?: boolean;
  $m?: boolean;
  $l?: boolean;
  $center?: boolean;
}>`
  padding: 1.5rem;

  &:not(:last-child) {
    border-right: 1px solid
      ${(props) => props.theme.customRelations.tableBorderColor};
  }

  ${(props) =>
    props.$xs
      ? css`
          flex: 1;
        `
      : props.$s
      ? css`
          flex: 2;
        `
      : props.$m
      ? css`
          flex: 4;
        `
      : props.$l
      ? css`
          flex: 10;
        `
      : ''};

  ${(props) =>
    props.$center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export const StyledIconButton = styled(IconButton)`
  padding: 1rem;
`;

export const TableRow = styled.div`
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${(props) => props.theme.customRelations.tableBorderColor};
  }
`;

export const NoData = styled.div`
  padding: 6rem;
  font-size: 2.4rem;
  text-align: center;
  color: ${(props) => props.theme.customRelations.tableStaticTextColor};
`;
