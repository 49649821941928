import styled, { css } from 'styled-components';

import Button from '@components/Button';
import Dot from '@components/Dot';
import Dropdown from '@components/Dropdown';
import Icon from '@components/Icon';

const ColumnCommon = css`
  min-height: 100%;
  height: 100%;
`;

export const StyledColumn = styled.div<{
  $greyOut: boolean;
  $uiError: boolean;
}>`
  ${ColumnCommon};
  min-width: 20rem;
  flex: 1;
  border-left-width: 1px;
  border-left-style: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderStyle
      : props.theme.table.borderStyle};
  border-left-color: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderColor
      : props.theme.table.borderColor};

  ${(props) =>
    props.$greyOut &&
    css`
      background-color: ${(props) => props.theme.table.updateBackgroundColor};
    `};
`;

export const StyledActionColumn = styled.div<{ $uiError: boolean }>`
  position: sticky;
  top: -1px;
  right: -1px;
  background-color: ${(props) => props.theme.table.backgroundColor};
  border-left-width: 1px;
  border-left-style: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderStyle
      : props.theme.table.borderStyle};
  border-left-color: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderColor
      : props.theme.table.borderColor};
`;

export const StyledActionColumnIcon = styled(Icon)`
  stroke: ${(props) => props.theme.table.header.action.iconColor};
`;

export const StyledActionColumnButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 1.5rem;

  &:hover {
    ${StyledActionColumnIcon} {
      stroke: ${(props) => props.theme.table.header.action.hovered.iconColor};
    }
  }
`;

export const StyledNumberColumn = styled.div`
  ${ColumnCommon};
`;

export const Content = styled.div<{
  $uiError: boolean;
  $highlight?: boolean;
  $height?: number;
}>`
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-style: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderStyle
      : props.theme.table.borderStyle};
  border-bottom-color: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderColor
      : props.theme.table.borderColor};
  min-height: 7.8rem;
  max-height: calc(7.8rem * 3);

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `};

  ${(props) =>
    props.$highlight &&
    css`
      background-color: ${(props) => props.theme.table.hoverBackgroundColor};
    `};
`;

export const LabelParent = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;

  &:focus-within {
    width: 100%;
  }
`;

export const Label = styled.p`
  outline: none;
  margin-right: 4.25rem;
  color: ${(props) => props.theme.table.headingLabelColor};

  &:focus {
    background-color: ${(props) =>
      props.theme.table.labelEditableBackgroundColor};
  }
`;

export const StyledDot = styled(Dot)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  right: 1rem;
`;
