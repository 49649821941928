import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const headerTheme: DefaultTheme['header'] = {
  height: 9,
  backgroundColor: Color.MINE_SHAFT,
  mobileMenu: {
    backgroundColor: Color.COD_GRAY,
    burgerBarBackgroundColor: Color.WHITE,
  },
};
