import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SampleText, TextStatus } from '@containers/Processor/types';
import { MultiCreatableValue } from '@components/Creatable/types';
import Export from '@components/Export';
import Toggle from '@components/Toggle';
import { Mode } from '@components/Toggle/types';
import JsonView from '@components/JsonView';
import { RelationExtractionData, UIModel } from '@store/user/types';
import { UIError } from '@store/ui/types';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import {
  StyledProcessor,
  ProcessorTitle,
  SectionTop,
  Actions,
  ProcessorContent,
  ProcessorJsonView,
} from './styled';
import { ProcessorMode } from './types';

interface Props {
  model: UIModel;
  texts: SampleText[];
  selectedText: SampleText;
  selectedTextIndex: number;
  handleTextClick: (index: number) => void;
  handleEditTextClick: VoidFunction;
  handleFocusTextClick: VoidFunction;
  handleSubmitTextClick: VoidFunction;
  handleTextContentChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleToggleHoveredRelation: (relation: RelationExtractionData) => void;
  handleChangeNamedEntityRecognitionLabels: (
    value: MultiCreatableValue
  ) => void;
  uiErrors: UIError[];
  handleRemoveLink: VoidFunction;
  onFilesAccepted: (files: File[]) => void;
  handleRemoveFile: (file: File) => void;
  handleDownloadJsonFile: VoidFunction;
  playAnimation: boolean;
  handleProcess: VoidFunction;
  handleClear: VoidFunction;
}

const Processor: FC<Props> = ({
  model,
  texts,
  selectedText,
  selectedTextIndex,
  handleTextClick,
  handleEditTextClick,
  handleFocusTextClick,
  handleSubmitTextClick,
  handleTextContentChange,
  handleToggleHoveredRelation,
  handleChangeNamedEntityRecognitionLabels,
  uiErrors,
  handleRemoveLink,
  onFilesAccepted,
  handleRemoveFile,
  handleDownloadJsonFile,
  playAnimation,
  handleProcess,
  handleClear,
}) => {
  const { t } = useTranslation();

  const [isDragActive, setIsDragActive] = useState(false);
  const [activeMode, setActiveMode] = useState<ProcessorMode>(
    ProcessorMode.DEFAULT
  );

  useEffect(() => {
    setActiveMode(ProcessorMode.DEFAULT);
  }, [model]);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = () => {
    setIsDragActive(false);
  };

  const handleModeChange = (value: ProcessorMode) => {
    setActiveMode(value);
  };

  const modes: Mode<ProcessorMode>[] = useMemo(
    () => [
      {
        title: t('components.processor.mode.default'),
        type: ProcessorMode.DEFAULT,
      },
      {
        title: t('components.processor.mode.json'),
        type: ProcessorMode.JSON,
      },
    ],
    []
  );

  const isEmpty =
    model === UIModel.TEXT_TO_TABLE && selectedText.status === TextStatus.EMPTY;
  const isPending = [
    TextStatus.PENDING_ERROR,
    TextStatus.PENDING_VALID,
  ].includes(selectedText.status);
  return (
    <StyledProcessor>
      <SectionTop>
        <ProcessorTitle>{t('components.processor.title')}</ProcessorTitle>
        {model === UIModel.NAMED_ENTITY_RECOGNITION &&
          selectedText.namedEntityRecognition!.data.length > 0 && (
            <Actions>
              <Export
                model={model}
                handleDownloadJsonFile={handleDownloadJsonFile}
              />
              <Toggle
                active={activeMode}
                onChange={handleModeChange}
                items={modes}
              />
            </Actions>
          )}
      </SectionTop>
      <ProcessorContent
        $textStatus={
          isDragActive ? TextStatus.PENDING_VALID : selectedText.status
        }
        $uiError={
          uiErrors.includes(UIError.GENERAL_TEXT_EMPTY) ||
          uiErrors.includes(UIError.FILE_INPUT_ERROR) ||
          uiErrors.includes(UIError.INVALID_URL_ERROR)
        }
      >
        {activeMode === ProcessorMode.DEFAULT ? (
          <>
            <Header
              model={model}
              texts={texts}
              textStatus={selectedText.status}
              selectedTextIndex={selectedTextIndex}
              handleTextClick={handleTextClick}
              handleChangeNamedEntityRecognitionLabels={
                handleChangeNamedEntityRecognitionLabels
              }
              uiErrors={uiErrors}
              isDragActive={isDragActive}
              playAnimation={playAnimation}
              handleProcess={handleProcess}
              handleClear={handleClear}
            />
            <Content
              value={selectedText.content}
              onChange={handleTextContentChange}
              handleSubmit={handleSubmitTextClick}
              handleEdit={handleEditTextClick}
              handleFocus={handleFocusTextClick}
              isEmpty={isEmpty}
              isPending={isPending}
              selectedRelations={
                selectedText.relationExtraction?.selectedRelations || []
              }
              activeRelations={
                selectedText.relationExtraction?.activeRelations || []
              }
              hoveredRelations={
                selectedText.relationExtraction?.hoveredRelations || []
              }
              handleToggleHoveredRelation={handleToggleHoveredRelation}
              model={model}
              data={selectedText.namedEntityRecognition?.data || []}
              labels={selectedText.namedEntityRecognition?.labels || []}
              highlightedData={selectedText.textToTable?.highlightedData || []}
              isLink={selectedText.link}
              handleRemoveLink={handleRemoveLink}
              files={selectedText.files}
              onFilesAccepted={onFilesAccepted}
              handleRemoveFile={handleRemoveFile}
              isDragActive={isDragActive}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              uiErrors={uiErrors}
            />
            {isPending && (
              <Footer model={model} textContent={selectedText.content} />
            )}
          </>
        ) : (
          <ProcessorJsonView>
            <JsonView value={selectedText.namedEntityRecognition!.data} />
          </ProcessorJsonView>
        )}
      </ProcessorContent>
    </StyledProcessor>
  );
};

export default Processor;
