import styled from 'styled-components';

import { IconButtonType } from './types';

export const StyledIconButton = styled.div<{ $type: IconButtonType }>`
  display: inline-flex;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: ${(props) =>
    props.theme.iconButton[props.$type].backgroundColor};
  border-width: 1px;
  border-style: ${(props) => props.theme.iconButton[props.$type].borderStyle};
  border-color: ${(props) => props.theme.iconButton[props.$type].borderColor};

  &:hover {
    background-color: ${(props) =>
      props.theme.iconButton[props.$type].hover.backgroundColor};
    border-color: ${(props) =>
      props.theme.iconButton[props.$type].hover.borderColor};
  }

  &:active {
    background-color: ${(props) =>
      props.theme.iconButton[props.$type].active.backgroundColor};
    border-color: ${(props) =>
      props.theme.iconButton[props.$type].active.borderColor};
  }
`;
