import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const exportTheme: DefaultTheme['export'] = {
  iconHoverColor: Color.CORNFLOWER_BLUE,
  backgroundColor: Color.MINE_SHAFT,
  borderColor: Color.TUNDORA,
  optionColor: Color.SILVER_CHALICE,
  hover: {
    optionColor: Color.WHITE,
  },
};
