import { FC } from 'react';

import { IconType } from '@components/Icon/types';

import { StyledUrlText, StyledIcon } from './styled';

interface Props {
  value: string;
  uiError: boolean;
}

const UrlText: FC<Props> = ({ value, uiError }) => (
  <StyledUrlText $uiError={uiError}>
    <StyledIcon type={IconType.LINK} $uiError={uiError} />
    {value}
  </StyledUrlText>
);

export default UrlText;
