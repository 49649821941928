import styled from 'styled-components';

export const StyledCustomRelations = styled.div`
  display: flex;
`;

export const HighlighterWrapper = styled.div`
  background-color: ${(props) =>
    props.theme.customRelations.highlighterBackgroundColor};
  border: 1px solid
    ${(props) => props.theme.customRelations.highlighterBorderColor};
  border-radius: 1.5rem;
  flex: 2;
  margin-right: 1.25rem;
`;

export const TableWrapper = styled.div`
  background-color: ${(props) =>
    props.theme.customRelations.tableBackgroundColor};
  border: 1px solid ${(props) => props.theme.customRelations.tableBorderColor};
  border-radius: 1.5rem;
  flex: 3;
  margin-left: 1.25rem;
  padding: 2rem;
`;
