import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const scrollbarTheme: DefaultTheme['scrollbar'] = {
  width: 2,
  height: 2,
  thumb: {
    backgroundColor: Color.CORNFLOWER_BLUE,
  },
  track: {
    backgroundColor: Color.MINE_SHAFT,
  },
};
