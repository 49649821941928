import styled from 'styled-components';

export const StyledTable = styled.div``;

export const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.table.titleColor};
  font-size: 2.4rem;
  line-height: 5rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const TableContent = styled.div<{ $uiError: boolean }>`
  max-height: 67rem;
  overflow: auto;
  display: flex;
  border-width: 1px;
  border-style: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderStyle
      : props.theme.table.borderStyle};
  border-color: ${(props) =>
    props.$uiError
      ? props.theme.table.errorBorderColor
      : props.theme.table.borderColor};
  border-radius: 1.5rem;
  background-color: ${(props) => props.theme.table.backgroundColor};
  position: relative;
`;
