import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Processor from '@containers/Processor';
import BarChart from '@components/BarChart';
import { BarChartType } from '@components/BarChart/types';
import Explore from '@components/Explore';
import Playground from '@components/Playground';
import { UIModel } from '@store/user/types';

import {
  StyledModel,
  StyledBarChartsPlayground,
  BarChartsTitle,
  BarChartsWrapper,
  BarChartComponent,
} from './styled';

interface Props {
  model: UIModel;
}

const Model: FC<Props> = ({ model }) => {
  const { t } = useTranslation();

  return (
    <StyledModel>
      <Playground>
        <Processor model={model} />
      </Playground>
      {[UIModel.TEXT_CLASSIFICATION, UIModel.NAMED_ENTITY_RECOGNITION].includes(
        model
      ) && (
        <StyledBarChartsPlayground>
          <BarChartsTitle>{t('components.barCharts.title')}</BarChartsTitle>
          <BarChartsWrapper>
            <BarChartComponent>
              <BarChart model={model} />
            </BarChartComponent>
            <BarChartComponent>
              <BarChart type={BarChartType.COST} model={model} />
            </BarChartComponent>
          </BarChartsWrapper>
        </StyledBarChartsPlayground>
      )}
      <Explore model={model} />
    </StyledModel>
  );
};

export default Model;
