import styled from 'styled-components';

import Button from '@components/Button';

export const StyledHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 2rem 0 4rem 0;
`;

export const SelectWrapper = styled.div`
  flex: 1;
  margin-right: 2rem;
`;

export const StyledButton = styled(Button)`
  min-height: ${(props) => props.theme.select.minHeight}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
`;
