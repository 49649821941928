import { UIModel } from '@store/user/types';

export const mapModelToRapidApiLink: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]:
    'https://rapidapi.com/knowledgator-knowledgator-default/api/rex7',
  [UIModel.NAMED_ENTITY_RECOGNITION]:
    'https://rapidapi.com/knowledgator-knowledgator-default/api/zero-shot-ner',
  [UIModel.TEXT_CLASSIFICATION]:
    'https://rapidapi.com/knowledgator-knowledgator-default/api/comprehend-it',
  [UIModel.TEXT_TO_TABLE]:
    'https://rapidapi.com/knowledgator-knowledgator-default/api/text2table',
};

export const mapModelToApiDocumentation: Record<UIModel, string> = {
  [UIModel.RELATION_EXTRACTION]:
    'https://docs.knowledgator.com/api-docs/documentation/information-extraction/relation-extraction',
  [UIModel.NAMED_ENTITY_RECOGNITION]:
    'https://docs.knowledgator.com/api-docs/documentation/information-extraction/entity-extraction',
  [UIModel.TEXT_CLASSIFICATION]:
    'https://docs.knowledgator.com/api-docs/documentation/content-categorization-and-filtering/text-classification',
  [UIModel.TEXT_TO_TABLE]:
    'https://docs.knowledgator.com/api-docs/documentation/text-to-table-structuring',
};
