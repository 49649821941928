import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const iconTheme: DefaultTheme['icon'] = {
  width: 1.8,
  height: 1.8,
  fill: 'none',
  stroke: Color.WHITE,
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};
