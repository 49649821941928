import { FC } from 'react';
import { DefaultTFuncReturn } from 'i18next';

import { StyledLimit, Min, Incrementor, Current, Max } from './styled';

interface Props {
  current: number;
  min: number;
  maxText: DefaultTFuncReturn;
  max: number;
}

const Limit: FC<Props> = ({ current, min, maxText, max }) => {
  const minError = current < min;
  const maxError = current > max;

  return (
    <StyledLimit>
      <Min />
      <Incrementor>
        <Current $error={minError || maxError}>{current}</Current>
        <Max>/{maxText}</Max>
      </Incrementor>
    </StyledLimit>
  );
};

export default Limit;
