import { FC } from 'react';

import { TooltipPlacement } from '@components/Tooltip/types';
import useToggle from '@hooks/useToggle';

import { StyledEntity, StyledTooltip, Type } from './styled';
import TooltipLabel from './TooltipLabel';

interface Props {
  active: boolean;
  entity: string;
  type: string;
  score: string;
}

const Entity: FC<Props> = ({ active, entity, type, score }) => {
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  return (
    <StyledEntity
      $active={active}
      onMouseEnter={toggleShowTooltip}
      onMouseLeave={toggleShowTooltip}
    >
      <StyledTooltip
        visible={showTooltip}
        label={<TooltipLabel active={active} score={score} />}
        placement={TooltipPlacement.LEFT}
        delay={100}
      >
        {entity}
        <Type>{type}</Type>
      </StyledTooltip>
    </StyledEntity>
  );
};

export default Entity;
