import { Element, HTMLReactParserOptions, Text } from 'html-react-parser';

import { Color } from '@theme/colors';

import ElementComponent from './Element';

export const getTextToTableOptions = (): HTMLReactParserOptions => ({
  replace: (domNode) => {
    const elementDomNode = domNode as Element;
    if (
      elementDomNode.attribs &&
      elementDomNode.attribs.class.includes('highlight') &&
      elementDomNode.attribs['data-color']
    ) {
      const label = (elementDomNode.children[0] as Text).data;
      const column = (
        (elementDomNode.children[1] as Element).children[0] as Text
      ).data;
      const color = elementDomNode.attribs['data-color'];
      return (
        <ElementComponent
          label={label}
          column={column}
          color={color as Color}
        />
      );
    }
    return domNode;
  },
});

export const isElementInWindowViewportByTop = (element: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  if (Math.trunc(elementRect.top) >= 0) {
    return true;
  }
  return false;
};
