import { FC, Fragment, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { useTheme } from 'styled-components';

import {
  NEW_LINE_SYMBOL,
  EMPTY_SENTENCE_VALUE,
} from '@components/Highlighter/defaults';
import Sentence from '@components/Highlighter/RelationExtraction/Sentence';
import { StyledHighlighter } from '@components/Highlighter/styled';
import { RelationState } from '@components/Radio/types';
import useWindowResize from '@hooks/useWindowResize';
import { RelationExtractionData } from '@store/user/types';
import { convertRemToPx } from '@utils/css';

import { prepareRelationExtractionText } from './algorithm';
import {
  RECTANGLE_BORDER_RADIUS_REM,
  TEXT_FONT_SIZE_REM,
  TEXT_HORIZONTAL_PADDING_REM,
  VERTICAL_OFFSET_REM,
} from './defaults';
import { StyledSVG, StyledPath, StyledRect, StyledText } from './styled';
import { Path, Text } from './types';
import { getOptions, getTextSizeInPx } from './utils';

interface Props {
  isEmpty?: boolean;
  sentences: string[];
  selectedRelations?: RelationExtractionData[];
  activeRelations?: RelationExtractionData[];
  hoveredRelations?: RelationExtractionData[];
  handleToggleHoveredRelation?: (relation: RelationExtractionData) => void;
  handleEdit?: VoidFunction;
}

const RelationExtraction: FC<Props> = ({
  sentences,
  selectedRelations = [],
  activeRelations = [],
  hoveredRelations = [],
  handleToggleHoveredRelation,
  handleEdit,
}) => {
  const verticalOffsetPx = convertRemToPx(VERTICAL_OFFSET_REM);
  const textHorizontalPaddingPx = convertRemToPx(TEXT_HORIZONTAL_PADDING_REM);
  const textFontSizePx = convertRemToPx(TEXT_FONT_SIZE_REM);
  const rectangleBorderRadiusPx = convertRemToPx(RECTANGLE_BORDER_RADIUS_REM);

  const highlighterElementRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [paths, setPaths] = useState<Path[]>([]);
  const [texts, setTexts] = useState<Text[]>([]);

  const [windowWidth] = useWindowResize();

  useEffect(() => {
    const svgContainerElement = svgRef.current;
    if (!svgContainerElement) {
      return;
    }
    const highlighterElement = highlighterElementRef.current;
    if (!highlighterElement) {
      return;
    }
    activeRelations.forEach(({ relation, id, source, target }) => {
      const isSelected = selectedRelations.find(
        (selectedRelation) => selectedRelation.id === id
      );
      const isHovered = hoveredRelations.find(
        (hoveredRelation) => hoveredRelation.id === id
      );
      const sourceClassName = `${source.start}-${source.end}`;
      const targetClassName = `${target.start}-${target.end}`;
      const sourceEntityElement =
        document.getElementsByClassName(sourceClassName)[0];
      const targetEntityElement =
        document.getElementsByClassName(targetClassName)[0];
      if (!sourceEntityElement || !targetEntityElement) {
        return;
      }
      const sourceEntityRect = sourceEntityElement.getBoundingClientRect();
      const targetEntityRect = targetEntityElement.getBoundingClientRect();
      const svgContainerRect = svgContainerElement.getBoundingClientRect();
      const x1 =
        sourceEntityRect.x + sourceEntityRect.width / 2 - svgContainerRect.x;
      const x2 =
        targetEntityRect.x + targetEntityRect.width / 2 - svgContainerRect.x;
      const y1 = sourceEntityRect.y - svgContainerRect.y;
      const y2 = targetEntityRect.y - svgContainerRect.y;
      const { width, height } = getTextSizeInPx(
        relation,
        highlighterElement,
        textFontSizePx
      );
      if (y2 === y1) {
        const pathPoints = {
          point1: `${x1} ${y1}`,
          point2: `${x1} ${y1 - verticalOffsetPx}`,
          point3: `${x2} ${y2 - verticalOffsetPx}`,
          point4: `${x2} ${y2}`,
          point5: `${x2 - verticalOffsetPx / 2} ${y2 - verticalOffsetPx / 2}`,
          point6: `${x2} ${y2}`,
          point7: `${x2 + verticalOffsetPx / 2} ${y2 - verticalOffsetPx / 2}`,
        };
        const newPath: Path = {
          value: `M${pathPoints.point1} ${pathPoints.point2} ${pathPoints.point3} ${pathPoints.point4} ${pathPoints.point5} ${pathPoints.point6} ${pathPoints.point7}`,
          selected: !!isSelected,
          hovered: !!isHovered,
        };
        setPaths((paths) => [...paths, newPath]);
        const textCoordinates = {
          x: (x2 - x1) / 2 + x1 - width / 2,
          y: y1 - verticalOffsetPx / 2,
        };
        const newText: Text = {
          x: textCoordinates.x,
          y: textCoordinates.y,
          value: relation,
          width,
          height,
          selected: !!isSelected,
          hovered: !!isHovered,
        };
        setTexts((texts) => [...texts, newText]);
        return;
      }
      const { right } = highlighterElement.getBoundingClientRect();
      const horizontalPadding = parseInt(
        window.getComputedStyle(highlighterElement).paddingRight,
        10
      );
      const endOfLine =
        right - horizontalPadding - svgContainerRect.x + verticalOffsetPx;
      const pathPoints = {
        point1: `${x1} ${y1}`,
        point2: `${x1} ${y1 - verticalOffsetPx}`,
        point3: `${endOfLine} ${y1 - verticalOffsetPx}`,
        point4: `${endOfLine} ${y2 - verticalOffsetPx}`,
        point5: `${x2} ${y2 - verticalOffsetPx}`,
        point6: `${x2} ${y2}`,
        point7: `${x2 - verticalOffsetPx / 2} ${y2 - verticalOffsetPx / 2}`,
        point8: `${x2} ${y2}`,
        point9: `${x2 + verticalOffsetPx / 2} ${y2 - verticalOffsetPx / 2}`,
      };
      const newPath: Path = {
        value: `M${pathPoints.point1} ${pathPoints.point2} ${pathPoints.point3} ${pathPoints.point4} ${pathPoints.point5} ${pathPoints.point6} ${pathPoints.point7} ${pathPoints.point8} ${pathPoints.point9}`,
        selected: !!isSelected,
        hovered: !!isHovered,
      };
      setPaths((paths) => [...paths, newPath]);
      const textCoordinates = {
        x: (endOfLine - x1) / 2 + x1 - width / 2,
        y: y1 - verticalOffsetPx / 2,
      };
      const newText: Text = {
        x: textCoordinates.x,
        y: textCoordinates.y,
        value: relation,
        width,
        height,
        selected: !!isSelected,
        hovered: !!isHovered,
      };
      setTexts((texts) => [...texts, newText]);
    });
    return () => {
      setPaths([]);
      setTexts([]);
    };
  }, [activeRelations, selectedRelations, hoveredRelations, windowWidth]);

  const theme = useTheme();

  const preparedText = prepareRelationExtractionText(
    sentences.join(''),
    activeRelations,
    selectedRelations,
    hoveredRelations
  );
  const splittedText = preparedText.split(NEW_LINE_SYMBOL);

  return (
    <StyledHighlighter ref={highlighterElementRef} onClick={handleEdit}>
      {splittedText.map((sentence, index) => {
        if (!sentence) {
          return <Sentence key={index} value={EMPTY_SENTENCE_VALUE} hidden />;
        }
        const value = parse(
          sentence,
          getOptions(activeRelations, handleToggleHoveredRelation)
        );
        return <Sentence key={index} value={value} />;
      })}
      {activeRelations.length > 0 && highlighterElementRef.current && (
        <StyledSVG
          ref={svgRef}
          $height={highlighterElementRef.current.scrollHeight}
        >
          {paths.map((path, index) => (
            <StyledPath
              key={index}
              d={path.value}
              stroke={
                path.hovered
                  ? theme.relations[RelationState.HOVERED].lineColor
                  : path.selected
                  ? theme.relations[RelationState.SELECTED].lineColor
                  : theme.relations[RelationState.ACTIVE].lineColor
              }
              fill="none"
              strokeWidth={1}
            />
          ))}
          {texts.map((text, index) => {
            if (!text.selected && !text.hovered) {
              return null;
            }
            return (
              <Fragment key={index}>
                <StyledRect
                  x={text.x - textHorizontalPaddingPx}
                  y={text.y - text.height / 2 - verticalOffsetPx / 2}
                  width={text.width + textHorizontalPaddingPx * 2}
                  height={text.height}
                  rx={rectangleBorderRadiusPx}
                  fill={
                    text.hovered
                      ? theme.relations[RelationState.HOVERED]
                          .rectBackgroundColor
                      : theme.relations[RelationState.SELECTED]
                          .rectBackgroundColor
                  }
                  stroke={
                    text.hovered
                      ? theme.relations[RelationState.HOVERED].rectBorderColor
                      : theme.relations[RelationState.SELECTED].rectBorderColor
                  }
                  strokeWidth="2"
                />
                <StyledText
                  fill={
                    text.hovered
                      ? theme.relations[RelationState.HOVERED].textColor
                      : theme.relations[RelationState.SELECTED].textColor
                  }
                  x={text.x}
                  y={text.y}
                  fontSize={textFontSizePx}
                >
                  {text.value}
                </StyledText>
              </Fragment>
            );
          })}
        </StyledSVG>
      )}
    </StyledHighlighter>
  );
};

export default RelationExtraction;
