import styled from 'styled-components';

export const StyledNotFound = styled.div`
  height: 100%;
`;

export const Title = styled.p`
  font-size: 2.4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
