import { Dispatch } from 'redux';

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes';
import { NOTIFICATION_TIME } from './defaults';
import { AddNotification, Notification, RemoveNotification } from './types';

export const addNotification = (notification: Notification) => {
  return async (dispatch: Dispatch) => {
    dispatch<AddNotification>({
      type: ADD_NOTIFICATION,
      payload: {
        notification,
      },
    });
    setTimeout(() => {
      dispatch<RemoveNotification>({
        type: REMOVE_NOTIFICATION,
        payload: {
          notification,
        },
      });
    }, NOTIFICATION_TIME);
  };
};

export const removeNotification = (notification: Notification) => {
  return async (dispatch: Dispatch) => {
    dispatch<RemoveNotification>({
      type: REMOVE_NOTIFICATION,
      payload: {
        notification,
      },
    });
  };
};
