import { FC } from 'react';

import Link from './Link';
import { StyledLinkViewer } from './styled';

interface Props {
  value: string;
  handleRemove: VoidFunction;
}

const LinkViewer: FC<Props> = ({ value, handleRemove }) => (
  <StyledLinkViewer>
    <Link value={value} handleRemove={handleRemove} />
  </StyledLinkViewer>
);

export default LinkViewer;
