import styled, { css } from 'styled-components';

import Dropzone from '@components/Dropzone';

export const StyledFileInput = styled.div`
  flex: auto;
  height: 59rem;
`;

export const StyledDropzone = styled(Dropzone)<{ $isDragActive: boolean }>`
  height: 100%;

  ${(props) =>
    props.$isDragActive &&
    css`
      background-color: ${(props) =>
        props.theme.fileInput.dragActiveBackgroundColor};
    `};
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  height: 100%;
`;

export const EmptyText = styled.div<{ $uiError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12rem 0;
  color: ${(props) =>
    props.$uiError
      ? props.theme.fileInput.emptyText.errorColor
      : props.theme.fileInput.emptyText.color};
  gap: 0.5rem;
`;

export const Text = styled.p<{ $uiError: boolean }>`
  text-decoration: underline;
  color: ${(props) =>
    props.$uiError
      ? props.theme.fileInput.emptyText.errorUnderlineColor
      : props.theme.fileInput.emptyText.underlineColor};
`;
