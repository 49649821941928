import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SampleText } from '@containers/Processor/types';
import {
  validateIfTextToTableColumnIsEmpty,
  validateIfTextToTableDataIsEmpty,
} from '@containers/Processor/utils';
import Export from '@components/Export';
import Toggle from '@components/Toggle';
import { Mode } from '@components/Toggle/types';
import JsonView from '@components/JsonView';
import { UIModel } from '@store/user/types';
import { UIError } from '@store/ui/types';
import { Color } from '@theme/colors';

import { ActionColumn, Column, NumberColumn } from './Column';
import { TABLE_ROW_CLASS_NAME } from './Column/Cell/default';
import {
  StyledTable,
  SectionTop,
  Title,
  Actions,
  TableContent,
} from './styled';
import { TableMode } from './types';
import {
  colorPalette,
  fillData,
  getColumnColor,
  getMaxElements,
} from './utils';

interface Props {
  selectedText: SampleText;
  handleAddColumn: VoidFunction;
  handleDeleteColumn: (value: string) => void;
  handleUpdateColumn: (newLabel: string, value: string) => void;
  handleRowClick: (index: number, isHighlighted: boolean) => void;
  handleColumnClick: (
    value: string,
    color: Color,
    isHighlighted: boolean
  ) => void;
  handleCellClick: (
    column: string,
    value: string,
    color: Color,
    isHighlighted: boolean
  ) => void;
  uiErrors: UIError[];
  handleDownloadJsonFile: VoidFunction;
  prepareTableTextForCSV: () => string[][];
  prepareTableTextForCopy: () => string;
}

const Table: FC<Props> = ({
  selectedText,
  handleAddColumn,
  handleDeleteColumn,
  handleUpdateColumn,
  handleRowClick,
  handleColumnClick,
  handleCellClick,
  uiErrors,
  handleDownloadJsonFile,
  prepareTableTextForCSV,
  prepareTableTextForCopy,
}) => {
  const { columns, data, highlightedData, highlightedElement } =
    selectedText.textToTable!;

  const { t } = useTranslation();

  const [maxRowHeights, setMaxRowHeights] = useState<number[]>([]);
  const [activeMode, setActiveMode] = useState<TableMode>(TableMode.DEFAULT);

  useEffect(() => {
    setMaxRowHeights([]);
    setTimeout(() => {
      const rowCount = getMaxElements(data) + 1;
      const maxHeights: number[] = [];
      Array.from(Array(rowCount).keys()).forEach((index) => {
        const cells = document.getElementsByClassName(
          `${TABLE_ROW_CLASS_NAME}-${index}`
        );
        const maxHeight = Math.max(
          ...Array.from(cells).map(
            (cell) => cell.getBoundingClientRect().height
          )
        );
        maxHeights.push(maxHeight);
      });
      setMaxRowHeights(maxHeights);
    });
  }, [data]);

  const handleModeChange = (value: TableMode) => {
    setActiveMode(value);
  };

  const modes: Mode<TableMode>[] = useMemo(
    () => [
      {
        title: t('components.table.mode.default'),
        type: TableMode.DEFAULT,
      },
      {
        title: t('components.table.mode.json'),
        type: TableMode.JSON,
      },
    ],
    []
  );

  const maxElements = getMaxElements(data);
  const filledData = fillData(data, maxElements);
  const isEmpty = validateIfTextToTableDataIsEmpty(filledData);

  const uiError = uiErrors.includes(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY);
  return (
    <StyledTable>
      <SectionTop>
        <Title>{t('components.table.title')}</Title>
        {!isEmpty && (
          <Actions>
            <Export
              model={UIModel.TEXT_TO_TABLE}
              handleDownloadJsonFile={handleDownloadJsonFile}
              prepareTableTextForCSV={prepareTableTextForCSV}
              prepareTableTextForCopy={prepareTableTextForCopy}
            />
            <Toggle
              active={activeMode}
              onChange={handleModeChange}
              items={modes}
            />
          </Actions>
        )}
      </SectionTop>
      <TableContent $uiError={uiError}>
        {activeMode === TableMode.DEFAULT ? (
          columns.map((column, columnIndex) => {
            const isEmpty = validateIfTextToTableColumnIsEmpty(
              column.label,
              filledData
            );
            const columnData = filledData[column.label];
            const columnColor = getColumnColor(columnIndex, colorPalette);
            return (
              <Fragment key={columnIndex}>
                {columnIndex === 0 && (
                  <NumberColumn
                    isEmpty={isEmpty}
                    count={maxElements}
                    highlightedElement={highlightedElement}
                    handleRowClick={handleRowClick}
                    uiError={uiError}
                    maxRowHeights={maxRowHeights}
                  />
                )}
                <Column
                  label={column.label}
                  isUpdated={column.updated}
                  isNew={column.new}
                  data={columnData}
                  color={columnColor}
                  handleDelete={() => {
                    handleDeleteColumn(column.value);
                  }}
                  handleUpdate={(newLabel: string) => {
                    handleUpdateColumn(newLabel, column.value);
                  }}
                  handleColumnClick={(isHighlighted: boolean) => {
                    handleColumnClick(column.value, columnColor, isHighlighted);
                  }}
                  handleCellClick={(value: string, isHighlighted: boolean) => {
                    handleCellClick(
                      column.label,
                      value,
                      columnColor,
                      isHighlighted
                    );
                  }}
                  highlightedData={highlightedData}
                  isColumnHighlighted={
                    highlightedElement?.type === 'column' &&
                    highlightedElement?.index === columnIndex
                  }
                  isAnyRowHighlighted={highlightedElement?.type === 'row'}
                  uiError={uiError}
                  maxRowHeights={maxRowHeights}
                />
                {columnIndex === columns.length - 1 && (
                  <ActionColumn
                    handleAdd={handleAddColumn}
                    uiError={uiError}
                    maxRowHeights={maxRowHeights}
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <JsonView value={data} />
        )}
      </TableContent>
    </StyledTable>
  );
};

export default Table;
