import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledFooter, StyledLink } from './styled';

interface Props {
  handleResetClick: MouseEventHandler<HTMLAnchorElement>;
}

const Footer: FC<Props> = ({ handleResetClick }) => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <StyledLink to="#" onClick={handleResetClick}>
        {t('components.classes.footer.reset')}
      </StyledLink>
    </StyledFooter>
  );
};

export default Footer;
