import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const linkViewerTheme: DefaultTheme['linkViewer'] = {
  link: {
    width: 50,
    backgroundColor: Color.TUNDORA,
    color: Color.WHITE,
    crossIconColor: Color.SILVER_CHALICE,
  },
};
