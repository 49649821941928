export enum Color {
  /* Custom */
  WHITE = '#fff',
  BLACK = '#000',
  TRANSPARENT = 'transparent',
  FLUSH_MAHOGANY = '#CF3C3C',

  /* Figma color palette */
  COD_GRAY = '#111111',
  MINE_SHAFT = '#242424',
  MINE_SHAFT_MEDIUM = '#2D2D2D',
  MINE_SHAFT_LIGHT = '#363636',
  SILVER_CHALICE = '#a5a5a5',
  CORNFLOWER_BLUE = '#548CF2',
  INDIGO = '#4570C1',
  SHIP_COVE = '#607EB6',
  TUNDORA = '#464646',
  WILD_SAND = '#F5F5F5',
  PIZZA = '#C89511',
  FUEL_YELLOW = '#EAB324',
  BUTTERED_RUM = '#957011',
  HIPPIE_GREEN = '#4E8250',
  ENVY = '#8CAF8D',
  ALTO = '#d3d3d3',
  GRAY = '#8D8D8D',
  HAWKES_BLUE = '#CCDDFC',
  CHABLIS = '#FFF5F5',

  /* Raw RGBA values */
  GALLERY_10 = 'rgba(234, 234, 234, 0.1)',
  WHITE_15 = 'rgba(255, 255, 255, 0.15)',
  BLACK_25 = 'rgba(0, 0, 0, 0.25)',
  CORNFLOWER_BLUE_50 = 'rgba(84, 140, 242, 0.5)',
  CORNFLOWER_BLUE_30 = 'rgba(84, 140, 242, 0.3)',
  SAN_MARINO_25 = 'rgba(69, 104, 167, 0.25)',

  /* Text2Table color palette */
  NEW_ORLEANS = '#F1CD96',
  COLD_PURPLE = '#989ED7',
  KOBI = '#E892CE',
  BURNT_SIENNA = '#E9845B',
  ROMAN = '#D94F4F',
  HALF_BAKED = '#85C8CC',
  SANTA_FE = '#AB7853',
  APPLE = '#4EAA37',
  CONGO_BROWN = '#643B3B',
  PLUM = '#7E346E',
}
