import { createSelector } from 'reselect';

import { AppState } from '@store/rootReducer';

export const getAuthState = (state: AppState) => state.auth;

export const getLoginState = (state: AppState) => state.auth.login;

export const getRegisterState = (state: AppState) => state.auth.register;

export const getCheckAuthState = (state: AppState) => state.auth.checkAuth;

export const getForgotPasswordState = (state: AppState) =>
  state.auth.forgotPassword;

export const getResetPasswordState = (state: AppState) =>
  state.auth.resetPassword;

export const getValidatePasswordState = (state: AppState) =>
  state.auth.validatePassword;

export const getChangePasswordState = (state: AppState) =>
  state.auth.changePassword;

export const getLoginLoadingSelector = createSelector(
  getLoginState,
  (login) => login.loading
);

export const getLoginErrorSelector = createSelector(
  getLoginState,
  (login) => login.error
);

export const getLoginSuccessSelector = createSelector(
  getLoginState,
  (login) => login.success
);

export const getRegisterLoadingSelector = createSelector(
  getRegisterState,
  (register) => register.loading
);

export const getRegisterErrorSelector = createSelector(
  getRegisterState,
  (register) => register.error
);

export const getRegisterSuccessSelector = createSelector(
  getRegisterState,
  (register) => register.success
);

export const getCheckAuthLoadingSelector = createSelector(
  getCheckAuthState,
  (checkAuth) => checkAuth.loading
);

export const getCheckAuthErrorSelector = createSelector(
  getCheckAuthState,
  (checkAuth) => checkAuth.error
);

export const getCheckAuthSuccessSelector = createSelector(
  getCheckAuthState,
  (checkAuth) => checkAuth.success
);

export const getCurrentUserSelector = createSelector(
  getAuthState,
  (auth) => auth.currentUser
);

export const getForgotPasswordLoadingSelector = createSelector(
  getForgotPasswordState,
  (forgotPassword) => forgotPassword.loading
);

export const getForgotPasswordErrorSelector = createSelector(
  getForgotPasswordState,
  (forgotPassword) => forgotPassword.error
);

export const getForgotPasswordSuccessSelector = createSelector(
  getForgotPasswordState,
  (forgotPassword) => forgotPassword.success
);

export const getResetPasswordLoadingSelector = createSelector(
  getResetPasswordState,
  (resetPassword) => resetPassword.loading
);

export const getResetPasswordErrorSelector = createSelector(
  getResetPasswordState,
  (resetPassword) => resetPassword.error
);

export const getResetPasswordSuccessSelector = createSelector(
  getResetPasswordState,
  (resetPassword) => resetPassword.success
);

export const getValidatePasswordLoadingSelector = createSelector(
  getValidatePasswordState,
  (validatePassword) => validatePassword.loading
);

export const getValidatePasswordErrorSelector = createSelector(
  getValidatePasswordState,
  (validatePassword) => validatePassword.error
);

export const getValidatePasswordSuccessSelector = createSelector(
  getValidatePasswordState,
  (validatePassword) => validatePassword.success
);

export const getChangePasswordLoadingSelector = createSelector(
  getChangePasswordState,
  (changePassword) => changePassword.loading
);

export const getChangePasswordErrorSelector = createSelector(
  getChangePasswordState,
  (changePassword) => changePassword.error
);

export const getChangePasswordSuccessSelector = createSelector(
  getChangePasswordState,
  (changePassword) => changePassword.success
);
