import NotificationComponent from '@components/Notification';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { removeNotification } from '@store/notification/actions';
import { getNotificationsSelector } from '@store/notification/selectors';
import { Notification } from '@store/notification/types';

import { StyledNotifications } from './styled';

const Notifications = () => {
  const dispatch = useAppDispatch();

  const items = useAppSelector(getNotificationsSelector);

  const handleRemove = (notification: Notification) => {
    dispatch(removeNotification(notification));
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <StyledNotifications>
      {items.map((item) => (
        <NotificationComponent
          key={item.reason}
          type={item.type}
          reason={item.reason}
          handleRemove={() => {
            handleRemove(item);
          }}
        />
      ))}
    </StyledNotifications>
  );
};

export default Notifications;
