import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStepper from '@hooks/useStepper';

import Progress from './Progress';
import Step from './Step';
import { Step as StepType } from './Step/types';
import {
  StyledStepper,
  Header,
  Title,
  Action,
  Footer,
  FooterActions,
  ActionWrapper,
  ProgressWrapper,
  StyledButton,
} from './styled';

interface Props {
  title: string;
  steps: StepType[];
  handleFinish: VoidFunction;
}

const Stepper: FC<Props> = ({ title, steps, handleFinish }) => {
  const [animationKey, setAnimationKey] = useState(0);

  const { t } = useTranslation();

  const { stepIndex, step, isFirst, isLast, handleNextStep, handleStepClick } =
    useStepper(steps);

  const handleReplay = () => {
    setAnimationKey((key) => key + 1);
  };

  return (
    <StyledStepper>
      <Header>
        <Title>{title}</Title>
        <Action onClick={handleFinish}>{t('components.stepper.skip')}</Action>
      </Header>
      <Step
        animationKey={animationKey}
        animation={step.animation}
        description={step.description}
        details={step.details}
        size={stepIndex > 0 ? step.size : undefined}
      />
      <Footer>
        {stepIndex > 0 && (
          <ProgressWrapper>
            <Progress
              activeIndex={stepIndex - 1}
              count={steps.length - 1}
              handleClick={handleStepClick}
            />
          </ProgressWrapper>
        )}
        <FooterActions>
          {stepIndex > 0 && (
            <ActionWrapper>
              <Action onClick={handleReplay}>
                {t('components.stepper.replay')}
              </Action>
            </ActionWrapper>
          )}
          <StyledButton to="#" onClick={isLast ? handleFinish : handleNextStep}>
            {isFirst
              ? t('components.stepper.start')
              : isLast
              ? t('components.stepper.finish')
              : t('components.stepper.next')}
          </StyledButton>
        </FooterActions>
      </Footer>
    </StyledStepper>
  );
};

export default Stepper;
