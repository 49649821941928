import { DragEventHandler, FC, PropsWithChildren, RefObject } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

import { addUIError, removeUIError } from '@store/ui/actions';
import { UIError } from '@store/ui/types';
import { addNotification } from '@store/notification/actions';
import { NotificationType } from '@store/notification/types';
import { useAppDispatch } from '@store/hooks';

import {
  DEFAULT_ACCEPT,
  DEFAULT_MAX_FILES,
  DEFAULT_MAX_FILE_SIZE_IN_BYTES,
} from './defaults';
import { StyledDropzone } from './styled';
import { mapFileInputErrorToNotificationReason } from './utils';
import { FileInputError } from './types';

interface Props extends PropsWithChildren {
  className?: string;
  inputRef: RefObject<HTMLInputElement>;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  onDrop: VoidFunction;
  onFilesAccepted: (files: File[]) => void;
  uiErrors: UIError[];
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
}

const Dropzone: FC<Props> = ({
  className,
  inputRef,
  onDragEnter,
  onDragLeave,
  onDrop,
  onFilesAccepted,
  uiErrors,
  accept = DEFAULT_ACCEPT,
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  children,
}) => {
  const dispatch = useAppDispatch();

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
    if (uiErrors.includes(UIError.FILE_INPUT_ERROR)) {
      dispatch(removeUIError(UIError.FILE_INPUT_ERROR));
    }
    onDragEnter(event);
  };

  const handleDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
    onDrop();
    if (acceptedFiles.length > 0) {
      onFilesAccepted(acceptedFiles);
    }
    if (rejections.length > 0) {
      const errorKey = (rejections[0]?.errors[0].code ||
        'unknown') as FileInputError;
      dispatch(
        addNotification({
          type: NotificationType.ERROR,
          reason: mapFileInputErrorToNotificationReason[errorKey],
        })
      );
      dispatch(addUIError(UIError.FILE_INPUT_ERROR));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    noClick: true,
    onDragEnter: handleDragEnter,
    onDragLeave,
    onDrop: handleDrop,
  });

  return (
    <StyledDropzone className={className} {...getRootProps()}>
      <input {...getInputProps()} ref={inputRef} />
      {children}
    </StyledDropzone>
  );
};

export default Dropzone;
