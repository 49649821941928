import styled from 'styled-components';

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  margin: 2rem 2.5rem 2rem 0;
  color: ${(props) => props.theme.classes.legendColor};
`;

export const Section = styled.p``;
