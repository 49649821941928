import {
  CLEAR_MODELS,
  NAMED_ENTITY_RECOGNITION_PROCESS,
  NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED,
  NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED,
  RELATION_EXTRACTION_PROCESS,
  RELATION_EXTRACTION_PROCESS_FULFILLED,
  RELATION_EXTRACTION_PROCESS_REJECTED,
  TEXT_CLASSIFICATION_PROCESS,
  TEXT_CLASSIFICATION_PROCESS_FULFILLED,
  TEXT_CLASSIFICATION_PROCESS_REJECTED,
  TEXT_TO_TABLE_PROCESS,
  TEXT_TO_TABLE_PROCESS_FULFILLED,
  TEXT_TO_TABLE_PROCESS_REJECTED,
} from './actionTypes';
import { UserActions, UserState } from './types';

const initialState: UserState = {
  namedEntityRecognitionProcessDetails: {},
  textClassificationProcessDetails: {},
  textToTableProcessDetails: {},
  relationExtractionProcessDetails: {},
};

const reducer = (state = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case NAMED_ENTITY_RECOGNITION_PROCESS:
      return {
        ...state,
        namedEntityRecognitionProcessDetails: {
          ...state.namedEntityRecognitionProcessDetails,
          [action.payload.name]: {
            ...state.namedEntityRecognitionProcessDetails[action.payload.name],
            loading: true,
            data: [],
            success: false,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case NAMED_ENTITY_RECOGNITION_PROCESS_FULFILLED:
      return {
        ...state,
        namedEntityRecognitionProcessDetails: {
          ...state.namedEntityRecognitionProcessDetails,
          [action.payload.name]: {
            ...state.namedEntityRecognitionProcessDetails[action.payload.name],
            loading: false,
            data: action.payload.data,
            success: true,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case NAMED_ENTITY_RECOGNITION_PROCESS_REJECTED:
      return {
        ...state,
        namedEntityRecognitionProcessDetails: {
          ...state.namedEntityRecognitionProcessDetails,
          [action.payload.name]: {
            ...state.namedEntityRecognitionProcessDetails[action.payload.name],
            loading: false,
            data: [],
            success: false,
            index: action.payload.index,
            error: action.payload.error,
          },
        },
      };
    case TEXT_CLASSIFICATION_PROCESS:
      return {
        ...state,
        textClassificationProcessDetails: {
          ...state.textClassificationProcessDetails,
          [action.payload.name]: {
            ...state.textClassificationProcessDetails[action.payload.name],
            loading: true,
            data: {},
            success: false,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case TEXT_CLASSIFICATION_PROCESS_FULFILLED:
      return {
        ...state,
        textClassificationProcessDetails: {
          ...state.textClassificationProcessDetails,
          [action.payload.name]: {
            ...state.textClassificationProcessDetails[action.payload.name],
            loading: false,
            data: action.payload.data,
            success: true,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case TEXT_CLASSIFICATION_PROCESS_REJECTED:
      return {
        ...state,
        textClassificationProcessDetails: {
          ...state.textClassificationProcessDetails,
          [action.payload.name]: {
            ...state.textClassificationProcessDetails[action.payload.name],
            loading: false,
            data: {},
            success: false,
            index: action.payload.index,
            error: action.payload.error,
          },
        },
      };
    case TEXT_TO_TABLE_PROCESS:
      return {
        ...state,
        textToTableProcessDetails: {
          ...state.textToTableProcessDetails,
          [action.payload.name]: {
            ...state.textToTableProcessDetails[action.payload.name],
            loading: true,
            data: {},
            success: false,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case TEXT_TO_TABLE_PROCESS_FULFILLED:
      return {
        ...state,
        textToTableProcessDetails: {
          ...state.textToTableProcessDetails,
          [action.payload.name]: {
            ...state.textToTableProcessDetails[action.payload.name],
            loading: false,
            data: action.payload.data,
            success: true,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case TEXT_TO_TABLE_PROCESS_REJECTED:
      return {
        ...state,
        textToTableProcessDetails: {
          ...state.textToTableProcessDetails,
          [action.payload.name]: {
            ...state.textToTableProcessDetails[action.payload.name],
            loading: false,
            data: {},
            success: false,
            index: action.payload.index,
            error: action.payload.error,
          },
        },
      };
    case RELATION_EXTRACTION_PROCESS:
      return {
        ...state,
        relationExtractionProcessDetails: {
          ...state.relationExtractionProcessDetails,
          [action.payload.name]: {
            ...state.relationExtractionProcessDetails[action.payload.name],
            loading: true,
            data: [],
            success: false,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case RELATION_EXTRACTION_PROCESS_FULFILLED:
      return {
        ...state,
        relationExtractionProcessDetails: {
          ...state.relationExtractionProcessDetails,
          [action.payload.name]: {
            ...state.relationExtractionProcessDetails[action.payload.name],
            loading: false,
            data: action.payload.data,
            success: true,
            index: action.payload.index,
            error: '',
          },
        },
      };
    case RELATION_EXTRACTION_PROCESS_REJECTED:
      return {
        ...state,
        relationExtractionProcessDetails: {
          ...state.relationExtractionProcessDetails,
          [action.payload.name]: {
            ...state.relationExtractionProcessDetails[action.payload.name],
            loading: false,
            data: [],
            success: false,
            index: action.payload.index,
            error: action.payload.error,
          },
        },
      };
    case CLEAR_MODELS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
