import styled from 'styled-components';

export const StyledClasses = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.classes.titleColor};
  font-size: 2.4rem;
  line-height: 5rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const ClassesContent = styled.div<{
  $clicked: boolean;
  $uiError: boolean;
}>`
  background-color: ${(props) => props.theme.classes.backgroundColor};
  border-width: 1px;
  border-style: ${(props) =>
    props.$uiError
      ? props.theme.classes.errorBorderStyle
      : props.$clicked
      ? props.theme.classes.clickedBorderStyle
      : props.theme.classes.borderStyle};
  border-color: ${(props) =>
    props.$uiError
      ? props.theme.classes.errorBorderColor
      : props.$clicked
      ? props.theme.classes.clickedBorderColor
      : props.theme.classes.borderColor};
  border-radius: 1.5rem;
  height: 100%;
  max-height: 67rem;
  overflow-y: auto;
`;
