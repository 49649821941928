export interface TooltipCoordinates {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  arrow?: {
    top?: number;
    left?: number;
  };
}

export enum TooltipPlacement {
  LEFT = 'left',
  BOTTOM = 'bottom',
}
