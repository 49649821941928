import styled from 'styled-components';

import Link from '@components/Link';

export const StyledDesktopMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;

export const MenuElement = styled(Link)`
  font-size: 2rem;

  &:hover {
    text-decoration: underline;
  }
`;
