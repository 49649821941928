import { TextToTableHighlightedData } from '@containers/Processor/types';

/*
  TODO: Refactor and add unit tests and cover as much edge cases as possible
*/
export const prepareTextToTableText = (
  text: string,
  highlightedData: TextToTableHighlightedData[]
) => {
  if (highlightedData.length === 0) {
    return text;
  }
  let newText = text;
  highlightedData.forEach(({ column, label, color }) => {
    const labelIndex = newText.indexOf(label);
    if (labelIndex === -1) {
      return newText;
    }
    const start = newText.substring(0, labelIndex);
    const end = newText.substring(labelIndex + label.length, newText.length);
    const inject = `<div class="highlight" data-color="${color}">${label}<span className="column">${column}</span></div>`;
    newText = `${start}${inject}${end}`;
  });
  return newText;
};
