import styled from 'styled-components';

import { Color } from '@theme/colors';

export const StyledDot = styled.div<{ $color: Color }>`
  min-width: ${(props) => props.theme.dot.width}px;
  min-height: ${(props) => props.theme.dot.height}px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
`;
