import { combineReducers } from 'redux';

import auth from './auth/reducer';
import notification from './notification/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

import store from '.';

const rootReducer = combineReducers({
  auth,
  notification,
  ui,
  user,
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default rootReducer;
