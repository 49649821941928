import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import TutorialContainer from '@containers/Tutorial';
import Tabs from '@components/Tabs';
import { UIModel } from '@store/user/types';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { openTutorial } from '@store/ui/actions';
import { getErrorsSelector } from '@store/ui/selectors';
import { UIError } from '@store/ui/types';

import { getEmptyCustomText } from './data';
import { StyledHome, TabsWrapper } from './styled';
import { CustomText } from './types';

interface Props {
  model: UIModel;
}

const Home: FC<Props> = ({ model }) => {
  const dispatch = useAppDispatch();

  const uiErrors = useAppSelector(getErrorsSelector);

  const [customTexts, setCustomTexts] = useState<CustomText[]>([
    getEmptyCustomText(UIModel.TEXT_TO_TABLE),
    getEmptyCustomText(UIModel.TEXT_CLASSIFICATION),
    getEmptyCustomText(UIModel.NAMED_ENTITY_RECOGNITION),
    getEmptyCustomText(UIModel.RELATION_EXTRACTION),
  ]);

  const handleCustomTextsChange = (customTexts: CustomText[]) => {
    setCustomTexts(customTexts);
  };

  const handleOpenTutorial = () => {
    let stepIndex = 0;
    if (
      uiErrors.includes(UIError.TEXT_CLASSIFICATION_LABELS_EMPTY) ||
      uiErrors.includes(UIError.TEXT_TO_TABLE_COLUMNS_EMPTY) ||
      uiErrors.includes(UIError.NAMED_ENTITY_RECOGNITION_ENTITIES_EMPTY)
    ) {
      stepIndex = 2;
      ``;
    }
    dispatch(openTutorial(stepIndex));
  };

  return (
    <StyledHome>
      <TabsWrapper>
        <Tabs
          model={model}
          highlightTutorial={uiErrors.length > 0}
          handleOpenTutorial={handleOpenTutorial}
        />
      </TabsWrapper>
      {[
        UIModel.NAMED_ENTITY_RECOGNITION,
        UIModel.TEXT_CLASSIFICATION,
        UIModel.TEXT_TO_TABLE,
      ].includes(model) ? (
        <TutorialContainer model={model}>
          <Outlet context={{ customTexts, handleCustomTextsChange }} />
        </TutorialContainer>
      ) : (
        <Outlet context={{ customTexts, handleCustomTextsChange }} />
      )}
    </StyledHome>
  );
};

export default Home;
