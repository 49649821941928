import { FC, useState } from 'react';

import { StyledCell, Content, Label } from './styled';

interface Props {
  className?: string;
  label: string;
  handleClick: VoidFunction;
  highlight: boolean;
  isLast: boolean;
  isEmpty: boolean;
  uiError: boolean;
  height?: number;
}

const Cell: FC<Props> = ({
  className,
  label,
  handleClick,
  highlight,
  isLast,
  isEmpty,
  uiError,
  height,
}) => {
  const [hover, setHover] = useState(false);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <StyledCell
      onClick={isEmpty ? undefined : handleClick}
      $highlight={highlight}
    >
      <Content
        className={className}
        $uiError={uiError}
        $last={isLast}
        $highlight={isEmpty ? false : hover}
        $height={height}
        onMouseOver={isEmpty ? undefined : handleMouseOver}
        onMouseLeave={isEmpty ? undefined : handleMouseLeave}
      >
        <Label>{label}</Label>
      </Content>
    </StyledCell>
  );
};

export default Cell;
