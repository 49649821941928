import { NotificationReason } from '@store/notification/types';

import { FileInputError } from './types';

export const mapFileInputErrorToNotificationReason: Record<
  FileInputError,
  NotificationReason
> = {
  'file-invalid-type': NotificationReason.FILE_INVALID_TYPE,
  'file-too-large': NotificationReason.FILE_TOO_LARGE,
  'too-many-files': NotificationReason.TOO_MANY_FILES,
  unknown: NotificationReason.UNKNOWN,
};
