import { Dispatch } from 'redux';

import * as api from '@api/functions';
import { getErrorMessage } from '@store/utils';

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_CLEAR,
  CHANGE_PASSWORD_FULFILLED,
  CHANGE_PASSWORD_REJECTED,
  CHECK_AUTH,
  CHECK_AUTH_FULFILLED,
  CHECK_AUTH_REJECTED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CLEAR,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_REJECTED,
  LOGIN,
  LOGIN_CLEAR,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  REGISTER,
  REGISTER_CLEAR,
  REGISTER_FULFILLED,
  REGISTER_REJECTED,
  RESET_PASSWORD,
  RESET_PASSWORD_CLEAR,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
  VALIDATE_PASSWORD,
  VALIDATE_PASSWORD_CLEAR,
  VALIDATE_PASSWORD_FULFILLED,
  VALIDATE_PASSWORD_REJECTED,
} from './actionTypes';
import {
  Login,
  LoginFulfilled,
  LoginRejected,
  LoginCredentials,
  RegisterCredentials,
  Register,
  RegisterFulfilled,
  RegisterRejected,
  Logout,
  CheckAuth,
  CheckAuthFulfilled,
  CheckAuthRejected,
  LogoutFulfilled,
  LogoutRejected,
  LoginClear,
  RegisterClear,
  ForgotPassword,
  ForgotPasswordFulfilled,
  ForgotPasswordRejected,
  ForgotPasswordCredentials,
  ForgotPasswordClear,
  ResetPasswordCredentials,
  ResetPassword,
  ResetPasswordFulfilled,
  ResetPasswordRejected,
  ResetPasswordClear,
  ValidatePasswordCredentials,
  ValidatePassword,
  ValidatePasswordFulfilled,
  ValidatePasswordRejected,
  ValidatePasswordClear,
  ChangePasswordCredentials,
  ChangePassword,
  ChangePasswordFulfilled,
  ChangePasswordRejected,
  ChangePasswordClear,
} from './types';

export const loginUser = (credentials: LoginCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<Login>({
        type: LOGIN,
      });
      const { data: currentUser } = await api.signIn(credentials);
      dispatch<LoginFulfilled>({
        type: LOGIN_FULFILLED,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      dispatch<LoginRejected>({
        type: LOGIN_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const loginClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<LoginClear>({
      type: LOGIN_CLEAR,
    });
  };
};

export const registerUser = (credentials: RegisterCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<Register>({
        type: REGISTER,
      });
      const { data: currentUser } = await api.signUp(credentials);
      dispatch<RegisterFulfilled>({
        type: REGISTER_FULFILLED,
        payload: { currentUser },
      });
    } catch (error) {
      dispatch<RegisterRejected>({
        type: REGISTER_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const registerClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<RegisterClear>({
      type: REGISTER_CLEAR,
    });
  };
};

export const logoutUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<Logout>({
        type: LOGOUT,
      });
      await api.signOut();
      dispatch<LogoutFulfilled>({
        type: LOGOUT_FULFILLED,
      });
    } catch (error) {
      dispatch<LogoutRejected>({
        type: LOGOUT_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const checkAuth = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<CheckAuth>({
        type: CHECK_AUTH,
      });
      const {
        data: { currentUser },
      } = await api.getCurrentUser();
      dispatch<CheckAuthFulfilled>({
        type: CHECK_AUTH_FULFILLED,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      dispatch<CheckAuthRejected>({
        type: CHECK_AUTH_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const forgotPassword = (credentials: ForgotPasswordCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<ForgotPassword>({
        type: FORGOT_PASSWORD,
      });
      await api.resetPasswordRequest(credentials);
      dispatch<ForgotPasswordFulfilled>({
        type: FORGOT_PASSWORD_FULFILLED,
      });
    } catch (error) {
      dispatch<ForgotPasswordRejected>({
        type: FORGOT_PASSWORD_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const forgotPasswordClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<ForgotPasswordClear>({
      type: FORGOT_PASSWORD_CLEAR,
    });
  };
};

export const resetPassword = (credentials: ResetPasswordCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<ResetPassword>({
        type: RESET_PASSWORD,
      });
      await api.resetPassword(credentials);
      dispatch<ResetPasswordFulfilled>({
        type: RESET_PASSWORD_FULFILLED,
      });
    } catch (error) {
      dispatch<ResetPasswordRejected>({
        type: RESET_PASSWORD_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const resetPasswordClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<ResetPasswordClear>({
      type: RESET_PASSWORD_CLEAR,
    });
  };
};

export const validatePassword = (credentials: ValidatePasswordCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<ValidatePassword>({
        type: VALIDATE_PASSWORD,
      });
      await api.validatePassword(credentials);
      dispatch<ValidatePasswordFulfilled>({
        type: VALIDATE_PASSWORD_FULFILLED,
      });
    } catch (error) {
      dispatch<ValidatePasswordRejected>({
        type: VALIDATE_PASSWORD_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const validatePasswordClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<ValidatePasswordClear>({
      type: VALIDATE_PASSWORD_CLEAR,
    });
  };
};

export const changePassword = (credentials: ChangePasswordCredentials) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<ChangePassword>({
        type: CHANGE_PASSWORD,
      });
      await api.changePassword(credentials);
      dispatch<ChangePasswordFulfilled>({
        type: CHANGE_PASSWORD_FULFILLED,
      });
    } catch (error) {
      dispatch<ChangePasswordRejected>({
        type: CHANGE_PASSWORD_REJECTED,
        payload: {
          error: getErrorMessage(error),
        },
      });
    }
  };
};

export const changePasswordClear = () => {
  return async (dispatch: Dispatch) => {
    dispatch<ChangePasswordClear>({
      type: CHANGE_PASSWORD_CLEAR,
    });
  };
};
