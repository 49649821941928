import { FC } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { Color } from '@theme/colors';
import { convertRemToPx } from '@utils/css';

import { StyledLoader } from './styled';

interface Props {
  color?: Color;
  size?: number;
  speedMultiplier?: number;
}

const Loader: FC<Props> = ({ size, color, speedMultiplier }) => {
  const theme = useTheme();

  return (
    <StyledLoader>
      <ClipLoader
        color={color || theme.loader.color}
        size={convertRemToPx(size || theme.loader.size)}
        speedMultiplier={speedMultiplier || theme.loader.speedMultiplier}
      />
    </StyledLoader>
  );
};

export default Loader;
