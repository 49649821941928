import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPosition } from '@components/Relations/Content/Item/types';
import { mapEntityTypeToTitleMessageId } from '@components/Relations/Content/Item/utils';
import { EntityType } from '@store/user/types';

import { StyledType } from './styled';

interface Props {
  value: EntityType;
  selected: boolean;
  hovered: boolean;
  position: EntityPosition;
}

const Type: FC<Props> = ({ value, selected, hovered, position }) => {
  const { t } = useTranslation();

  return (
    <StyledType $selected={selected} $hovered={hovered} $position={position}>
      {t(mapEntityTypeToTitleMessageId[value]).toUpperCase()}
    </StyledType>
  );
};

export default Type;
