import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HorizontalLines from '@components/HorizontalLines';
import useClickCounter from '@hooks/useClickCounter';
import { clearTutorial } from '@store/ui/utils';
import { getCurrentYear } from '@utils/date';

import { STORAGE_RESET_COUNT_LIMIT } from './default';
import {
  StyledFooter,
  Content,
  SectionTop,
  SectionBottom,
  Brand,
  Year,
  Social,
  StyledLink,
} from './styled';
import { FooterLink } from './types';

const Footer = () => {
  const yearClickCounter = useClickCounter();

  const { t } = useTranslation();

  useEffect(() => {
    if (yearClickCounter.count === STORAGE_RESET_COUNT_LIMIT) {
      clearTutorial();
      yearClickCounter.handleReset();
    }
  }, [yearClickCounter.count]);

  const socialMediaLinks: FooterLink[] = t(
    'components.footer.socialMediaLinks',
    {
      returnObjects: true,
    }
  );

  const policyLinks: FooterLink[] = t('components.footer.policyLinks', {
    returnObjects: true,
  });

  return (
    <StyledFooter>
      <HorizontalLines />
      <Content>
        <SectionTop>
          <Brand external to="https://knowledgator.com">
            {t('components.footer.brand')} &copy;{' '}
            <Year onClick={yearClickCounter.handleClick}>
              {getCurrentYear()}
            </Year>
            .
          </Brand>
          <Social>
            {socialMediaLinks.map(({ name, href }, index) => (
              <StyledLink key={index} external to={href}>
                {name}
              </StyledLink>
            ))}
          </Social>
        </SectionTop>
        <SectionBottom>
          {policyLinks.map(({ name, href }, index) => (
            <StyledLink key={index} external to={href}>
              {name}
            </StyledLink>
          ))}
        </SectionBottom>
      </Content>
    </StyledFooter>
  );
};

export default Footer;
