import styled from 'styled-components';

import { TextStatus } from '@containers/Processor/types';

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.processor.footerHeight}rem;
  margin: 0 5rem;
  border-top: 1px solid
    ${(props) =>
      props.theme.processor[TextStatus.READY_FOR_PROCESSING].borderColor};
`;
