import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const userMenuTheme: DefaultTheme['userMenu'] = {
  zIndex: 9996,
  backgroundColor: Color.MINE_SHAFT,
  minWidth: 32,
  borderColor: Color.TUNDORA,
};
