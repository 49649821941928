import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const tabsTheme: DefaultTheme['tabs'] = {
  titleColor: Color.SILVER_CHALICE,
  iconColor: Color.SILVER_CHALICE,
  iconBackgroundColor: Color.COD_GRAY,
  iconHoverColor: Color.CORNFLOWER_BLUE,
  iconHoverBackgroundColor: Color.COD_GRAY,
  iconHighlightBorderColor: Color.CORNFLOWER_BLUE,
  iconHighlightBackgroundColor: Color.CORNFLOWER_BLUE_30,
  tab: {
    separatorBackgroundColor: Color.WHITE,
    color: Color.SILVER_CHALICE,
    backgroundColor: Color.TRANSPARENT,
    borderColor: Color.TUNDORA,
    active: {
      color: Color.WHITE,
      backgroundColor: Color.SHIP_COVE,
      borderColor: Color.TRANSPARENT,
    },
  },
};
