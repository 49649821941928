import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const creatableTheme: DefaultTheme['creatable'] = {
  minHeight: 5,
  minWidth: 37.5,
  borderColor: Color.TUNDORA,
  focusBorderColor: Color.INDIGO,
  focusBorderStyle: 'dashed',
  errorBorderColor: Color.FLUSH_MAHOGANY,
  errorBorderStyle: 'dashed',
  backgroundColor: Color.MINE_SHAFT,
  dropdownIndicatorDisabledColor: Color.GRAY,
  dropdownIndicatorColor: Color.WHITE,
  multiValueBackgroundColor: Color.GRAY,
  multiValueRemoveBackgroundColor: Color.MINE_SHAFT,
  multiValueRemoveHoverBackgroundColor: Color.CORNFLOWER_BLUE,
  multiValueRemoveIconColor: Color.GRAY,
  multiValueRemoveHoverIconColor: Color.MINE_SHAFT,
  color: Color.WHITE,
};
