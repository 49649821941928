import { AxiosResponse } from 'axios';

import httpClient, {
  namedEntityRecognitionRapidApiClient,
  relationExtractionRapidApiClient,
  textClassificationRapidApiClient,
  textToTableRapidApiClient,
} from '@api/client';
import {
  LoginCredentials,
  RegisterCredentials,
  ForgotPasswordCredentials,
  ResetPasswordCredentials,
  ValidatePasswordCredentials,
  ChangePasswordCredentials,
} from '@store/auth/types';

import * as endpoints from './endpoints';
import {
  SignInResponse,
  SignUpResponse,
  SignOutResponse,
  ResetPasswordRequestResponse,
  ResetPasswordResponse,
  ChangePasswordResponse,
  ValidatePasswordResponse,
  GetCurrentUserResponse,
  NamedEntityRecognitionProcessResponse,
  TextClassificationProcessResponse,
  TextToTableProcessResponse,
  TextToTablePreProcessResponse,
  TextToTableFilePreProcessResponse,
  TextToTableLinkPreProcessResponse,
  RelationExtractionProcessResponse,
} from './types';

/* Auth */
export const signIn = (
  credentials: LoginCredentials
): Promise<AxiosResponse<SignInResponse>> =>
  httpClient.post(endpoints.SIGN_IN, credentials);

export const signUp = (
  credentials: RegisterCredentials
): Promise<AxiosResponse<SignUpResponse>> =>
  httpClient.post(endpoints.SIGN_UP, credentials);

export const signOut = (): Promise<AxiosResponse<SignOutResponse>> =>
  httpClient.post(endpoints.SIGN_OUT);
/* */

/* Change/Validate Password */
export const resetPasswordRequest = (
  credentials: ForgotPasswordCredentials
): Promise<AxiosResponse<ResetPasswordRequestResponse>> =>
  httpClient.post(endpoints.RESET_PASSWORD_REQUEST, credentials);

export const resetPassword = (
  credentials: ResetPasswordCredentials
): Promise<AxiosResponse<ResetPasswordResponse>> =>
  httpClient.post(endpoints.RESET_PASSWORD, credentials);

export const changePassword = (
  credentials: ChangePasswordCredentials
): Promise<AxiosResponse<ChangePasswordResponse>> =>
  httpClient.put(endpoints.CHANGE_PASSWORD, credentials);

export const validatePassword = (
  credentials: ValidatePasswordCredentials
): Promise<AxiosResponse<ValidatePasswordResponse>> =>
  httpClient.post(endpoints.VALIDATE_PASSWORD, credentials);
/* */

/* User */
export const getCurrentUser = (): Promise<
  AxiosResponse<GetCurrentUserResponse>
> => httpClient.get(endpoints.CURRENT_USER);
/* */

/* ---------- Relation Extraction functions ---------- */

/* Text processing */
export const processRelationExtraction = (
  text: string,
  relations: string[]
): Promise<AxiosResponse<RelationExtractionProcessResponse>> =>
  relationExtractionRapidApiClient.post(endpoints.RELATION_EXTRACTION_PROCESS, {
    text,
    relations,
    scorer: true,
  });
/* */

/* ---------- --- ---------- */

/* ---------- Named Entity Recognition functions ---------- */

/* Text processing */
export const processNamedEntityRecognition = (
  text: string,
  labels: string[]
): Promise<AxiosResponse<NamedEntityRecognitionProcessResponse>> =>
  namedEntityRecognitionRapidApiClient.post(
    endpoints.NAMED_ENTITY_RECOGNITION_PROCESS,
    {
      text,
      labels,
      scorer: true,
    }
  );
/* */

/* ---------- --- ---------- */

/* ---------- Text Classification functions ---------- */

/* Text processing */
export const processTextClassification = (
  text: string,
  labels: string[]
): Promise<AxiosResponse<TextClassificationProcessResponse>> =>
  textClassificationRapidApiClient.post(endpoints.TEXT_CLASSIFICATION_PROCESS, {
    text,
    labels,
  });
/* */

/* ---------- --- ---------- */

/* ---------- Text to Table functions ---------- */

/* Text pre-processing */
export const preProcessTextToTable = (
  text: string,
  columns: string[]
): Promise<AxiosResponse<TextToTablePreProcessResponse>> =>
  textToTableRapidApiClient.post(endpoints.TEXT_TO_TABLE_PRE_PROCESS, {
    text,
    columns,
  });
/* */

/* Link pre-processing */
export const preProcessTextToTableLink = (
  url: string,
  columns: string[]
): Promise<AxiosResponse<TextToTableLinkPreProcessResponse>> =>
  textToTableRapidApiClient.post(endpoints.TEXT_TO_TABLE_LINK_PRE_PROCESS, {
    url,
    columns,
  });

/* File pre-processing */
export const preProcessTextToTableFile = (
  file: File,
  columns: string[]
): Promise<AxiosResponse<TextToTableFilePreProcessResponse>> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('json', JSON.stringify({ columns }));
  return textToTableRapidApiClient.post(
    endpoints.TEXT_TO_TABLE_FILE_PRE_PROCESS,
    formData
  );
};

/* Text processing */
export const processTextToTable = (
  text: string,
  columns: string[]
): Promise<AxiosResponse<TextToTableProcessResponse>> =>
  textToTableRapidApiClient.post(endpoints.TEXT_TO_TABLE_PROCESS, {
    text,
    columns,
  });
/* */

/* ---------- --- ---------- */
