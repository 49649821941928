import { DefaultTheme } from 'styled-components';

import { Color } from '@theme/colors';

export const fileInputTheme: DefaultTheme['fileInput'] = {
  file: {
    width: 50,
    backgroundColor: Color.TUNDORA,
    color: Color.WHITE,
    crossIconColor: Color.SILVER_CHALICE,
  },
  emptyText: {
    color: Color.SILVER_CHALICE,
    errorColor: Color.FLUSH_MAHOGANY,
    underlineColor: Color.SILVER_CHALICE,
    errorUnderlineColor: Color.FLUSH_MAHOGANY,
    linkColor: Color.WHITE,
    errorLinkColor: Color.FLUSH_MAHOGANY,
    fileColor: Color.INDIGO,
    errorFileColor: Color.FLUSH_MAHOGANY,
  },
  dragActiveBackgroundColor: Color.SAN_MARINO_25,
};
