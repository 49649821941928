import { GroupBase, StylesConfig } from 'react-select';
import styled, { css, DefaultTheme } from 'styled-components';

import IconButton from '@components/IconButton';

import { SelectOption, SelectLabelPosition } from './types';

export const StyledSelect = styled.div<{ $labelPosition: SelectLabelPosition }>`
  ${(props) =>
    props.$labelPosition === SelectLabelPosition.LEFT &&
    css`
      display: flex;
      align-items: center;
    `};
`;

export const Label = styled.label<{ $position: SelectLabelPosition }>`
  position: relative;
  display: inline-block;

  ${(props) =>
    props.$position === SelectLabelPosition.LEFT
      ? css`
          margin-right: 2rem;
        `
      : css`
          margin-bottom: 1rem;
        `};
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  transform: translateY(-50%);
`;

export const NoOptionsMessage = styled.div`
  padding: 1.5rem;
`;

export const GroupWrapper = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.select.groupBorderColor};
  }
`;

export const getSelectStyles = (
  theme: DefaultTheme
): StylesConfig<SelectOption, false, GroupBase<SelectOption>> => ({
  control: (provided, state) => ({
    ...provided,
    ...(state.menuIsOpen
      ? {
          borderTopLeftRadius: '0.8rem',
          borderTopRightRadius: '0.8rem',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }
      : {
          borderRadius: '0.8rem',
        }),
    cursor: 'pointer',
    backgroundColor: theme.select.backgroundColor,
    borderColor: theme.select.borderColor,
    boxShadow: 'none',
    width: `${theme.select.width}rem`,
    minHeight: `${theme.select.minHeight}rem`,
    ':hover': {
      borderColor: theme.select.borderColor,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.select.optionColor,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: -1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 'none',
    backgroundColor: theme.select.backgroundColor,
    width: 'max-content',
    minWidth: '100%',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: theme.select.zIndex,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderBottomLeftRadius: '0.8rem',
    borderBottomRightRadius: '0.8rem',
    border: `1px solid ${theme.select.borderColor}`,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: state.isSelected
      ? theme.select.selectedOptionColor
      : theme.select.optionColor,
    backgroundColor: theme.select.backgroundColor,
    ':hover': {
      color: state.isSelected
        ? theme.select.selectedOptionColor
        : theme.select.hover.optionColor,
      backgroundColor: theme.select.hover.optionBackgroundColor,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
