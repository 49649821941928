import { Color } from '@theme/colors';
import { DefaultTheme } from 'styled-components';

export const modalTheme: DefaultTheme['modal'] = {
  zIndex: 9997,
  transitionTime: 0.5,
  overlay: {
    backgroundColor: Color.WHITE_15,
  },
  content: {
    backgroundColor: Color.COD_GRAY,
    borderColor: Color.GALLERY_10,
    boxShadowColor: Color.BLACK_25,
  },
};
