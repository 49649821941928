import { FC, RefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useTheme } from 'styled-components';

import { AuthType } from '@containers/Model/types';
import Button from '@components/Button';
import Error from '@components/Error';
import Form from '@components/Form';
import Label from '@components/Form/Label';
import FormGroup from '@components/Form/FormGroup';
import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon';
import Link from '@components/Link';
import Loader from '@components/Loader';
import useToggle from '@hooks/useToggle';
import { ResetPasswordCredentials } from '@store/auth/types';
import { PasswordVisibility } from '@typings/auth';
import {
  mapPasswordVisibilityToIconType,
  mapPasswordVisibilityToInputType,
} from '@utils/auth';

import {
  FormFields,
  StyledInput,
  ButtonWrapper,
  StyledRightIcon,
  Hint,
} from './styled';
import { validationSchema } from './validation';

interface Props {
  loading: boolean;
  error: string;
  inputRef: RefObject<HTMLInputElement>;
  handleSubmit: (values: Pick<ResetPasswordCredentials, 'password'>) => void;
}

const ResetPassword: FC<Props> = ({
  loading,
  error,
  inputRef,
  handleSubmit,
}) => {
  const [passwordVisible, togglePasswordVisible] = useToggle(false);

  const formik = useFormik<Pick<ResetPasswordCredentials, 'password'>>({
    initialValues: {
      password: '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Form handleSubmit={formik.handleSubmit}>
      <FormFields>
        <FormGroup>
          <Label>{t('components.resetPassword.form.password.label')}</Label>
          <StyledInput
            ref={inputRef}
            name="password"
            type={
              mapPasswordVisibilityToInputType[
                passwordVisible
                  ? PasswordVisibility.VISIBLE
                  : PasswordVisibility.HIDDEN
              ]
            }
            autoComplete="resetPassword-password"
            placeholder={t(
              'components.resetPassword.form.password.placeholder'
            )}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rightIcon={
              <StyledRightIcon
                type={IconButtonType.PLAIN}
                onClick={togglePasswordVisible}
              >
                <Icon
                  type={
                    mapPasswordVisibilityToIconType[
                      passwordVisible
                        ? PasswordVisibility.VISIBLE
                        : PasswordVisibility.HIDDEN
                    ]
                  }
                  stroke={theme.resetPassword.eyeIconColor}
                />
              </StyledRightIcon>
            }
          />
          {formik.touched.password && (
            <Error>{t(formik.errors.password!)}</Error>
          )}
        </FormGroup>
        <FormGroup noMargin>
          {loading ? (
            <Loader color={theme.resetPassword.loaderColor} />
          ) : (
            <ButtonWrapper>
              <Button to="#" onClick={formik.submitForm}>
                {t('components.resetPassword.submit')}
              </Button>
              {error && <Error>{error}</Error>}
              <Hint>
                <Trans
                  i18nKey="components.resetPassword.hint"
                  components={[
                    <Link
                      key="loginLink"
                      underline
                      to={`/?authType=${AuthType.LOGIN}`}
                    />,
                  ]}
                />
              </Hint>
            </ButtonWrapper>
          )}
        </FormGroup>
      </FormFields>
    </Form>
  );
};

export default ResetPassword;
