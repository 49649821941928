import { FC, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components, GroupBase } from 'react-select';
import SelectType from 'react-select/dist/declarations/src/Select';
import { useTheme } from 'styled-components';
import { DefaultTFuncReturn } from 'i18next';

import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import IconButton from '@components/IconButton';
import { IconButtonType } from '@components/IconButton/types';

import { DEFAULT_SELECT_ROOT_ID } from './defaults';
import {
  StyledSelect,
  Label,
  StyledIconButton,
  NoOptionsMessage,
  GroupWrapper,
  getSelectStyles,
} from './styled';
import {
  SelectLabelPosition,
  SelectOption,
  SelectOptionsOrGroups,
  SingleSelectValue,
} from './types';

interface Props {
  className?: string;
  label?: DefaultTFuncReturn;
  labelPosition?: SelectLabelPosition;
  options: SelectOptionsOrGroups;
  value?: SingleSelectValue;
  handleChange: (value: SingleSelectValue) => void;
  placeholder?: DefaultTFuncReturn;
  isSearchable?: boolean;
  info?: boolean;
}

const Select: FC<PropsWithChildren<Props>> = ({
  className,
  label,
  labelPosition = SelectLabelPosition.TOP,
  options,
  value,
  handleChange,
  placeholder,
  isSearchable = false,
  info,
}) => {
  const ref =
    useRef<SelectType<SelectOption, false, GroupBase<SelectOption>>>(null);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledSelect className={className} $labelPosition={labelPosition}>
      {label && (
        <Label $position={labelPosition}>
          {label}
          {info && (
            <StyledIconButton type={IconButtonType.PLAIN}>
              <Icon type={IconType.INFO_CIRCLE} large />
            </StyledIconButton>
          )}
        </Label>
      )}
      <ReactSelect
        ref={ref}
        isSearchable={isSearchable}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={handleChange}
        components={{
          DropdownIndicator: (props) => (
            <IconButton type={IconButtonType.PLAIN}>
              <Icon
                type={IconType.CHEVRON_DOWN}
                stroke={
                  props.selectProps.menuIsOpen
                    ? theme.select.openedIconColor
                    : theme.select.iconColor
                }
                large
              />
            </IconButton>
          ),
          NoOptionsMessage: () => (
            <NoOptionsMessage>
              {t('components.select.noOptions')}
            </NoOptionsMessage>
          ),
          Group: (props) => {
            return (
              <GroupWrapper>
                <components.Group
                  {...props}
                  getStyles={() => ({
                    padding: 0,
                  })}
                />
              </GroupWrapper>
            );
          },
        }}
        menuPortalTarget={
          document.querySelector(`#${DEFAULT_SELECT_ROOT_ID}`) as HTMLElement
        }
        styles={getSelectStyles(theme)}
      />
    </StyledSelect>
  );
};

export default Select;
