import {
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSProperties } from 'styled-components';

import { IconButtonType } from '@components/IconButton/types';
import Icon from '@components/Icon';
import { IconType } from '@components/Icon/types';
import Portal from '@components/Portal';
import useToggle from '@hooks/useToggle';
import useOnClickOutside from '@hooks/useOnClickOutside';

import {
  DEFAULT_DROPDOWN_ROOT_ID,
  DEFAULT_MARGIN,
  EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK,
} from './defaults';
import {
  StyledDropdown,
  StyledIconButton,
  Content,
  Option,
  IconWrapper,
  Label,
} from './styled';
import {
  DropdownCoordinates,
  DropdownOption,
  OptionHoverStyle,
  OptionStyle,
} from './types';

interface Props {
  className?: string;
  control?: ReactElement;
  contentStyle?: CSSProperties;
  options: DropdownOption[];
  optionStyle?: OptionStyle & {
    hover: OptionHoverStyle;
  };
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
}

const Dropdown: FC<Props> = ({
  className,
  control,
  contentStyle,
  options,
  optionStyle,
  onOpen,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [coordinates, setCoordinates] = useState<DropdownCoordinates>();
  const [opened, toggleOpened, handleClose] = useToggle(false);

  useEffect(() => {
    if (opened) {
      onOpen?.();
      return;
    }
    onClose?.();
  }, [opened]);

  const getCoordinates = (el: HTMLDivElement): DropdownCoordinates => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.x,
      top: rect.y + rect.height + window.scrollY,
    };
  };

  const handleToggleOpen = () => {
    if (!ref.current) {
      return;
    }
    setCoordinates(getCoordinates(ref.current));
    toggleOpened();
  };

  useOnClickOutside(ref, handleClose, {
    excludeClassName: EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK,
  });

  return (
    <StyledDropdown className={className} ref={ref}>
      {control ? (
        cloneElement(control, { onClick: handleToggleOpen })
      ) : (
        <StyledIconButton
          type={IconButtonType.PLAIN}
          onClick={handleToggleOpen}
        >
          <Icon type={IconType.MORE_VERTICAL} />
        </StyledIconButton>
      )}

      {opened && (
        <Portal root={DEFAULT_DROPDOWN_ROOT_ID}>
          <Content
            ref={contentRef}
            className={EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK}
            style={{
              ...coordinates,
              marginTop: `${DEFAULT_MARGIN}rem`,
              ...contentStyle,
            }}
          >
            {options.map((option, index) => (
              <Option
                key={index}
                className={EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK}
                onClick={() => {
                  option.onClick?.();
                  toggleOpened();
                }}
                $color={optionStyle?.color}
                $hoverColor={optionStyle?.hover.color}
              >
                {option.icon && (
                  <IconWrapper>
                    <Icon
                      className={EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK}
                      type={option.icon}
                    />
                  </IconWrapper>
                )}
                <Label className={EXCLUDE_CLASSNAME_FROM_OUTSIDE_CLICK}>
                  {option.label}
                </Label>
              </Option>
            ))}
          </Content>
        </Portal>
      )}
    </StyledDropdown>
  );
};

export default Dropdown;
