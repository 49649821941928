import { CSVLink } from 'react-csv';
import styled, { css, CSSProperties, DefaultTheme } from 'styled-components';

import Button from '@components/Button';
import { OptionHoverStyle, OptionStyle } from '@components/Dropdown/types';
import Icon from '@components/Icon';
import Link from '@components/Link';

export const StyledExport = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDefaultExportButton = styled(Button)`
  padding: 1rem 2rem;
`;

export const StyledControlIcon = styled(Icon)``;

export const StyledControlButton = styled(Button)<{ $open: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;

  ${(props) =>
    props.$open &&
    css`
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border-bottom-color: transparent;
      }
    `};

  &:hover {
    ${StyledControlIcon} {
      stroke: ${(props) => props.theme.export.iconHoverColor};
    }
  }
`;

export const ControlText = styled.div`
  margin-right: 1.5rem;
`;

export const StyledJSONLink = styled(Link)`
  color: inherit;
`;

export const StyledCSVLink = styled(CSVLink)`
  color: inherit;
  text-decoration: none;
`;

export const getDropdownContentStyle = (
  theme: DefaultTheme,
  maxWidth?: number
): CSSProperties => ({
  overflow: 'hidden',
  marginTop: 0,
  backgroundColor: theme.export.backgroundColor,
  borderColor: theme.export.borderColor,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: '0.8rem',
  borderBottomRightRadius: '0.8rem',
  minWidth: `${maxWidth}px` || '15rem',
});

export const getDropdownOptionStyle = (theme: DefaultTheme): OptionStyle => ({
  color: theme.export.optionColor,
});

export const getDropdownOptionHoverStyle = (
  theme: DefaultTheme
): OptionHoverStyle => ({
  color: theme.export.hover.optionColor,
});
