import { IconType } from './types';

export const config: Record<IconType, string> = {
  [IconType.HELP_CIRCLE]:
    'M 12 22 C 17.520833 22 22 17.520833 22 12 C 22 6.479167 17.520833 2 12 2 C 6.479167 2 2 6.479167 2 12 C 2 17.520833 6.479167 22 12 22 Z M 12 22 M 9.09375 9 C 9.328125 8.333333 9.791667 7.765625 10.40625 7.411458 C 11.015625 7.052083 11.734375 6.916667 12.432292 7.036458 C 13.130208 7.15625 13.760417 7.520833 14.21875 8.0625 C 14.677083 8.604167 14.927083 9.291667 14.921875 10 C 14.921875 12 11.921875 13 11.921875 13 M 12 17 L 12.010417 17',
  [IconType.PLUS]: 'M9 3.75V14.25 M3.75 9H14.25',
  [IconType.CROSS]: 'M13.5 4.5L4.5 13.5 M4.5 4.5L13.5 13.5',
  [IconType.CROSS_CIRCLE]:
    'M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z M15 9L9 15 M9 9L15 15',
  [IconType.CHECK]: 'M15 4.5L6.75 12.75L3 9',
  [IconType.EDIT]:
    'M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75 M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z',
  [IconType.LOADER]:
    'M9 1.5V4.5 M9 13.5V16.5 M3.69531 3.69727L5.81781 5.81977 M12.1797 12.1797L14.3022 14.3022 M1.5 9H4.5 M13.5 9H16.5 M3.69531 14.3022L5.81781 12.1797 M12.1797 5.81977L14.3022 3.69727',
  [IconType.INFO_CIRCLE]:
    'M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z M12 16V12 M12 8H12.01',
  [IconType.EYE]:
    'M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z',
  [IconType.EYE_OFF]:
    'M13.455 13.455C12.1729 14.4323 10.6118 14.9737 9 15C3.75 15 0.75 9.00002 0.75 9.00002C1.68292 7.26144 2.97685 5.74247 4.545 4.54502M7.425 3.18002C7.94125 3.05918 8.4698 2.99877 9 3.00002C14.25 3.00002 17.25 9.00002 17.25 9.00002C16.7947 9.85172 16.2518 10.6536 15.63 11.3925M10.59 10.59C10.384 10.8111 10.1356 10.9884 9.85961 11.1114C9.58362 11.2343 9.28568 11.3005 8.98357 11.3058C8.68146 11.3111 8.38137 11.2555 8.10121 11.1424C7.82104 11.0292 7.56654 10.8608 7.35288 10.6471C7.13923 10.4335 6.97079 10.179 6.85763 9.89881C6.74447 9.61865 6.68889 9.31856 6.69423 9.01645C6.69956 8.71434 6.76568 8.4164 6.88866 8.1404C7.01163 7.86441 7.18894 7.61601 7.41 7.41002 M0.75 0.75L17.25 17.25',
  [IconType.MORE_HORIZONTAL]:
    'M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z',
  [IconType.MORE_VERTICAL]:
    'M9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9Z M9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75Z M9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25Z',
  [IconType.CHEVRON_DOWN]: 'M6 9L12 15L18 9',
  [IconType.CHEVRON_LEFT]: 'M15 18L9 12L15 6',
  [IconType.CHEVRON_RIGHT]: 'M9 6L15 12L9 18',
  [IconType.ARROW_DOWN]: 'M9 3.75L9 14.25 M14.25 9L9 14.25L3.75 9',
  [IconType.ARROW_UP_RIGHT]: 'M5.25 12.75L12.75 5.25 M5.25 5.25H12.75V12.75',
  [IconType.GOOGLE]:
    'M 9.003906 1.800781 C 5.023438 1.800781 1.800781 5.023438 1.800781 9 C 1.800781 12.976562 5.023438 16.199219 9.003906 16.199219 C 15.007812 16.199219 16.363281 10.625 15.796875 7.800781 L 9 7.800781 L 9 10.199219 L 13.644531 10.199219 C 13.109375 12.269531 11.234375 13.800781 9 13.800781 C 6.347656 13.800781 4.199219 11.652344 4.199219 9 C 4.199219 6.347656 6.347656 4.199219 9 4.199219 C 10.207031 4.199219 11.304688 4.648438 12.148438 5.382812 L 13.851562 3.675781 C 12.570312 2.511719 10.871094 1.800781 9.003906 1.800781 Z M 9.003906 1.800781',
  [IconType.USER]:
    'M 17 18 L 17 16 C 17 14.9375 16.578125 13.921875 15.828125 13.171875 C 15.078125 12.421875 14.0625 12 13 12 L 5 12 C 3.9375 12 2.921875 12.421875 2.171875 13.171875 C 1.421875 13.921875 1 14.9375 1 16 L 1 18 M 9 8 C 11.210938 8 13 6.210938 13 4 C 13 1.789062 11.210938 0 9 0 C 6.789062 0 5 1.789062 5 4 C 5 6.210938 6.789062 8 9 8 Z M 9 8',
  [IconType.BIN]:
    'M2.25 4.5H3.75H15.75 M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5 M7.5 8.25V12.75 M10.5 8.25V12.75',
  [IconType.LINK]:
    'M11.25 5.25H13.5C13.9925 5.25 14.4801 5.347 14.9351 5.53545C15.39 5.72391 15.8034 6.00013 16.1517 6.34835C16.4999 6.69657 16.7761 7.10997 16.9645 7.56494C17.153 8.01991 17.25 8.50754 17.25 9C17.25 9.49246 17.153 9.98009 16.9645 10.4351C16.7761 10.89 16.4999 11.3034 16.1517 11.6517C15.8034 11.9999 15.39 12.2761 14.9351 12.4645C14.4801 12.653 13.9925 12.75 13.5 12.75H11.25M6.75 12.75H4.5C4.00754 12.75 3.51991 12.653 3.06494 12.4645C2.60997 12.2761 2.19657 11.9999 1.84835 11.6517C1.14509 10.9484 0.75 9.99456 0.75 9C0.75 8.00544 1.14509 7.05161 1.84835 6.34835C2.55161 5.64509 3.50544 5.25 4.5 5.25H6.75 M6 9H12',
  [IconType.UPLOAD]:
    'M15.75 11.25L15.75 14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75L3.75 15.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25L2.25 11.25 M12.75 6L9 2.25L5.25 6 M9 2.25L9 11.25',
  [IconType.FILE]:
    'M9.75 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6.75L9.75 1.5Z M9.75 1.5V6.75H15',
};
