import styled from 'styled-components';

export const StyledTooltipLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const DotWrapper = styled.div`
  margin-right: 1rem;
`;

export const Text = styled.p`
  margin-right: 0.5rem;
`;

export const Value = styled.p``;
