import { FC } from 'react';
import parse from 'html-react-parser';

import {
  NEW_LINE_SYMBOL,
  EMPTY_SENTENCE_VALUE,
} from '@components/Highlighter/defaults';
import {
  StyledHighlighter,
  StyledModelSentence,
} from '@components/Highlighter/styled';
import { NamedEntityRecognitionData } from '@store/user/types';

import { prepareNamedEntityRecognitionText } from './algorithm';
import { getNamedEntityRecognitionOptions } from './utils';

interface Props {
  sentences: string[];
  data?: NamedEntityRecognitionData[];
  labels?: string[];
  handleEdit?: VoidFunction;
}

const NamedEntityRecognition: FC<Props> = ({
  sentences,
  data = [],
  labels = [],
  handleEdit,
}) => {
  const preparedText = prepareNamedEntityRecognitionText(
    sentences.join(''),
    data,
    labels
  );
  const splittedText = preparedText.split(NEW_LINE_SYMBOL);
  return (
    <StyledHighlighter onClick={handleEdit}>
      {splittedText.map((sentence, index) => {
        if (!sentence) {
          return (
            <StyledModelSentence key={index} $hidden>
              {EMPTY_SENTENCE_VALUE}
            </StyledModelSentence>
          );
        }
        const value = parse(sentence, getNamedEntityRecognitionOptions());
        return <StyledModelSentence key={index}>{value}</StyledModelSentence>;
      })}
    </StyledHighlighter>
  );
};

export default NamedEntityRecognition;
